export var navItems = [{
  label: 'What\'s in it for you',
  ref: 'features'
}, {
  label: 'How it works',
  ref: 'howItWorks'
}, {
  label: 'Testimonials',
  ref: 'testimonials'
}, {
  label: 'FAQs',
  ref: 'faq'
}];