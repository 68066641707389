var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import * as React from 'react';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkLink } from 'uitk-react-link';
import { UitkFigure } from 'uitk-react-images';
import { UitkTertiaryButton } from 'uitk-react-button';
import { useDialog } from '@shared-ui/dialog-context';
import { Layout, AffiliateCard, Dialog, Faq, HeroVideo } from 'features/creator-video';
import { useCommonClickStreamEvent, EventType } from 'shared/utils';
import { LinkWithQuery } from 'shared/components';
function ConfirmationPage() {
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  // Dialog needed for FAQ
  var desktopVideoContentGuidelinesTrigger = React.useRef(null);
  var fullSheetDialogTrigger = React.useRef(null);
  var _a = __read(useDialog('VCG-necessary-dialog-layer-confirmation'), 3),
    isVCGDialogOpen = _a[0],
    dialogVCGActions = _a[1],
    DialogVCGComponent = _a[2];
  var _b = __read(useDialog('CV-footer-full-necessary-dialog-layer'), 3),
    isFullDialogOpen = _b[0],
    dialogFullActions = _b[1],
    DialogFullComponent = _b[2];
  var openTermsAndConditions = function () {
    trackEvent({
      event_type: EventType.IMPRESSION,
      event_name: 'creator_video_terms_and_conditions.presented',
      action_location: 'confirmation-page-faq'
    });
    dialogFullActions.openDialog();
  };
  var handleAffiliateRedirectAnalytics = function () {
    trackEvent({
      event_type: EventType.INTERACTION,
      event_name: 'creator_video_join_affiliate.selected',
      action_location: 'confirmation-page-faq-link'
    });
  };
  React.useEffect(function () {
    trackEvent({
      event_type: EventType.PAGE_VIEW,
      event_name: 'creator_video_confirmation.viewed'
    });
  }, []);
  var shortInitFaqList = [{
    isVisible: true,
    expandoTitle: 'How long does it take for Expedia to review the submitted content? How will I know if my submission qualifies for earning?',
    expandoDescription: React.createElement(UitkText, {
      size: 400
    }, "While we strive to review submissions promptly, the exact timeframe may vary due to the volume of submissions and other factors. We\u2019ll send updates to the email you provided once the review is complete. Please ensure you check any junk folders so that you don\u2019t miss any important updates.")
  }, {
    isVisible: false,
    expandoTitle: 'How does payment work?',
    expandoDescription: React.createElement(UitkText, {
      size: 400
    }, "If your video is selected, you'll receive an email with instructions to redeem your payment.")
  }, {
    isVisible: false,
    expandoTitle: 'What rights does Expedia have to use my video?',
    expandoDescription: React.createElement(UitkText, {
      size: 400
    }, "If your video is approved, Expedia will own an exclusive license to use it for showcasing the video content across Expedia's Apps and Sites and social channels. More details can be found in our ", React.createElement(UitkTertiaryButton, {
      onClick: openTermsAndConditions
    }, "terms and conditions"), ".")
  }, {
    isVisible: false,
    expandoTitle: 'Where will selected videos be featured?',
    expandoDescription: React.createElement(UitkText, {
      size: 400
    }, "Selected videos may be showcased on various Expedia Group channels across all our brands. This may include but is not limited to our apps, sites and social media pages (Instagram, Facebook, TikTok, and more).")
  }, {
    isVisible: false,
    expandoTitle: 'What is Expedia Group Affiliate Program? Who can apply to the program?',
    expandoDescription: React.createElement(React.Fragment, null, React.createElement(UitkText, {
      size: 400
    }, "The Expedia Group Affiliate Program is designed for individuals and businesses to earn commissions by promoting Expedia travel products. It is a similar way to earn real money through your travel content as you\u2019ll be paid when someone books through one of your links.", ' '), React.createElement(UitkText, {
      size: 400
    }, "Anyone can apply, and we review all applications within minutes. If you'd like to ", React.createElement(UitkLink, {
      inline: true,
      onClick: handleAffiliateRedirectAnalytics
    }, React.createElement("a", {
      rel: "noreferrer",
      target: "_blank",
      href: "https://console.vap.expedia.com/eg-affiliates/signup"
    }, "become an affiliate")), ' ', "and earn commissions from selling travel, submit your application today."))
  }];
  var _c = __read(React.useState(shortInitFaqList), 2),
    shortFaqList = _c[0],
    setShortFaqList = _c[1];
  var handleRedirectAnalytics = function () {
    trackEvent({
      event_type: EventType.INTERACTION,
      event_name: 'creator_video_go_to_submission.selected',
      action_location: 'confirmation-page-hero-link'
    }, {
      additional_context: {
        user_interface: {
          component_element_text: 'confirmation page hero link'
        }
      }
    });
  };
  return React.createElement(Layout, null, React.createElement("section", {
    className: "CVSection"
  }, React.createElement("div", {
    className: "CVContainer"
  }, React.createElement("div", {
    className: "CVHero__section"
  }, React.createElement("div", {
    className: "CVHero__heading-flex"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    className: "CVHero__heading-text",
    justifyContent: "center"
  }, React.createElement(UitkHeading, {
    size: 2,
    tag: "h1"
  }, "Thanks for submitting!", ' ', React.createElement("img", {
    className: "CVConfirmation__yellow-highlight-svg",
    src: "/images/highlight-yellow.svg"
  })), React.createElement(UitkText, {
    className: "CVHero__confirmation-text"
  }, "Have more videos to submit? Submit away. There\u2019s no limit, just", ' ', React.createElement(UitkLink, {
    inline: true,
    className: "CVHero__link",
    onClick: handleRedirectAnalytics
  }, React.createElement(LinkWithQuery, {
    to: "/en-us/creator-video/submit"
  }, "complete a new form."))))), React.createElement("div", {
    className: "CVHero__relative-image"
  }, React.createElement(UitkFigure, {
    className: "CVHero__media-image"
  }, React.createElement(HeroVideo, {
    url: "https://mediaim.expedia.com/creator-video/creator-confirmation.mp4",
    poster: "/images/poster-confirmation.png"
  })), React.createElement("img", {
    className: "CVHero__svg CVConfirmation__shining-stars-svg",
    src: "/images/shining-stars.svg"
  }), React.createElement("img", {
    className: "CVHero__svg CVConfirmation__positive-chat-svg",
    src: "/images/positive-chat.svg"
  }), React.createElement("img", {
    className: "CVHero__svg CVConfirmation__blue-scribble-svg",
    src: "/images/blue-scribble.svg"
  }))))), React.createElement("section", {
    className: "CVSection"
  }, React.createElement(AffiliateCard, null)), React.createElement("section", {
    className: "CVSection"
  }, React.createElement(Faq, {
    faqList: shortFaqList,
    setFaqList: setShortFaqList
  })), React.createElement(Dialog, {
    dialogFullActions: dialogFullActions,
    dialogVCGActions: dialogVCGActions,
    centeredRef: desktopVideoContentGuidelinesTrigger,
    fullRef: fullSheetDialogTrigger,
    isVCG: false,
    fullOpen: isFullDialogOpen,
    vcgOpen: isVCGDialogOpen,
    DialogVCGComponent: DialogVCGComponent,
    DialogFullComponent: DialogFullComponent
  }));
}
export default ConfirmationPage;
export { ConfirmationPage };