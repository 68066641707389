var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
import { SystemEvent, SystemEventLevel } from 'bernie-logger';
var AffiliateHubLogger = /** @class */function () {
  function AffiliateHubLogger(logger) {
    var _this = this;
    this.error = function (event) {
      var args = [];
      for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
      }
      _this.logAtLevel(SystemEventLevel.ERROR, event, args);
    };
    this.warn = function (event) {
      var args = [];
      for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
      }
      _this.logAtLevel(SystemEventLevel.WARN, event, args);
    };
    this.info = function (event) {
      var args = [];
      for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
      }
      _this.logAtLevel(SystemEventLevel.INFO, event, args);
    };
    this.logger = logger;
  }
  AffiliateHubLogger.prototype.logEvent = function (systemEvent) {
    var _a;
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
      args[_i - 1] = arguments[_i];
    }
    (_a = this.logger).logEvent.apply(_a, __spreadArray([systemEvent], __read(args), false));
  };
  AffiliateHubLogger.prototype.logAtLevel = function (level, event, args) {
    this.logEvent.apply(this, __spreadArray([new SystemEvent(level, event)], __read(args), false));
  };
  AffiliateHubLogger.prototype.critical = function (event) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
      args[_i - 1] = arguments[_i];
    }
    this.logAtLevel(SystemEventLevel.CRITICAL, event, args);
  };
  return AffiliateHubLogger;
}();
export default AffiliateHubLogger;