import { DialogRoute } from 'bernie-core';
import { Layer } from 'bernie-view';
import * as React from 'react';
import { UitkDialog, UitkDialogContent } from 'uitk-react-dialog';
import { UitkHeading } from 'uitk-react-text';
import { UitkLoader } from 'uitk-react-loader';
import { LocalizedText } from '@shared-ui/localization-context';
import { UitkLink } from 'uitk-react-link';
var onLoad = function (loadEvent) {
  var iframe = loadEvent.target;
  iframe.style.minHeight = "".concat(window.innerHeight * 0.8, "px");
  window.onresize = function (resizeEvent) {
    iframe.style.minHeight = "".concat(resizeEvent.target.innerHeight * 0.8, "px");
  };
};
export var FlexDocumentDialog = function (_a) {
  var url = _a.url,
    headerText = _a.headerText;
  return React.createElement(DialogRoute, {
    id: "flex-document-dialog"
  }, React.createElement(Layer, {
    id: "dialog"
  }, React.createElement(UitkDialog, {
    dialogShow: true,
    autoFocus: false,
    className: "FlexDocument__dialog"
  }, React.createElement(UitkDialogContent, {
    key: "flexDialogContent1",
    className: "FlexDocumentContent__dialog"
  }, React.createElement(UitkHeading, {
    tag: "h2",
    size: 3
  }, headerText), React.createElement("div", {
    "data-stid": "flex-doc-frame"
  }, url === undefined ? React.createElement(UitkLoader, {
    className: "FlexDocument__spinner"
  }) : React.createElement("iframe", {
    src: url,
    role: "article",
    onLoad: onLoad
  })), React.createElement("div", null, React.createElement(UitkLink, {
    inline: true
  }, React.createElement("button", {
    "data-stid": "dialog-close",
    onClick: function () {
      return window.close();
    }
  }, React.createElement(LocalizedText, {
    message: "common.dialog.close"
  }))))))));
};