import React from 'react';
import { FaqSection, FeaturesSection, HeroSection, Header, HowItWorksSection, AffiliateProgramSection, Footer, TestimonialsSection } from "features/travel-creator-landing";
var TravelCreatorPage = function () {
  var sectionsRef = {
    features: React.useRef(null),
    howItWorks: React.useRef(null),
    testimonials: React.useRef(null),
    faq: React.useRef(null)
  };
  return React.createElement(React.Fragment, null, React.createElement(Header, {
    sectionsRef: sectionsRef
  }), React.createElement(HeroSection, null), React.createElement(FeaturesSection, {
    refProp: sectionsRef.features
  }), React.createElement(HowItWorksSection, {
    refProp: sectionsRef.howItWorks
  }), React.createElement(TestimonialsSection, {
    refProp: sectionsRef.testimonials
  }), React.createElement(AffiliateProgramSection, null), React.createElement(FaqSection, {
    refProp: sectionsRef.faq
  }), React.createElement(Footer, null));
};
export default TravelCreatorPage;
export { TravelCreatorPage };