var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
// 3rd party imports
import * as React from 'react';
//Uitk
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkButtonSize, UitkPrimaryButton } from 'uitk-react-button';
import { Carousel, InspirationDisplayVideo } from 'features/creator-video';
import { useCommonClickStreamEvent, EventType } from 'shared/utils';
import { LinkWithQuery } from 'shared/components';
export function Inspiration(_a) {
  var innerRef = _a.innerRef;
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  var _b = __read(React.useState([{
      videoUrl: 'https://mediaim.expedia.com/creator-video/creator-inspiration-1.mp4',
      poster: '/images/creator-video/poster-1.png'
    }, {
      videoUrl: 'https://mediaim.expedia.com/creator-video/creator-inspiration-2.mp4',
      poster: '/images/creator-video/poster-2.png'
    }, {
      videoUrl: 'https://mediaim.expedia.com/creator-video/creator-inspiration-3.mp4',
      poster: '/images/creator-video/poster-3.png'
    }, {
      videoUrl: 'https://mediaim.expedia.com/creator-video/creator-inspiration-4.mp4',
      poster: '/images/creator-video/poster-4.png'
    }]), 1),
    inspirationVideos = _b[0];
  var handleAnalyticsForSubmissionRedirect = function () {
    trackEvent({
      event_type: EventType.INTERACTION,
      event_name: 'creator_video_go_to_submission.selected',
      action_location: 'inspiration-section-button'
    }, {
      additional_context: {
        user_interface: {
          component_element_text: 'inspiration submit button'
        }
      }
    });
  };
  return React.createElement("div", {
    className: "CVContainer",
    ref: innerRef
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    alignItems: "center",
    className: "CVSection__padding CVGap-64 CVSection__width",
    id: "inspiration"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    tag: "header",
    className: "CVGap-16"
  }, React.createElement(UitkText, {
    size: 400,
    weight: "medium",
    align: "center"
  }, "Inspiration"), React.createElement(UitkHeading, {
    size: 2,
    tag: "h2",
    align: "center"
  }, "Videos to get you inspired")), React.createElement("div", {
    className: "CVInspiration__example-carousel"
  }, React.createElement(Carousel, {
    className: "inspiration"
  }, inspirationVideos.map(function (obj, ind) {
    return React.createElement("div", {
      className: "CVCarousel__flex-item",
      key: "cv-inspiration__carousel-item-".concat(ind)
    }, React.createElement(InspirationDisplayVideo, __assign({}, obj)));
  }))), React.createElement("div", {
    className: "CVInspiration__example-flex"
  }, inspirationVideos.map(function (obj, ind) {
    return React.createElement(InspirationDisplayVideo, __assign({
      key: "cv-inspiration__flex-item-".concat(ind)
    }, obj));
  })), React.createElement(LinkWithQuery, {
    to: "/en-us/creator-video/submit"
  }, React.createElement(UitkPrimaryButton, {
    size: UitkButtonSize.LARGE,
    onClick: handleAnalyticsForSubmissionRedirect
  }, "Submit videos"))));
}