import React from 'react';
import { UitkButtonSize, UitkPrimaryButton } from 'uitk-react-button';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { usePlatformContext } from '@bernie/platform-context';
import { makeLocaleParam } from 'shared/utils';
var HeroSection = function () {
  var context = usePlatformContext().context;
  return React.createElement("section", {
    className: "TCHero"
  }, React.createElement(UitkLayoutFlex, {
    space: 'six',
    className: "container"
  }, React.createElement(UitkLayoutFlexItem, {
    grow: 1
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: 'four',
    justifyContent: 'center'
  }, React.createElement(UitkLayoutFlexItem, {
    className: "TCHero__heading__container"
  }, React.createElement("div", null, React.createElement(UitkHeading, {
    tag: "h1",
    className: "TCHero__heading"
  }, "Discover new ways to earn from your travel content"))), React.createElement(UitkLayoutFlexItem, {
    className: "TCHero__subheading__container"
  }, React.createElement("div", null, React.createElement(UitkText, {
    theme: "inverse",
    spacing: 'one',
    className: "TCHero__subheading"
  }, "Curate and share content effortlessly, get featured, and access top travel brands like Expedia, Hotels.com, and Vrbo to expand your reach."))), React.createElement(UitkLayoutFlexItem, {
    className: "TCHero__button__container"
  }, React.createElement("div", null, React.createElement(UitkPrimaryButton, {
    size: UitkButtonSize.LARGE,
    href: "/".concat(makeLocaleParam(context.locale), "/signup"),
    inverse: true,
    className: 'TCHero__button'
  }, "Begin your journey now"))))), React.createElement(UitkLayoutFlexItem, {
    grow: 1
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    justifyContent: 'center',
    alignItems: 'center'
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement("div", {
    className: "TCHero__video"
  }, React.createElement("img", {
    src: "/images/travel-creator-landing/hero-image.png",
    alt: "placeholder content"
  })))))));
};
export { HeroSection };