var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
// 3rd party imports
import * as React from 'react';
//Uitk
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkText, UitkHeading } from 'uitk-react-text';
import { UitkExpandoList, UitkExpandoListItem } from 'uitk-react-expando';
import { EventType, useCommonClickStreamEvent } from 'shared/utils';
export function Faq(_a) {
  var faqList = _a.faqList,
    setFaqList = _a.setFaqList,
    innerRef = _a.innerRef;
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  return React.createElement("div", {
    className: "CVContainer",
    ref: innerRef
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    alignItems: "center",
    space: "twelve",
    className: "CVFaq"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    alignItems: "center",
    space: "six"
  }, React.createElement(UitkText, {
    size: 400,
    className: "cc-text-neutral-1-500"
  }, "FAQ"), React.createElement(UitkHeading, {
    size: 4,
    align: "center"
  }, "Frequently asked questions"), React.createElement(UitkText, {
    size: 500,
    className: "cc-text-neutral-1-600",
    align: "center"
  }, "Everything you need to know")), React.createElement(UitkExpandoList, {
    className: "CVFaq__expando-list"
  }, faqList.map(function (listItem, index) {
    return React.createElement("div", {
      className: "CVFaq__expando-list-item",
      key: "faq-list-item-".concat(index)
    }, React.createElement(UitkExpandoListItem, __assign({}, listItem, {
      triggerPosition: "trailing",
      onToggle: function () {
        var tempFaqList = __spreadArray([], __read(faqList), false);
        tempFaqList.map(function (obj) {
          obj.isVisible = obj.expandoTitle === listItem.expandoTitle;
        });
        trackEvent({
          event_type: EventType.INTERACTION,
          event_name: 'creator_video_faq.selected',
          action_location: 'creator-engine-faq'
        }, {
          article: {
            article_name: listItem.expandoTitle
          }
        });
        setFaqList(tempFaqList);
      }
    })));
  }))));
}