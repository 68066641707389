var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import * as React from 'react';
import { Layout, Dialog, Faq, HeroVideo, Inspiration, ThreeTile, ThreeTileMobile, Tips, TipsMobile, useCreatorVideoSetup } from 'features/creator-video';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkButtonSize, UitkPrimaryButton, UitkTertiaryButton } from 'uitk-react-button';
import { UitkFigure } from 'uitk-react-images';
import { UitkLink } from 'uitk-react-link';
import { UitkTable, UitkTableBody, UitkTableCell, UitkTableCellBorder, UitkTableCellTag, UitkTableRow } from 'uitk-react-tables';
import { UitkList } from 'uitk-react-list';
import { useDialog } from '@shared-ui/dialog-context';
import { useCommonClickStreamEvent, EventType } from 'shared/utils';
import { LinkWithQuery } from 'shared/components';
import { useLocation } from 'react-router-dom';
import { useStores } from 'shared/hooks';
function LandingPage() {
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  var uiStore = useStores().uiStore;
  var location = useLocation();
  useCreatorVideoSetup();
  var desktopVideoContentGuidelinesTrigger = React.useRef(null);
  var fullSheetDialogTrigger = React.useRef(null);
  var _a = __read(React.useState(true), 2),
    isVCG = _a[0],
    setIsVCG = _a[1];
  var _b = __read(useDialog('VCG-necessary-dialog-layer-landing-page'), 3),
    isVCGDialogOpen = _b[0],
    dialogVCGActions = _b[1],
    DialogVCGComponent = _b[2];
  var _c = __read(useDialog('CV-footer-full-necessary-dialog-layer-landing-page'), 3),
    isFullDialogOpen = _c[0],
    dialogFullActions = _c[1],
    DialogFullComponent = _c[2];
  var openFullSheet = function (isVCGBoolean, location) {
    setIsVCG(isVCGBoolean);
    trackEvent({
      event_type: EventType.IMPRESSION,
      event_name: isVCGBoolean ? 'creator_video_content_guidelines.presented' : 'creator_video_terms_and_conditions.presented',
      action_location: location
    });
    dialogFullActions.openDialog();
  };
  var handleAffiliateRedirectAnalytics = function () {
    trackEvent({
      event_type: EventType.INTERACTION,
      event_name: 'creator_video_join_affiliate.selected',
      action_location: 'landing-page-faq-link'
    });
  };
  React.useEffect(function () {
    if (uiStore.additionalParams === 'showCVDialog') {
      openFullSheet(true, 'email');
    }
    trackEvent({
      event_type: EventType.PAGE_VIEW,
      event_name: 'creator_video_landing.viewed'
    });
  }, []);
  var landingPageInitFaqList = [{
    isVisible: true,
    expandoTitle: 'How do I submit a travel video?',
    expandoDescription: React.createElement(UitkText, {
      size: 400
    }, "Submit your video through the", ' ', React.createElement(UitkLink, {
      inline: true,
      onClick: function () {
        trackEvent({
          event_type: EventType.INTERACTION,
          event_name: 'creator_video_go_to_submission.selected',
          action_location: 'landing-page-faq'
        }, {
          additional_context: {
            user_interface: {
              component_element_text: 'faq link'
            }
          }
        });
      },
      className: "CVLink__no-decoration"
    }, React.createElement(LinkWithQuery, {
      to: "/en-us/creator-video/submit"
    }, "video submission form")), ' ', "following the", ' ', React.createElement(UitkTertiaryButton, {
      tag: "a",
      target: "_blank",
      href: "/en-us/creator-video/video-guidelines"
    }, "video content guidelines."), ' ', "Remember to select the corresponding property for your submitted video to qualify for earning.")
  }, {
    isVisible: false,
    expandoTitle: 'Can I submit multiple travel videos?',
    expandoDescription: React.createElement(UitkText, {
      size: 400
    }, "Absolutely! You can submit up to 15 videos per upload, increasing your chances of getting featured and earning.")
  }, {
    isVisible: false,
    expandoTitle: 'What are the video specifications expected?',
    expandoDescription: React.createElement(React.Fragment, null, React.createElement(UitkText, {
      size: 400
    }, "We are looking for content with the following specifications: "), React.createElement(UitkTable, {
      border: true,
      isRounded: true,
      className: "CVFaq__Table"
    }, React.createElement(UitkTableBody, null, React.createElement(UitkTableRow, null, React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical],
      tag: UitkTableCellTag.header
    }, "Video Duration"), React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]
    }, "The ideal video length is 6-30 seconds with the maximum allowed length of 60 seconds and a minimum allowed length of 6 seconds.")), React.createElement(UitkTableRow, null, React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical],
      tag: UitkTableCellTag.header
    }, "Format"), React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]
    }, "We can only accept videos in", ' ', React.createElement(UitkText, {
      className: "CVText__italicize",
      inline: true
    }, ".mp4"), ' ', "and", ' ', React.createElement(UitkText, {
      className: "CVText__italicize",
      inline: true
    }, ".mov"), ' ', "formats.")), React.createElement(UitkTableRow, null, React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical],
      tag: UitkTableCellTag.header
    }, "File Size"), React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]
    }, "File size must not exceed 500 MB.")), React.createElement(UitkTableRow, null, React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical],
      tag: UitkTableCellTag.header
    }, "Resolution"), React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]
    }, "The video resolution should be 1080 x 1920 pixels or higher.")), React.createElement(UitkTableRow, null, React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical],
      tag: UitkTableCellTag.header
    }, "Orientation"), React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]
    }, "All clips must be filmed in portrait mode (9:16).")), React.createElement(UitkTableRow, null, React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical],
      tag: UitkTableCellTag.header
    }, "Upload Limit"), React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]
    }, "You can attach up to 15 videos in one single upload.")), React.createElement(UitkTableRow, null, React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical],
      tag: UitkTableCellTag.header
    }, "Audio"), React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]
    }, "Only non-copyrighted music will be accepted. Clear audio voiceover and captions in English can be included. No other sound effects are allowed; any other added music will be removed.")))))
  }, {
    isVisible: false,
    expandoTitle: 'How long does it take for Expedia to review the submitted content? How will I know if my submission qualifies for earning?',
    expandoDescription: React.createElement(UitkText, {
      size: 400
    }, "While we strive to review submissions promptly, the exact timeframe may vary due to the volume of submissions and other factors. We\u2019ll send updates to the email you provided once the review is complete. Please ensure you check any junk folders so that you don\u2019t miss any important updates.")
  }, {
    isVisible: false,
    expandoTitle: 'How does payment work?',
    expandoDescription: React.createElement(UitkText, {
      size: 400
    }, "If your video is selected, you'll receive an email with instructions to redeem your payment.")
  }, {
    isVisible: false,
    expandoTitle: 'What rights does Expedia have to use my video?',
    expandoDescription: React.createElement(UitkText, {
      size: 400
    }, "If your video is approved, Expedia will own an exclusive license to use it for showcasing the video content across Expedia's Apps and Sites and social channels. More details can be found in our ", React.createElement(UitkTertiaryButton, {
      tag: "a",
      target: "_blank",
      href: "/en-us/creator-video/terms-and-conditions"
    }, "terms and conditions"), ".")
  }, {
    isVisible: false,
    expandoTitle: 'Where will selected videos be featured?',
    expandoDescription: React.createElement(UitkText, {
      size: 400
    }, "Selected videos may be showcased on various Expedia Group channels across all our brands. This may include but is not limited to our apps, sites and social media pages (Instagram, Facebook, TikTok, and more).")
  }, {
    isVisible: false,
    expandoTitle: 'How can I ensure my video has the best chance of being selected?',
    expandoDescription: React.createElement(React.Fragment, null, React.createElement(UitkText, {
      size: 400
    }, "Following our", ' ', React.createElement(UitkTertiaryButton, {
      tag: "a",
      target: "_blank",
      href: "/en-us/creator-video/video-guidelines"
    }, "content guidelines"), ' ', "ensures your video has the best chance of being selected. To highlight a few important elements from the guidelines:"), React.createElement(UitkList, null, React.createElement("li", {
      key: "Faq-list-best-chance-brands"
    }, React.createElement(UitkText, {
      size: 400
    }, "Do not mention or show brands or properties not available on an", ' ', React.createElement(UitkLink, {
      inline: true
    }, React.createElement("a", {
      rel: "noreferrer",
      target: "_blank",
      href: "https://www.expediagroup.com/travel-with-us/default.aspx"
    }, "Expedia Group brand.")))), React.createElement("li", {
      key: "Faq-list-best-chance-price"
    }, React.createElement(UitkText, {
      size: 400
    }, "Do not mention any prices, as they may change by the time your video goes live.")), React.createElement("li", {
      key: "Faq-list-best-chance-cta"
    }, React.createElement(UitkText, {
      size: 400
    }, "Do not make any calls to action that cannot apply across all social media sites (e.g. \u201CLink in bio!\u201D).")), React.createElement("li", {
      key: "Faq-list-best-chance-text"
    }, React.createElement(UitkText, {
      size: 400
    }, "Make sure your videos do not include any overlaid text."))))
  }, {
    isVisible: false,
    expandoTitle: "I shared a travel video that meets the video guidelines outlined. Why hasn't it been featured?",
    expandoDescription: React.createElement(UitkText, {
      size: 400
    }, "Unfortunately, not all videos that meet the guidelines may be used. All videos go through a moderation process, so it\u2019s possible your video has not been reviewed yet. Additionally, popular properties may receive multiple qualifying video submissions, to which we must select the video that has the highest earning potential.")
  }, {
    isVisible: false,
    expandoTitle: 'What is Expedia Group Affiliate Program? Who can apply to the program?',
    expandoDescription: React.createElement(React.Fragment, null, React.createElement(UitkText, {
      size: 400
    }, "The Expedia Group Affiliate Program is designed for individuals and businesses to earn commissions by promoting Expedia travel products. It is a similar way to earn real money through your travel content as you\u2019ll be paid when someone books through one of your links.", ' '), React.createElement(UitkText, {
      size: 400
    }, "Anyone can apply, and we review all applications within minutes. If you'd like to ", React.createElement(UitkLink, {
      className: "CVInlineLink",
      onClick: handleAffiliateRedirectAnalytics
    }, React.createElement("a", {
      rel: "noreferrer",
      target: "_blank",
      href: "https://console.vap.expedia.com/eg-affiliates/signup"
    }, "become an affiliate")), ' ', "and earn commissions from selling travel, submit your application today."))
  }];
  var _d = __read(React.useState(landingPageInitFaqList), 2),
    landingPageFaqList = _d[0],
    setLandingPageFaqList = _d[1];
  var howItWorksProps = {
    heading: 'How does it work?',
    subHeading: '3 easy steps',
    gridContent: [{
      svg: '/images/creator-video/how-it-works-one.svg',
      header: 'Upload your content',
      paragraph: "Fill out the form to begin uploading. You'll be asked to share a few details, such as where you stayed."
    }, {
      svg: '/images/creator-video/how-it-works-two.svg',
      header: 'Your content is reviewed',
      paragraph: React.createElement(UitkText, {
        size: 400
      }, "We're looking for video quality, property highlights, and engagement potential, among other criteria. ", React.createElement(UitkTertiaryButton, {
        type: "button",
        className: "CVVcg__button",
        tag: "a",
        target: "_blank",
        href: "/en-us/creator-video/video-guidelines"
      }, "Read our Content Guidelines."))
    }, {
      svg: '/images/creator-video/how-it-works-three.svg',
      header: 'Celebrate, then earn some more',
      paragraph: 'Keep an eye on your inbox. If your content is selected for use, our team will reach out via the email address you submit.'
    }]
  };
  var benefitProps = {
    heading: 'What’s in it for you?',
    subHeading: 'Benefits',
    isBenefit: true,
    gridContent: [{
      svg: '/images/creator-video/benefits-chest.svg',
      header: 'Earn for every featured video',
      paragraph: "Each video we use earns you $150. There's no limit on submissions, so keep uploading for a chance to fund your next trip!"
    }, {
      svg: '/images/creator-video/benefits-look.svg',
      header: 'See your content come to life',
      paragraph: "We'll share your content across our sites and social pages, where millions of travelers will be inspired by what you've created."
    }, {
      svg: '/images/creator-video/benefits-trending.svg',
      header: 'Unlock more earning opportunities',
      paragraph: React.createElement(UitkText, {
        size: 400,
        className: "CVBenefits__Text"
      }, "Leverage creator tools within our affiliate program to earn even more with your content. Not an affiliate yet?", ' ', React.createElement(UitkLink, {
        className: "CVInlineLink",
        onClick: handleAffiliateRedirectAnalytics
      }, React.createElement("a", {
        href: "https://console.vap.expedia.com/eg-affiliates/signup"
      }, "Join the program today!")))
    }]
  };
  var benefitsRef = React.useRef(null);
  var inspirationRef = React.useRef(null);
  var howItWorksRef = React.useRef(null);
  var tipsRef = React.useRef(null);
  var faqRef = React.useRef(null);
  var handleScroll = function (ref) {
    var _a;
    (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'center'
    });
  };
  React.useEffect(function () {
    if (benefitsRef.current && inspirationRef.current && howItWorksRef.current && tipsRef.current && faqRef.current) {
      if (location.hash) {
        // Check if there is a hash in the URL
        var anchor = location.hash.replace('#', ''); // Remove the '#' from hash
        switch (anchor) {
          case 'benefits':
            handleScroll(benefitsRef);
            break;
          case 'inspiration':
            handleScroll(inspirationRef);
            break;
          case 'howItWorks':
            handleScroll(howItWorksRef);
            break;
          case 'tips':
            handleScroll(tipsRef);
            break;
          default:
            handleScroll(faqRef);
            break;
        }
      }
    }
  }, [location, faqRef.current]);
  return React.createElement(Layout, null, React.createElement("section", {
    className: "CVSection"
  }, React.createElement("div", {
    className: "CVContainer"
  }, React.createElement("div", {
    className: "CVHero__section"
  }, React.createElement("div", {
    className: "CVHero__heading-flex"
  }, React.createElement(UitkLayoutFlex, {
    className: "CVHero__heading-text",
    direction: "column"
  }, React.createElement(UitkHeading, {
    size: 2,
    tag: "h1"
  }, "Create.", React.createElement("br", {
    className: "visible-mobile--large"
  }), " Share. Earn."), React.createElement(UitkText, {
    size: 500
  }, "Share videos of the amazing properties you stayed in with us. You could earn $150 for every video we feature on our sites, apps, social media, and more!")), React.createElement(UitkLayoutFlexItem, null, React.createElement("div", null, React.createElement(LinkWithQuery, {
    to: "/en-us/creator-video/submit"
  }, React.createElement(UitkPrimaryButton, {
    className: "CVHero__heading-button",
    size: UitkButtonSize.LARGE,
    onClick: function () {
      trackEvent({
        event_type: EventType.INTERACTION,
        event_name: 'creator_video_go_to_submission.selected',
        action_location: 'landing-page-hero-button'
      }, {
        additional_context: {
          user_interface: {
            component_element_text: 'hero button'
          }
        }
      });
    }
  }, "Submit videos"))))), React.createElement(UitkLayoutFlexItem, {
    alignSelf: "center"
  }, React.createElement("div", {
    className: "CVHero__relative-image"
  }, React.createElement(UitkFigure, {
    className: "CVHero__media-image"
  }, React.createElement(HeroVideo, {
    url: "https://mediaim.expedia.com/creator-video/creator-hero.mp4",
    poster: "/images/creator-video/poster-hero.png"
  })), React.createElement("img", {
    className: "CVHero__svg CVHero__star-svg",
    src: "/images/creator-video/landing-page-star.svg"
  }), React.createElement("img", {
    className: "CVHero__svg CVHero__record-svg",
    src: "/images/creator-video/landing-page-record.svg"
  }), React.createElement("img", {
    className: "CVHero__svg CVHero__flash-scribble-svg",
    src: "/images/creator-video/flash-scribble.svg"
  }), React.createElement("img", {
    className: "CVHero__svg CVHero__dark-blue-svg",
    src: "/images/creator-video/dark-blue-scribble.svg"
  }), React.createElement("img", {
    className: "CVHero__svg CVHero__button-svg",
    src: "/images/creator-video/creator-video-button.svg"
  })))))), React.createElement("div", {
    className: "hide-desktop"
  }, React.createElement("section", {
    className: "CVSection"
  }, React.createElement(ThreeTileMobile, __assign({}, benefitProps, {
    innerRef: benefitsRef
  }))), React.createElement("section", {
    className: "CVSection"
  }, React.createElement(Inspiration, {
    innerRef: inspirationRef
  })), React.createElement("section", {
    className: "CVSection"
  }, React.createElement(ThreeTileMobile, __assign({}, howItWorksProps, {
    innerRef: howItWorksRef
  }))), React.createElement("section", {
    className: "CVSection"
  }, React.createElement(TipsMobile, {
    innerRef: tipsRef
  }))), React.createElement("div", {
    className: "show-desktop"
  }, React.createElement("section", {
    className: "CVSection"
  }, React.createElement(ThreeTile, __assign({}, benefitProps, {
    innerRef: benefitsRef
  }))), React.createElement("section", {
    className: "CVSection"
  }, React.createElement(Inspiration, {
    innerRef: inspirationRef
  })), React.createElement("section", {
    className: "CVSection"
  }, React.createElement(ThreeTile, __assign({}, howItWorksProps, {
    innerRef: howItWorksRef
  }))), React.createElement("section", {
    className: "CVSection"
  }, React.createElement(Tips, {
    innerRef: tipsRef
  }))), React.createElement("section", {
    className: "CVSection"
  }, React.createElement(Faq
  //isMobile={isMobile}
  , {
    //isMobile={isMobile}
    faqList: landingPageFaqList,
    setFaqList: setLandingPageFaqList,
    innerRef: faqRef
  })), React.createElement(Dialog, {
    dialogFullActions: dialogFullActions,
    dialogVCGActions: dialogVCGActions,
    centeredRef: desktopVideoContentGuidelinesTrigger,
    fullRef: fullSheetDialogTrigger,
    isVCG: isVCG,
    fullOpen: isFullDialogOpen,
    vcgOpen: isVCGDialogOpen,
    DialogVCGComponent: DialogVCGComponent,
    DialogFullComponent: DialogFullComponent
  }));
}
export default LandingPage;
export { LandingPage };