import React from 'react';
import { UitkCard, UitkCardContentSection } from 'uitk-react-cards';
import { UitkText } from 'uitk-react-text';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkLink } from 'uitk-react-link';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkLineSkeleton } from 'uitk-react-skeleton';
import { UitkSpacing } from 'uitk-react-spacing';
export function SubmitFormHotelCard(_a) {
  var isLoading = _a.isLoading,
    propertyUrl = _a.propertyUrl,
    hotelName = _a.hotelName,
    location = _a.location,
    onEdit = _a.onEdit;
  return React.createElement(UitkCard, {
    border: true
  }, React.createElement(UitkCardContentSection, {
    padded: false
  }, React.createElement(UitkCardContentSection, {
    padded: true
  }, React.createElement(UitkSpacing, {
    padding: {
      inline: 'two'
    }
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: "three"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: "one"
  }, isLoading ? React.createElement("div", {
    style: {
      position: 'relative',
      width: '100px'
    }
  }, React.createElement(UitkLineSkeleton, null)) : React.createElement(UitkText, {
    size: 300,
    weight: "medium"
  }, hotelName), isLoading ? React.createElement("div", {
    style: {
      position: 'relative',
      width: '200px'
    }
  }, React.createElement(UitkLineSkeleton, null)) : React.createElement(UitkText, {
    size: 100
  }, location)), React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    space: "six",
    className: "CVHotelPreview__actions"
  }, React.createElement(UitkLink, {
    onClick: onEdit,
    size: "small",
    inline: true,
    disabled: isLoading
  }, React.createElement("span", null, React.createElement(UitkIcon, {
    name: "mode_edit",
    size: UitkIconSize.EXTRA_SMALL
  }), "Edit")), React.createElement(UitkLink, {
    size: "small",
    inline: true,
    disabled: isLoading
  }, React.createElement("a", {
    href: propertyUrl,
    target: "_blank",
    rel: "noreferrer"
  }, React.createElement(UitkIcon, {
    name: "open_in_new",
    size: UitkIconSize.EXTRA_SMALL
  }), "Preview"))))))));
}