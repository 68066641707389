var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import React, { useEffect, useRef, useState } from 'react';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkHeading } from 'uitk-react-text';
import { TestimonialsVideo } from './testimonials-video';
import { TestimonialsQuote } from './testimonials-quote';
import { UitkSpacing } from 'uitk-react-spacing';
var TestimonialsSection = function (_a) {
  var refProp = _a.refProp;
  var videoRefs = useRef([]);
  var _b = __read(useState({}), 2),
    playingVideos = _b[0],
    setPlayingVideos = _b[1];
  useEffect(function () {
    var checkIfVideoIsInCenter = function () {
      videoRefs.current.forEach(function (videoElement, index) {
        if (videoElement) {
          var rect = videoElement.getBoundingClientRect();
          var viewportHeight = window.innerHeight;
          // Calculate the center of the viewport
          var viewportCenter = viewportHeight / 2;
          // Calculate the center of the video
          var videoCenter = rect.top + rect.height / 2;
          // If the video center is close to the viewport center, update playback state
          if (videoCenter >= viewportCenter - 150 && videoCenter <= viewportCenter + 150) {
            setPlayingVideos(function (prev) {
              var _a;
              return __assign(__assign({}, prev), (_a = {}, _a[index] = true, _a));
            });
          } else {
            setPlayingVideos(function (prev) {
              var _a;
              return __assign(__assign({}, prev), (_a = {}, _a[index] = false, _a));
            });
          }
        }
      });
    };
    // Add scroll and resize event listeners
    window.addEventListener('scroll', checkIfVideoIsInCenter);
    window.addEventListener('resize', checkIfVideoIsInCenter);
    // Initial check
    checkIfVideoIsInCenter();
    return function () {
      // Cleanup listeners on unmount
      window.removeEventListener('scroll', checkIfVideoIsInCenter);
      window.removeEventListener('resize', checkIfVideoIsInCenter);
    };
  }, []);
  return React.createElement("section", {
    className: "TCTestimonials",
    id: "testimonials",
    ref: refProp
  }, React.createElement("div", {
    className: "container"
  }, React.createElement(UitkLayoutFlex, {
    justifyContent: 'center',
    className: "TCTestimonials__section-heading__container"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement("div", null, React.createElement(UitkHeading, {
    className: "TCTestimonials__section-heading"
  }, "Hear from our partners")))), React.createElement(UitkLayoutFlex, {
    className: "TCTestimonials__container"
  }, React.createElement(UitkLayoutFlexItem, {
    grow: 1,
    className: "TCTestimonials__col TCTestimonials__col__video"
  }, React.createElement("div", {
    ref: function (el) {
      return videoRefs.current[0] = el;
    }
  }, React.createElement(TestimonialsVideo, {
    videoSrc: "https://mediaim.expedia.com/creator-video/creator-inspiration-1.mp4",
    imgSrc: "/images/travel-creator-landing/avatar-reneeroaming.png",
    altText: "Image of Renee Roaming Favourites",
    creatorName: "Renee Roaming Favourites",
    creatorHandle: "@reneeroaming",
    shopUrl: "#",
    isAutoPlaying: playingVideos[0],
    captionsSrc: "https://c.travel-assets.com/eg-affiliates-homepage/captions/travel-creator-video-captions-sample.vtt"
  }))), React.createElement(UitkLayoutFlexItem, {
    grow: 1,
    className: "TCTestimonials__col TCTestimonials__col__quote"
  }, React.createElement("div", null, React.createElement(TestimonialsQuote, {
    quote: "No other brand in the travel space has a shoppable marketplace like this. And even better, Travel Shops was built based on interviews and feedback from creators like me.",
    imgSrc: "/images/travel-creator-landing/avatar-brettconti.png",
    altText: "Image of Brett Conti",
    creatorName: "Brett Conti",
    creatorHandle: "@brettconti",
    shopUrl: "#"
  })))), React.createElement(UitkSpacing, {
    padding: {
      blockstart: 'six'
    }
  }, React.createElement(UitkLayoutFlex, {
    className: "TCTestimonials__container TCTestimonials__container--reverse"
  }, React.createElement(UitkLayoutFlexItem, {
    grow: 1,
    className: "TCTestimonials__col TCTestimonials__col__video"
  }, React.createElement("div", {
    ref: function (el) {
      return videoRefs.current[1] = el;
    }
  }, React.createElement(TestimonialsVideo, {
    videoSrc: "https://mediaim.expedia.com/creator-video/creator-inspiration-1.mp4",
    imgSrc: "/images/travel-creator-landing/avatar-achiengagutu.png",
    altText: "Image of Achieng Agutu Favourites",
    creatorName: "Achieng Agutu",
    creatorHandle: "@achiengagutu",
    shopUrl: "#",
    isAutoPlaying: playingVideos[1],
    captionsSrc: "https://c.travel-assets.com/eg-affiliates-homepage/captions/travel-creator-video-captions-sample.vtt"
  }))), React.createElement(UitkLayoutFlexItem, {
    grow: 1,
    className: "TCTestimonials__col TCTestimonials__col__quote"
  }, React.createElement("div", null, React.createElement(TestimonialsQuote, {
    quote: "Using Expedia's Travel Shops has made it much easier to create, curate and share all of our favorite stays in one place. It's a great way to aggregate all of our hotel stays by city, budget or vibe and also earn commission on our favorite spots we'd be sharing anyway!",
    imgSrc: "/images/travel-creator-landing/avatar-ewa-jeromy.png",
    altText: "Image of Ewa & Jeromy",
    creatorName: "Ewa & Jeromy",
    creatorHandle: "@nom_life",
    shopUrl: "#"
  })))))));
};
export { TestimonialsSection };