var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import React from 'react';
import { usePlatformContext } from '@bernie/platform-context';
import { UitkHeading, UitkParagraph } from 'uitk-react-text';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { Faq } from 'src/features/travel-creator-landing';
import { makeLocaleParam } from 'src/shared/utils';
var FaqSection = function (_a) {
  var refProp = _a.refProp;
  var context = usePlatformContext().context;
  var landingPageInitFaqList = [{
    isVisible: true,
    expandoTitle: 'What is the Travel Creator Program? Who Can Apply?',
    expandoDescription: React.createElement("div", null, React.createElement(UitkParagraph, null, "The Travel Creator Program enables individuals and businesses to earn commissions by promoting our travel properties on social media. It offers a way to generate real income from your travel content, with payments made whenever someone books through your links."), React.createElement(UitkParagraph, null, "Anyone can apply! We review all applications within minutes. If you're interested in earning commissions by sharing travel experiences on your socials, ", React.createElement("a", {
      href: "/".concat(makeLocaleParam(context.locale), "/signup"),
      target: "_blank",
      rel: "noreferrer"
    }, "sign up"), " today."))
  }, {
    isVisible: false,
    expandoTitle: 'What is a Travel Shop?',
    expandoDescription: React.createElement("div", null, React.createElement(UitkParagraph, null, React.createElement("a", {
      href: "https://www.expedia.com/product/travel-shops/",
      target: "_blank",
      rel: "noreferrer"
    }, "Travel Shops"), " is a new feature on the Expedia app that allows Travel Creators to aggregate their travel recommendations all in one spot."), React.createElement(UitkParagraph, null, "Travel Shops allows you to share one link with your audience which will house all your top hotel recommendations and travel content \u2013 the best part, when your audience goes through your affiliate link to book through your Travel Shop, you\u2019ll receive affiliate commission."))
  }, {
    isVisible: false,
    expandoTitle: 'How long does it take for Expedia to review the submitted Travel Video?',
    expandoDescription: React.createElement("div", null, React.createElement(UitkParagraph, null, "While we strive to review submissions promptly, the exact timeframe may vary due to the volume of submissions and other factors. We\u2019ll send updates to the email you provided once the review is complete. Please ensure you check any junk folders so that you don\u2019t miss any important updates."), React.createElement(UitkParagraph, null, "To ensure your video has the best chance of being selected, ", React.createElement("a", {
      href: "https://affiliates.expediagroup.com/en-us/creator-video",
      target: "_blank",
      rel: "noreferrer"
    }, "review the specifications and best practices outlined here"), "."))
  }, {
    isVisible: false,
    expandoTitle: 'What rights does Expedia have to use my video?',
    expandoDescription: React.createElement("div", null, React.createElement(UitkParagraph, null, "If your video is approved, Expedia will own a non-exclusive license to use it across our apps, sites, and social channels (Instagram, Facebook, TikTok, and more) as applicable. This means your video belongs to you and can be reused across other platforms and brand opportunities. Review our ", React.createElement("a", {
      href: "https://affiliates.expediagroup.com/lp/b/affhub/affiliate?siteid=2055&locale=en-us",
      target: "_blank",
      rel: "noreferrer"
    }, "terms and conditions"), " for full details."))
  }, {
    isVisible: false,
    expandoTitle: 'How will I be paid?',
    expandoDescription: React.createElement("div", null, React.createElement(UitkParagraph, null, "We pay commissions via our payment partner, Partnerize. Read our ", React.createElement("a", {
      href: "https://help.affiliates.expediagroup.com/hc/en-us/articles/13163964169495-Payments-101",
      target: "_blank",
      rel: "noreferrer"
    }, "Payments 101"), " article for information on how payments are made."))
  }];
  var faqRef = React.useRef();
  var _b = __read(React.useState(landingPageInitFaqList), 2),
    landingPageFaqList = _b[0],
    setLandingPageFaqList = _b[1];
  return React.createElement("section", {
    className: "TCFaq",
    id: "faq",
    ref: refProp
  }, React.createElement("div", {
    className: 'container'
  }, React.createElement(UitkLayoutFlex, {
    className: 'TCFaq__container'
  }, React.createElement(UitkLayoutFlexItem, {
    className: 'TCFaq__col-left'
  }, React.createElement("div", null, React.createElement(UitkHeading, {
    tag: "h2",
    size: 4
  }, "Questions? ", React.createElement("br", null), " We've got you covered"))), React.createElement(UitkLayoutFlexItem, {
    className: 'TCFaq__col-right'
  }, React.createElement("div", null, React.createElement(Faq, {
    faqList: landingPageFaqList,
    setFaqList: setLandingPageFaqList,
    innerRef: faqRef
  }))))));
};
export { FaqSection };