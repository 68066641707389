var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import React, { useState } from 'react';
import { UitkTypeahead, ESSAdapter } from 'uitk-react-typeahead';
export function SubmitFormTypeahead(_a) {
  var onSelection = _a.onSelection,
    onDismiss = _a.onDismiss;
  var _b = __read(useState(''), 2),
    searchValue = _b[0],
    setSearchValue = _b[1];
  var handleSelection = function (selection) {
    console.log(selection);
    onSelection(selection);
  };
  return React.createElement(UitkTypeahead, {
    adapter: new ESSAdapter({
      client: 'SearchForm',
      dest: false,
      domain: 'https://www.expedia.com/',
      expuserid: -1,
      features: 'consistent_display',
      guid: '',
      lob: 'HOTELS',
      locale: 'en_US',
      // Decides what language suggestions are returned in
      maxResults: 6,
      personalize: true,
      regionType: 128,
      // All regions types + hotels (matches HOTELS lob)
      siteId: 1
    }),
    id: "destination-typeahead",
    stid: "destination-typeahead",
    // className="destination__typeahead"
    name: "destination",
    icon: "place",
    label: "Work",
    value: searchValue,
    typeaheadPlaceholder: "Search for a property",
    allowExactMatch: true,
    onDismiss: onDismiss,
    customTrigger: {
      showTypeahead: true
    },
    hasClearButton: true,
    handleClear: function () {
      return setSearchValue('');
    },
    onUpdate: handleSelection
  });
}