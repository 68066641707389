// 3rd party imports
import * as React from 'react';
//Uitk
import { UitkCard, UitkCardContentSection } from 'uitk-react-cards';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkParagraph, UitkText } from 'uitk-react-text';
import { UitkPrimaryButton, UitkButtonSize } from 'uitk-react-button';
import { useCommonClickStreamEvent, EventType } from 'shared/utils';
export function AffiliateCard() {
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  var handleRedirect = function () {
    trackEvent({
      event_type: EventType.INTERACTION,
      event_name: 'creator_video_join_affiliate.selected',
      action_location: 'affiliate-card-join-today-button'
    });
  };
  return React.createElement("div", {
    className: "CVContainer"
  }, React.createElement(UitkCard, {
    className: "CVAffiliates__card CVSection__width"
  }, React.createElement(UitkLayoutFlex, {
    className: "CVAffiliates__card-wrapper",
    justifyContent: "space-between",
    alignItems: "center"
  }, React.createElement(UitkLayoutFlexItem, {
    className: "CVAffiliates__content"
  }, React.createElement(UitkLayoutFlex, {
    justifyContent: "center",
    className: "cc-affiliate__image"
  }, React.createElement(UitkLayoutFlexItem, {
    alignSelf: "end"
  }, React.createElement("img", {
    alt: "splatter",
    className: "CVAffiliate__image_splatter",
    src: "/images/splatter.svg"
  })), React.createElement(UitkLayoutFlexItem, {
    className: "CVAffiliate__image-wrap"
  }, React.createElement("img", {
    alt: "affiliate dashboard preview",
    className: "CVAffiliate__image",
    src: "/images/Dashboard.png"
  })))), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkCardContentSection, {
    padded: false,
    className: "cc-affiliate-card__text-content"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column"
  }, React.createElement(UitkText, {
    weight: "bold",
    size: 500,
    className: "cc-text-neutral-1-700  CVAffiliate_heading"
  }, "Join the Expedia Group Affiliate Program"), React.createElement(UitkParagraph, {
    className: "CVAffiliates__paragraph-size cc-text-neutral-1-500 ${\n                    CVAffiliate_heading"
  }, "Calling all creators! Join the Expedia Group Affiliate Program today to monetize your content online and grow your audience.")))), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkCardContentSection, null, React.createElement(UitkPrimaryButton, {
    onClick: handleRedirect,
    size: UitkButtonSize.LARGE,
    className: "cc-affiliate-card__button",
    href: "http://console.vap.expedia.com/eg-affiliates",
    target: "_blank",
    tag: "a"
  }, "Join today"))))));
}