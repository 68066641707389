var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
// 3rd party imports
import * as React from 'react';
import { useState } from 'react'; //Uitk
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkExpandoList, UitkExpandoListItem } from 'uitk-react-expando';
import { UitkTertiaryButton } from 'uitk-react-button';
import { UitkTable, UitkTableBody, UitkTableCell, UitkTableCellBorder, UitkTableCellTag, UitkTableRow } from 'uitk-react-tables';
import { UitkList } from 'uitk-react-list';
import { UitkLink } from 'uitk-react-link';
import { EventType, makeLocaleParam, useCommonClickStreamEvent } from 'shared/utils';
import { usePlatformContext } from '@bernie/platform-context';
import { LocalizedText, useLocalization } from '@shared-ui/localization-context';
import { LocalizedTextWrapping } from 'shared/components/localized-text-wrapping';
export function Faq() {
  var l10n = useLocalization();
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  var handleAffiliateRedirectAnalytics = function () {
    trackEvent({
      event_type: EventType.INTERACTION,
      event_name: 'creator_video_join_affiliate.selected',
      action_location: 'landing-page-faq-link'
    });
  };
  var context = usePlatformContext().context;
  var localeParam = makeLocaleParam(context.locale);
  var faqs = [{
    isVisible: true,
    expandoTitle: l10n.formatText('creatorvideos.faq.header.what_is_affiliate'),
    expandoDescription: React.createElement(UitkLayoutFlex, {
      direction: "column",
      space: "three"
    }, React.createElement(UitkText, {
      size: 400
    }, React.createElement(LocalizedText, {
      message: "creatorvideos.faq.paragraph_one.what_is_affiliate"
    })), React.createElement(UitkText, {
      size: 400
    }, "Anyone can apply, and we review all applications within minutes. If you'd like to ", React.createElement(UitkLink, {
      inline: true,
      className: "CVInlineLink",
      onClickCapture: handleAffiliateRedirectAnalytics
    }, React.createElement("a", {
      rel: "noreferrer",
      target: "_blank",
      href: "https://console.vap.expedia.com/eg-affiliates/signup"
    }, React.createElement(LocalizedText, {
      message: "creatorvideos.faq.link_two.what_is_affiliate"
    }))), ' ', "and earn commission from selling travel!"))
  }, {
    isVisible: false,
    expandoTitle: l10n.formatText('creatorvideos.faq.header.what_is_beta_program'),
    expandoDescription: React.createElement(UitkText, {
      size: 400
    }, React.createElement(LocalizedText, {
      message: "creatorvideos.faq.paragraph_one.what_is_beta_program"
    }))
  }, {
    isVisible: false,
    expandoTitle: l10n.formatText('creatorvideo.faq.how_long'),
    expandoDescription: React.createElement(React.Fragment, null, React.createElement(UitkText, {
      size: 400
    }, React.createElement(LocalizedText, {
      message: "creatorvideo.faq.paragraph.how_long"
    })))
  }, {
    isVisible: false,
    expandoTitle: l10n.formatText('creatorvideo.faq.how_much'),
    expandoDescription: React.createElement(React.Fragment, null, React.createElement(UitkText, {
      size: 400
    }, React.createElement(LocalizedText, {
      message: "creatorvideo.faq.paragraph.how_much"
    })))
  }, {
    isVisible: false,
    expandoTitle: l10n.formatText('creatorvideos.faq.header.how_do_i_submit'),
    expandoDescription: React.createElement(React.Fragment, null, React.createElement(UitkText, {
      size: 400
    }, "Join the Expedia Group Affiliate Program to submit a video following the", ' ', React.createElement(UitkTertiaryButton, {
      tag: "a",
      target: "_blank",
      href: "/en-us/travel-video/video-guidelines"
    }, React.createElement(LocalizedText, {
      message: "creatorvideos.faq.link_one.how_do_i_submit"
    })), "."))
  }, {
    isVisible: false,
    expandoTitle: l10n.formatText('creatorvideos.faq.header.multiple_videos'),
    expandoDescription: React.createElement(React.Fragment, null, React.createElement(UitkText, {
      size: 400
    }, React.createElement(LocalizedText, {
      message: "creatorvideos.faq.paragraph_one.how_do_i_submit"
    })))
  }, {
    isVisible: false,
    expandoTitle: l10n.formatText('creatorvideos.faq.header.video_specs'),
    expandoDescription: React.createElement(React.Fragment, null, React.createElement(UitkText, {
      size: 400
    }, React.createElement(LocalizedText, {
      message: "creatorvideos.faq.paragraph_one.video_specs"
    })), React.createElement(UitkTable, {
      border: true,
      isRounded: true,
      className: "CVFaq__Table"
    }, React.createElement(UitkTableBody, null, React.createElement(UitkTableRow, null, React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical],
      tag: UitkTableCellTag.header
    }, React.createElement(LocalizedText, {
      message: "creatorvideos.faq.table_header.video_duration"
    })), React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]
    }, React.createElement(LocalizedText, {
      message: "creatorvideos.faq.table_content.video_duration"
    }))), React.createElement(UitkTableRow, null, React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical],
      tag: UitkTableCellTag.header
    }, React.createElement(LocalizedText, {
      message: "creatorvideos.faq.table_header.format"
    })), React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]
    }, React.createElement(LocalizedText, {
      message: "creatorvideos.faq.table_content.format"
    }))), React.createElement(UitkTableRow, null, React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical],
      tag: UitkTableCellTag.header
    }, React.createElement(LocalizedText, {
      message: "creatorvideos.faq.table_header.file_size"
    })), React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]
    }, React.createElement(LocalizedText, {
      message: "creatorvideos.faq.table_content.file_size"
    }))), React.createElement(UitkTableRow, null, React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical],
      tag: UitkTableCellTag.header
    }, React.createElement(LocalizedText, {
      message: "creatorvideos.faq.table_header.resolution"
    })), React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]
    }, React.createElement(LocalizedText, {
      message: "creatorvideos.faq.table_content.resolution"
    }))), React.createElement(UitkTableRow, null, React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical],
      tag: UitkTableCellTag.header
    }, React.createElement(LocalizedText, {
      message: "creatorvideos.faq.table_header.orientation"
    })), React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]
    }, React.createElement(LocalizedText, {
      message: "creatorvideos.faq.table_content.orientation"
    }))), React.createElement(UitkTableRow, null, React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical],
      tag: UitkTableCellTag.header
    }, React.createElement(LocalizedText, {
      message: "creatorvideos.faq.table_header.audio"
    })), React.createElement(UitkTableCell, {
      border: [UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]
    }, React.createElement(LocalizedText, {
      message: "creatorvideos.faq.table_content.audio"
    }))))))
  }, {
    isVisible: false,
    expandoTitle: l10n.formatText('travelvideos.faq_subheading.how_long'),
    expandoDescription: React.createElement(UitkText, {
      size: 400
    }, React.createElement(LocalizedText, {
      message: "travelvideos.faq.content.how_long"
    }))
  }, {
    isVisible: false,
    expandoTitle: l10n.formatText('travelvideos.faq_subheading.how-qualifies'),
    expandoDescription: React.createElement(UitkText, {
      size: 400
    }, React.createElement(LocalizedTextWrapping, {
      fieldName: "link",
      message: "travelvideos.faq.content.how-qualifies"
    }, React.createElement(UitkLink, {
      inline: true,
      className: "CVInlineLink",
      onClickCapture: handleAffiliateRedirectAnalytics
    }, React.createElement("a", {
      rel: "noreferrer",
      target: "_blank",
      href: "https://help.affiliates.expediagroup.com/hc/en-us/articles/15185581399447-How-am-I-paid"
    }, "payment"))))
  }, {
    isVisible: false,
    expandoTitle: l10n.formatText('travelvideos.faq_subheading.rights'),
    expandoDescription: React.createElement(UitkText, {
      size: 400
    }, React.createElement(LocalizedTextWrapping, {
      fieldName: "link",
      message: "travelvideos.faq.content.rights"
    }, React.createElement(UitkLink, {
      inline: true,
      className: "CVInlineLink"
    }, React.createElement("a", {
      rel: "noreferrer",
      target: "_blank",
      href: "https://affiliates.expediagroup.com/lp/b/affhub/affiliate?siteid=2055&locale=".concat(localeParam)
    }, React.createElement(LocalizedText, {
      message: "travelvideos.faq.content.rights_link"
    })))))
  }, {
    isVisible: false,
    expandoTitle: l10n.formatText('travelvideos.faq_subheading.best_selected'),
    expandoDescription: React.createElement(React.Fragment, null, React.createElement(UitkText, {
      size: 400
    }, React.createElement(LocalizedTextWrapping, {
      message: "travelvideos.faq.content.best_selected_pre_list",
      fieldName: "link"
    }, React.createElement(UitkTertiaryButton, {
      tag: "a",
      target: "_blank",
      href: "/en-us/travel-video/video-guidelines"
    }, React.createElement(LocalizedText, {
      message: "travelvideos.general.content_guidelines"
    })))), React.createElement(UitkList, null, React.createElement("li", {
      key: "Faq-list-best-chance-brands"
    }, React.createElement(UitkText, {
      size: 400
    }, React.createElement(LocalizedTextWrapping, {
      message: "travelvideos.faq.content.best_selected_list_one",
      fieldName: "link"
    }, React.createElement(UitkLink, {
      inline: true
    }, React.createElement("a", {
      rel: "noreferrer",
      target: "_blank",
      href: "https://www.expediagroup.com/travel-with-us/default.aspx"
    }, React.createElement(LocalizedText, {
      message: "travelvideos.faq.content.best_selected_list_one_link"
    })))))), React.createElement("li", {
      key: "Faq-list-best-chance-price"
    }, React.createElement(UitkText, {
      size: 400
    }, React.createElement(LocalizedText, {
      message: "travelvideos.faq.content.best_selected_list_two"
    }))), React.createElement("li", {
      key: "Faq-list-best-chance-cta"
    }, React.createElement(UitkText, {
      size: 400
    }, React.createElement(LocalizedText, {
      message: "travelvideos.faq.content.best_selected_list_three"
    }))), React.createElement("li", {
      key: "Faq-list-best-chance-text"
    }, React.createElement(UitkText, {
      size: 400
    }, React.createElement(LocalizedText, {
      message: "travelvideos.faq.content.best_selected_list_four"
    }))), React.createElement("li", {
      key: "Faq-list-best-chance-pov"
    }, React.createElement(UitkText, {
      size: 400
    }, React.createElement(LocalizedText, {
      message: "travelvideos.faq.content.best_selected_list_five"
    })))))
  }];
  var _a = __read(useState(faqs), 2),
    list = _a[0],
    setList = _a[1];
  return React.createElement("div", {
    className: "CVContainer"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    alignItems: "center",
    space: "twelve",
    className: "CVFaq"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    alignItems: "center",
    space: "six"
  }, React.createElement(UitkText, {
    size: 400,
    className: "cc-text-neutral-1-500"
  }, React.createElement(LocalizedText, {
    message: "creatorvideos.common.FAQ"
  })), React.createElement(UitkHeading, {
    size: 4,
    align: "center"
  }, React.createElement(LocalizedText, {
    message: "creatorvideos.landing_page.faq.heading"
  })), React.createElement(UitkText, {
    size: 500,
    className: "cc-text-neutral-1-600",
    align: "center"
  }, React.createElement(LocalizedText, {
    message: "creatorvideos.landing_page.faq.everything"
  }))), React.createElement(UitkExpandoList, {
    className: "CVFaq__expando-list"
  }, list.map(function (listItem, index) {
    return React.createElement("div", {
      className: "CVFaq__expando-list-item",
      key: "faq-list-item-".concat(index)
    }, React.createElement(UitkExpandoListItem, __assign({}, listItem, {
      onToggle: function () {
        listItem.isVisible = !listItem.isVisible;
        trackEvent({
          event_type: EventType.INTERACTION,
          event_name: 'creator_video_faq.selected',
          action_location: 'travel-video-faq'
        }, {
          article: {
            article_name: listItem.expandoTitle
          }
        });
        setList(__spreadArray([], __read(list), false));
      },
      triggerPosition: "trailing"
    })));
  }))));
}