var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import { useState, useEffect } from 'react';
function useDevice() {
  var defaultWidth = 800;
  var defaultHeight = 600;
  var _a = __read(useState({
      width: typeof window !== 'undefined' ? window.innerWidth : defaultWidth,
      height: typeof window !== 'undefined' ? window.innerHeight : defaultHeight
    }), 2),
    screenSize = _a[0],
    setScreenSize = _a[1];
  var _b = __read(useState(screenSize.width < 768), 2),
    isMobile = _b[0],
    setIsMobile = _b[1];
  useEffect(function () {
    if (typeof window !== 'undefined') {
      var handleResize_1 = function () {
        setScreenSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
        setIsMobile(window.innerWidth < 768);
      };
      window.addEventListener('resize', handleResize_1);
      return function () {
        window.removeEventListener('resize', handleResize_1);
      };
    }
  }, []);
  return {
    isMobile: isMobile,
    screenSize: screenSize
  };
}
export { useDevice };