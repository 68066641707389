var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
// 3rd party imports
import * as React from 'react';
// ui
import { LocalizedText, useLocalization } from '@shared-ui/localization-context';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkCarousel } from 'uitk-react-carousel';
import { UitkCard } from 'uitk-react-cards';
import { UitkFigure, UitkImage } from 'uitk-react-images';
import { UitkIcon } from 'uitk-react-icons';
import { UitkLayoutGrid, UitkLayoutGridItem } from 'uitk-react-layout-grid';
import { BrandItems } from './brand-cards-items';
function BrandCards() {
  var useRef = React.useRef,
    useState = React.useState,
    useEffect = React.useEffect;
  var _a = __read(useState(0), 2),
    currentIndex = _a[0],
    setCurrentIndex = _a[1];
  var carouselRef = useRef(null);
  var l10n = useLocalization();
  var onIndexChange = function (index) {
    setCurrentIndex(index);
  };
  var numberOfCards = BrandItems().length;
  var cardIndex = 0;
  useEffect(function () {
    var timer = setTimeout(function () {
      cardIndex = currentIndex < numberOfCards - 3 ? currentIndex + 1 : 0;
      carouselRef.current.changeIndex(cardIndex);
    }, 3000);
    return function () {
      return clearTimeout(timer);
    };
  }, [currentIndex]);
  var brandCardsCarouselProps = {
    carouselName: 'BrandCardsCarousel',
    itemsVisible: {
      sm: 1,
      md: 2,
      lg: 3
    },
    pageBy: 1,
    peek: true,
    wrapItems: false,
    buttonText: {
      nextButton: l10n.formatText('common.next'),
      prevButton: l10n.formatText('common.previous')
    },
    onIndexChange: onIndexChange,
    navigationIconId: {
      next: 'next-button-external-control',
      prev: 'prev-button-external-control'
    },
    peekWidth: 20
  };
  return React.createElement("div", {
    className: "BrandCards container"
  }, React.createElement(UitkSpacing, {
    padding: {
      blockend: 'six'
    }
  }, React.createElement(UitkHeading, {
    tag: "h2",
    size: 3
  }, React.createElement(LocalizedText, {
    message: "home.brand-cards.heading.grow_with_our_brands"
  }))), React.createElement(UitkCarousel, __assign({}, brandCardsCarouselProps, {
    carouselRef: carouselRef
  }), BrandItems().map(function (item, i) {
    return React.createElement(UitkCard, {
      key: i,
      tag: "li",
      padded: true,
      border: true
    }, React.createElement(UitkFigure, {
      imageFit: "contain",
      className: "Brands__logo"
    }, React.createElement(UitkImage, {
      alt: item.brand,
      src: item.logo
    })), React.createElement(UitkSpacing, {
      padding: {
        block: 'four'
      }
    }, React.createElement(UitkText, {
      size: 300
    }, item.description)), React.createElement(UitkLayoutGrid, {
      columns: 2,
      space: "two"
    }, item.lobs.map(function (lob, l) {
      return React.createElement(UitkLayoutGridItem, {
        key: l
      }, React.createElement(UitkText, {
        size: 200,
        className: "BrandCards__lob-item"
      }, React.createElement(UitkIcon, {
        name: "lob_".concat(lob)
      }), React.createElement(LocalizedText, {
        message: "programs.lobs.".concat(lob)
      })));
    })));
  })));
}
export { BrandCards };