// 3rd party imports
import * as React from 'react';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
// bernie
import { updateSearch } from 'bernie-core';
import { UitkSpacing } from 'uitk-react-spacing';
import { useLocalization } from '@shared-ui/localization-context';
import { useStores } from 'shared/hooks';
import { withRouter } from 'react-router-dom';
import { FlexDocumentDialog } from 'features/flex-document';
import { usePlatformContext } from '@bernie/platform-context';
var FlexDocumentComponent = observer(function (_a) {
  var documentName = _a.documentName,
    history = _a.history,
    location = _a.location;
  var uiStore = useStores().uiStore;
  var context = usePlatformContext().context;
  var l10n = useLocalization();
  useEffect(function () {
    updateSearch({
      history: history,
      location: location,
      newParams: {
        pwaDialog: 'flex-document-dialog'
      }
    });
  }, []);
  var url;
  var headerText;
  if (documentName) {
    var documentPrefix = uiStore === null || uiStore === void 0 ? void 0 : uiStore.flexDocumentPrefix;
    var locale = context.locale;
    url = "".concat(documentPrefix, "/lp/b/affhub/").concat(documentName, "?siteid=2055&locale=").concat(locale);
    var headerName = {
      'terms-of-use': 'terms.page.title',
      'privacy-policy': 'privacy.policy.page.title',
      'cookie-policy': 'cookie.policy.page.title'
    }[documentName] || false;
    headerText = headerName ? l10n.formatText(headerName) : '';
  }
  return React.createElement(UitkSpacing, {
    className: "container--small",
    padding: {
      block: 'twelve'
    }
  }, React.createElement(FlexDocumentDialog, {
    url: url,
    headerText: headerText
  }));
});
export var FlexDocument = withRouter(FlexDocumentComponent);