import * as React from 'react';
import { Layout, VideoGuidelinesContent } from 'features/creator-video';
import { UitkSpacing } from 'uitk-react-spacing';
import { EventType, useCommonClickStreamEvent } from 'shared/utils';
function VideoGuidelinesPage() {
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  React.useEffect(function () {
    trackEvent({
      event_type: EventType.IMPRESSION,
      event_name: 'creator_video_content_guidelines.presented',
      action_location: 'content_guidelines-page'
    });
  }, []);
  return React.createElement(Layout, null, React.createElement("div", {
    className: "container"
  }, React.createElement(UitkSpacing, {
    padding: {
      block: 'six'
    }
  }, React.createElement("div", null, React.createElement(VideoGuidelinesContent, null)))));
}
export default VideoGuidelinesPage;
export { VideoGuidelinesPage };