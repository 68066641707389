var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import React, { useId } from 'react';
import { usePlatformContext } from '@bernie/platform-context';
import { UitkCarousel } from 'uitk-react-carousel';
import { UitkText, UitkHeading } from 'uitk-react-text';
import { UitkTertiaryButton } from 'uitk-react-button';
import { UitkLayoutGrid } from 'uitk-react-layout-grid';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { makeLocaleParam } from 'shared/utils';
import { LocalizedText, useLocalization } from '@shared-ui/localization-context';
export function HowItWorks() {
  var l10n = useLocalization();
  var context = usePlatformContext().context;
  var benefits = [{
    icon: '/images/creator-video/how-it-works-one.svg',
    title: l10n.formatText('creatorvideos.landing_page.how_it_works.card.become_affiliate'),
    content: React.createElement(UitkText, {
      size: 400
    }, React.createElement(LocalizedText, {
      message: "creatorvideos.landing_page.how_it_works.card.become_affiliate.paragraph"
    }))
  }, {
    icon: '/images/creator-video/how-it-works-two.svg',
    title: l10n.formatText('creatorvideos.landing_page.how_it_works.card.share_your'),
    content: React.createElement(UitkText, {
      size: 400
    }, "We're looking for video quality, property highlights, and engagement potential, among other criteria. Read our", ' ', React.createElement(UitkTertiaryButton, {
      type: "button",
      className: "CVVcg__button",
      tag: "a",
      target: "_blank",
      href: "/".concat(makeLocaleParam(context.locale), "/travel-video/video-guidelines")
    }, React.createElement(LocalizedText, {
      message: "creatorvideos.landing_page.how_it_works.card.share_your.link"
    })), ".")
  }, {
    icon: '/images/creator-video/how-it-works-three.svg',
    title: l10n.formatText('creatorvideos.landing_page.how_it_works.card.earn'),
    content: React.createElement(UitkText, {
      size: 400
    }, React.createElement(LocalizedText, {
      message: "creatorvideos.landing_page.how_it_works.card.earn.paragraph"
    }))
  }];
  var props = {
    carouselName: 'Carouseledgetoedge',
    carouselHeaderText: 'Carousel with edge-to-edge layout via inner spacing',
    useContainerSizing: false,
    itemsMaxHeight: true,
    innerSpacing: {
      sm: 48,
      md: 32,
      lg: 24
    },
    itemsVisible: {
      sm: 1,
      md: 2,
      lg: 3
    },
    peek: true,
    peekAmount: {
      sm: 64,
      md: 48,
      lg: 24
    },
    itemsGap: {
      sm: 24,
      md: 12,
      lg: 12
    },
    pagingInset: true,
    wrapItems: true,
    navigationIconId: {
      next: 'next-button',
      prev: 'prev-button'
    },
    buttonText: {
      nextButton: 'Show next card',
      prevButton: 'Show previous card'
    }
  };
  return React.createElement("div", null, React.createElement(UitkCarousel, __assign({}, props, {
    className: "hide-desktop"
  }), benefits.map(function (item) {
    return React.createElement(UitkLayoutFlex, {
      direction: "column",
      alignItems: "center",
      justifyContent: "start",
      space: "six",
      key: useId()
    }, React.createElement("img", {
      className: 'cc-three-tile__small-grid-svg',
      src: item.icon
    }), React.createElement(UitkLayoutFlex, {
      direction: "column",
      alignItems: "center",
      justifyContent: "center",
      space: "one"
    }, React.createElement(UitkHeading, {
      size: 5,
      align: "center"
    }, item.title), React.createElement("div", null, item.content)));
  })), React.createElement(UitkLayoutGrid, {
    columns: 3,
    space: "twelve",
    className: "show-desktop"
  }, benefits.map(function (item) {
    return React.createElement("div", {
      className: "CVThreeTile__grid-item",
      key: useId()
    }, React.createElement("img", {
      className: 'cc-three-tile__small-grid-svg',
      src: item.icon
    }), React.createElement(UitkHeading, {
      size: 5
    }, item.title), React.createElement("div", null, item.content));
  })));
}