import React from 'react';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkLink } from 'uitk-react-link';
import { UitkText } from 'uitk-react-text';
import { usePlatformContext } from '@bernie/platform-context';
import { makeLocaleParam } from 'src/shared/utils';
var Footer = function () {
  var context = usePlatformContext().context;
  return React.createElement("footer", {
    className: "TCFooter"
  }, React.createElement("div", {
    className: 'container'
  }, React.createElement(UitkLayoutFlex, {
    justifyContent: "space-between",
    space: "six",
    className: "TCFooter__content"
  }, React.createElement(UitkLayoutFlex, {
    className: "CVFooter__links",
    alignItems: "center",
    wrap: "wrap",
    space: "six"
  }, React.createElement(UitkLink, {
    inline: true,
    className: "CVFooter__button"
  }, React.createElement(UitkText, {
    size: 200
  }, React.createElement("a", {
    href: "/".concat(makeLocaleParam(context.locale), "/doc/affiliate"),
    role: "link",
    target: "_blank",
    rel: "noreferrer"
  }, "Terms & conditions"))), React.createElement(UitkLink, {
    inline: true,
    className: "CVFooter__button"
  }, React.createElement(UitkText, {
    size: 200
  }, React.createElement("a", {
    href: "https://help.affiliates.expediagroup.com/hc/en-us",
    role: "link",
    target: "_blank",
    rel: "noreferrer"
  }, "Help center"))), React.createElement(UitkLink, {
    inline: true,
    className: "CVFooter__button"
  }, React.createElement(UitkText, {
    size: 200
  }, React.createElement("a", {
    href: "mailto:affiliatehubsupport@expediagroup.com"
  }, "Contact us")))), React.createElement(UitkText, {
    size: 200
  }, "\u00A9 2024 Expedia, Inc. All rights reserved. Confidential and proprietary."))));
};
export { Footer };