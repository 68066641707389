import React from 'react';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkText } from 'uitk-react-text';
import { TestimonialsCreatorDetails } from './testimonials-creator-details';
var TestimonialsQuote = function (_a) {
  var quote = _a.quote,
    imgSrc = _a.imgSrc,
    altText = _a.altText,
    creatorName = _a.creatorName,
    creatorHandle = _a.creatorHandle,
    shopUrl = _a.shopUrl;
  return React.createElement(UitkLayoutFlex, {
    direction: "column",
    alignItems: 'start',
    justifyContent: "center",
    space: 'four',
    className: 'TCTestimonials__quote-container'
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement("div", null, React.createElement("img", {
    src: "/images/travel-creator-landing/quotation-marks.svg",
    alt: "Quote"
  }))), React.createElement(UitkLayoutFlexItem, null, React.createElement("div", null, React.createElement(UitkText, {
    size: 500,
    theme: "emphasis",
    className: "TCTestimonials__quote"
  }, quote))), React.createElement(UitkLayoutFlexItem, null, React.createElement("div", null, React.createElement(TestimonialsCreatorDetails, {
    imgSrc: imgSrc,
    altText: altText,
    creatorName: creatorName,
    creatorHandle: creatorHandle,
    shopUrl: shopUrl
  }))));
};
export { TestimonialsQuote };