var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
// 3rd party
import { action, makeObservable, observable } from 'mobx';
// Bernie
import { Store } from 'bernie-plugin-mobx';
import { NOOP_LOGGER } from 'bernie-logger';
var UiStore = /** @class */function (_super) {
  __extends(UiStore, _super);
  function UiStore(config, state, logger) {
    if (state === void 0) {
      state = {};
    }
    if (logger === void 0) {
      logger = NOOP_LOGGER;
    }
    var _a, _b, _c, _d, _e;
    var _this = _super.call(this, state, logger) || this;
    _this.logger = logger;
    _this.pageName = '';
    _this.bffUrl = '';
    _this.flexDocumentPrefix = '';
    _this.documentName = '';
    _this.additionalParams = '';
    makeObservable(_this, {
      pageName: observable,
      setPageName: action,
      flexDocumentPrefix: observable,
      setDocumentName: action,
      setAdditionalParams: action,
      bffUrl: observable
    });
    _this.bffUrl = "".concat((_b = (_a = config.app) === null || _a === void 0 ? void 0 : _a.bff) === null || _b === void 0 ? void 0 : _b.protocol, "//").concat((_d = (_c = config.app) === null || _c === void 0 ? void 0 : _c.bff) === null || _d === void 0 ? void 0 : _d.hostname);
    _this.flexDocumentPrefix = (_e = config.app) === null || _e === void 0 ? void 0 : _e.flexDocumentPrefix;
    return _this;
  }
  UiStore.prototype.hydrate = function (data) {
    Object.assign(this, data);
  };
  UiStore.prototype.setPageName = function (name) {
    this.pageName = name;
  };
  UiStore.prototype.setDocumentName = function (documentName) {
    this.documentName = documentName;
  };
  UiStore.prototype.setAdditionalParams = function (paramName) {
    this.additionalParams = paramName;
  };
  return UiStore;
}(Store);
export { UiStore };