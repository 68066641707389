import * as React from 'react';
import { observer } from 'mobx-react';
import { Layout } from 'shared/layout';
import { FlexDocument } from 'features/flex-document';
import { useStores } from 'shared/hooks';
var FlexDocumentPage = observer(
// tslint:disable-next-line:no-shadowed-variable
function FlexDocumentPage() {
  var uiStore = useStores().uiStore;
  return React.createElement(Layout, null, React.createElement(FlexDocument, {
    documentName: uiStore === null || uiStore === void 0 ? void 0 : uiStore.documentName
  }));
});
export default FlexDocumentPage;
export { FlexDocumentPage };