var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import React, { useState, createContext } from 'react';
import { createStore, useStore } from 'zustand';
var QueryState = createContext(null);
export var QueryStateProvider = function (props) {
  var children = props.children,
    rest = __rest(props, ["children"]);
  var _a = __read(useState(function () {
      return createStore(function (set) {
        return __assign(__assign({}, rest), {
          actions: {
            // just an example - probably shouldn't use this because we should refresh if the locale changes
            setLocale: function (locale) {
              return set({
                locale: locale
              });
            }
          }
        });
      });
    }), 1),
    store = _a[0];
  return React.createElement(QueryState.Provider, {
    value: store
  }, children);
};
export var useQueryState = function (selector) {
  var store = React.useContext(QueryState);
  return useStore(store, selector);
};