export function getVideoThumbnail(videoElement) {
  return new Promise(function (resolve) {
    var captureFrame = function () {
      var canvas = document.createElement('canvas');
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      var ctx = canvas.getContext('2d');
      ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
      var imgSrc = canvas.toDataURL('image/jpeg');
      resolve(imgSrc);
    };
    videoElement.addEventListener('loadeddata', function () {
      videoElement.currentTime = 0.1;
    });
    videoElement.addEventListener('seeked', function () {
      setTimeout(captureFrame, 100);
    });
  });
}