var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import * as React from 'react';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkFigure } from 'uitk-react-images';
import { UitkIcon } from 'uitk-react-icons';
import { UitkButtonSize, UitkOverlayButton } from 'uitk-react-button';
import { useCommonClickStreamEvent, EventType } from 'shared/utils';
export function InspirationDisplayVideo(_a) {
  var videoUrl = _a.videoUrl,
    poster = _a.poster;
  var videoRef = React.useRef(null);
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  var _b = __read(React.useState(false), 2),
    isPlaying = _b[0],
    setIsPlaying = _b[1];
  var _c = __read(React.useState(true), 2),
    isMuted = _c[0],
    setIsMuted = _c[1];
  React.useEffect(function () {
    var _a;
    if (isPlaying && videoRef.current) {
      trackEvent({
        event_type: EventType.IMPRESSION,
        event_name: 'creator_video_media.presented',
        action_location: 'inspiration-video'
      }, {
        additional_context: {
          user_interface: {
            component_name: 'inspiration video is played',
            component_element_name: "".concat((_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.src, " video")
          }
        }
      });
    }
  }, [isPlaying, videoRef === null || videoRef === void 0 ? void 0 : videoRef.current]);
  function togglePlay() {
    setIsPlaying(!isPlaying);
    videoRef.current[isPlaying ? 'pause' : 'play']();
  }
  function toggleMute() {
    setIsMuted(!isMuted);
    videoRef.current.muted = !isMuted;
  }
  return React.createElement(UitkLayoutFlex, {
    className: "CVInspiration__example-card",
    direction: "column"
  }, React.createElement("div", null, React.createElement(UitkFigure, {
    className: "CVInspiration_display-video"
  }, React.createElement("video", {
    role: "video",
    poster: poster,
    playsInline: true,
    src: videoUrl,
    style: {
      width: '100%',
      position: 'relative'
    },
    ref: videoRef,
    muted: isMuted
  }), React.createElement(UitkLayoutFlex, {
    direction: "row",
    className: "CVInspiration__overlay-buttons",
    justifyContent: "space-between"
  }, React.createElement(UitkOverlayButton, {
    onClick: togglePlay,
    size: UitkButtonSize.SMALL,
    className: "CVInspiration__overlay"
  }, isPlaying ? React.createElement(React.Fragment, null, React.createElement(UitkIcon, {
    name: "pause"
  }), " Pause") : React.createElement(React.Fragment, null, React.createElement(UitkIcon, {
    name: "play_arrow"
  }), " Play")), React.createElement(UitkOverlayButton, {
    onClick: toggleMute,
    size: UitkButtonSize.SMALL,
    className: "CVInspiration__overlay",
    "aria-label": "play"
  }, isMuted ? React.createElement(React.Fragment, null, React.createElement(UitkIcon, {
    name: "volume_off"
  })) : React.createElement(React.Fragment, null, React.createElement(UitkIcon, {
    name: "volume_up"
  })))))));
}