var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import React, { useEffect, useRef } from 'react';
import { UitkOverlayButton } from 'uitk-react-button';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { TestimonialsCreatorDetails } from './testimonials-creator-details';
import { UitkIcon } from 'uitk-react-icons';
import { UitkFigure } from 'uitk-react-images';
var TestimonialsVideo = function (_a) {
  var videoSrc = _a.videoSrc,
    imgSrc = _a.imgSrc,
    altText = _a.altText,
    creatorName = _a.creatorName,
    creatorHandle = _a.creatorHandle,
    shopUrl = _a.shopUrl,
    isAutoPlaying = _a.isAutoPlaying,
    captionsSrc = _a.captionsSrc;
  var videoRef = useRef(null);
  var _b = __read(React.useState(false), 2),
    isPlaying = _b[0],
    setIsPlaying = _b[1];
  var _c = __read(React.useState(true), 2),
    isMuted = _c[0],
    setIsMuted = _c[1];
  useEffect(function () {
    if (videoRef.current) {
      if (isAutoPlaying && !isPlaying) {
        videoRef.current.play();
        setIsPlaying(true);
      } else if (!isAutoPlaying && !isPlaying) {
        videoRef.current.pause();
        setIsPlaying(false);
      } else if (!isAutoPlaying && isPlaying) {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  }, [isAutoPlaying]);
  function togglePlay() {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true); // Track that the user played the video
    } else {
      videoRef.current.pause();
      setIsPlaying(false); // Track that the user paused the video
    }
  }
  function toggleMute() {
    setIsMuted(!isMuted);
    videoRef.current.muted = !isMuted;
  }
  return React.createElement(UitkLayoutFlex, {
    direction: "column",
    alignItems: 'center',
    justifyContent: "center",
    space: 'four'
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement("div", null, React.createElement(UitkFigure, null, React.createElement("video", {
    role: "video",
    playsInline: true,
    muted: isMuted,
    className: "TCTestimonials__video",
    ref: videoRef,
    crossOrigin: "anonymous"
  }, React.createElement("source", {
    src: videoSrc,
    type: "video/mp4"
  }), React.createElement("track", {
    kind: "captions",
    src: captionsSrc,
    srcLang: "en",
    label: "English",
    default: true
  }))))), React.createElement(UitkLayoutFlexItem, {
    grow: 1
  }, React.createElement(UitkLayoutFlex, {
    className: "TCTestimonials__video-controls",
    justifyContent: 'space-between'
  }, React.createElement(UitkLayoutFlexItem, {
    grow: 1
  }, React.createElement(TestimonialsCreatorDetails, {
    imgSrc: imgSrc,
    altText: altText,
    creatorName: creatorName,
    creatorHandle: creatorHandle,
    shopUrl: shopUrl
  })), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkLayoutFlex, {
    space: "two",
    alignItems: 'center'
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkOverlayButton, {
    onClick: togglePlay,
    className: "TCTestimonials__video__button"
  }, isPlaying ? React.createElement(React.Fragment, null, React.createElement(UitkIcon, {
    name: "pause"
  })) : React.createElement(React.Fragment, null, React.createElement(UitkIcon, {
    name: "play_arrow"
  })))), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkOverlayButton, {
    onClick: toggleMute,
    className: "TCTestimonials__video__button"
  }, isMuted ? React.createElement(React.Fragment, null, React.createElement(UitkIcon, {
    name: "volume_off"
  })) : React.createElement(React.Fragment, null, React.createElement(UitkIcon, {
    name: "volume_up"
  })))))))));
};
export { TestimonialsVideo };