export function validateVideo(file, videoElement) {
  return new Promise(function (resolve) {
    var errors = [];
    if (file.size > 500 * 1024 * 1024) {
      errors.push('The file size must be less than 500MB.');
    }
    videoElement.addEventListener('loadedmetadata', function () {
      if (videoElement.videoWidth < 1080 || videoElement.videoHeight < 1920) {
        errors.push('The video resolution must be at least 1080*1920.');
      }
      var aspectRatio = videoElement.videoWidth / videoElement.videoHeight;
      var expectedAspectRatio = 9 / 16;
      var tolerance = 0.01;
      if (Math.abs(aspectRatio - expectedAspectRatio) > tolerance) {
        errors.push('The video must be in a 9:16 aspect ratio.');
      }
      if (videoElement.duration <= 5.9 || videoElement.duration >= 60) {
        errors.push('The video must be between 6 - 60 seconds.');
      }
      resolve(errors);
    }, {
      once: true
    });
  });
}