var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDialog } from '@shared-ui/dialog-context';
import { LocalizedText } from '@shared-ui/localization-context';
import { useViewportMap, ViewSizes } from '@shared-ui/viewport-context';
import { UitkPrimaryButton, UitkButtonSize, UitkTertiaryButton, UitkSecondaryButton } from 'uitk-react-button';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkIcon } from 'uitk-react-icons';
import { UitkSheet, UitkSheetContent, UitkSheetTransition } from 'uitk-react-sheet';
import { UitkSpacing } from 'uitk-react-spacing';
import { navItems } from 'features/travel-creator-landing';
import { usePlatformContext } from '@bernie/platform-context';
import { MobileLanguageSelector } from 'features/travel-creator-landing';
import { LanguageSelector } from 'shared/language-selector';
import { makeLocaleParam } from 'shared/utils';
var HeaderActions = function (_a) {
  var _b;
  var sectionsRef = _a.sectionsRef;
  var triggerRef = useRef();
  var _c = __read(useDialog('full-sheet-header-menu'), 3),
    isDialogOpen = _c[0],
    dialogActions = _c[1],
    DialogComponent = _c[2];
  var context = usePlatformContext().context;
  var joinNowButtonViewportProps = useViewportMap((_b = {}, _b[ViewSizes.SMALL] = {
    size: UitkButtonSize.MEDIUM
  }, _b[ViewSizes.MEDIUM] = {
    size: UitkButtonSize.LARGE
  }, _b));
  var openDialog = function () {
    dialogActions.openDialog();
  };
  var closeDialog = function () {
    dialogActions.closeDialog();
  };
  var scrollToSection = function (section) {
    closeDialog();
    setTimeout(function () {
      sectionsRef[section].current.scrollIntoView({
        behavior: 'smooth'
      });
    }, 150); // delay to ensure dialog is closed
  };
  return React.createElement("nav", {
    className: "Header__nav"
  }, React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    className: "no-bullet",
    direction: "row",
    space: "four",
    tag: "ul"
  }, React.createElement("li", {
    className: "visible-desktop--large"
  }, React.createElement(LanguageSelector, null)), React.createElement("li", {
    className: "visible-desktop--large"
  }, React.createElement(UitkTertiaryButton, {
    tag: "a",
    href: "/".concat(makeLocaleParam(context.locale), "/signin"),
    "data-cy": "sign-in-link"
  }, React.createElement(LocalizedText, {
    message: "common.sign_in"
  }))), React.createElement("li", null, React.createElement(UitkPrimaryButton, __assign({
    tag: "a",
    href: "/".concat(makeLocaleParam(context.locale), "/signup"),
    "data-cy": "sign-up-link"
  }, joinNowButtonViewportProps), React.createElement(LocalizedText, {
    message: "common.join_now"
  }))), React.createElement("li", {
    className: "visible-mobile--large"
  }, React.createElement(UitkTertiaryButton, {
    onClick: openDialog
  }, React.createElement(UitkIcon, {
    name: "menu",
    id: "menu"
  })))), React.createElement(UitkSheetTransition, {
    isVisible: isDialogOpen
  }, React.createElement(DialogComponent, null, React.createElement(UitkSheet, {
    liveAnnounceString: 'Header menu, open',
    type: "full",
    triggerRef: triggerRef,
    isVisible: isDialogOpen
  }, React.createElement(UitkSpacing, {
    padding: {
      small: {
        inline: "four"
      },
      medium: {
        inline: "six"
      }
    },
    border: ["blockend"]
  }, React.createElement(UitkLayoutFlex, {
    alignItems: 'center',
    justifyContent: 'space-between',
    className: "TCHeader__toolbar"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(Link, {
    to: "/".concat(makeLocaleParam(context.locale), "/travel-creator")
  }, React.createElement("img", {
    src: "/images/travel-creator-landing/travel-creator-program-logo.png",
    alt: "Expedia Group Affiliate Program",
    className: "TCHeader__logo"
  }))), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkTertiaryButton, {
    onClick: closeDialog
  }, React.createElement(UitkIcon, {
    name: "close",
    id: "close"
  }))))), React.createElement(UitkSheetContent, null, React.createElement(UitkSpacing, {
    padding: {
      blockstart: 'four',
      blockend: 'eight'
    }
  }, React.createElement(UitkLayoutFlex, {
    space: 'four'
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkSecondaryButton, {
    tag: "a",
    href: "/".concat(makeLocaleParam(context.locale), "/signin"),
    "data-cy": "sign-in-link",
    isFullWidth: true
  }, React.createElement(LocalizedText, {
    message: "common.sign_in"
  }))), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkPrimaryButton, {
    tag: "a",
    href: "/".concat(makeLocaleParam(context.locale), "/signup"),
    "data-cy": "sign-up-link",
    isFullWidth: true
  }, React.createElement(LocalizedText, {
    message: "common.join_now"
  }))))), React.createElement(UitkLayoutFlex, {
    direction: 'column',
    alignItems: 'start',
    tag: 'ul',
    className: 'TCHeader__nav TCHeader__menu'
  }, navItems.map(function (item, id) {
    return React.createElement(UitkSpacing, {
      padding: {
        block: "two"
      },
      key: id
    }, React.createElement("li", {
      className: "TCHeader__menu-item"
    }, React.createElement(UitkTertiaryButton, {
      onClick: function () {
        return scrollToSection(item.ref);
      }
    }, item.label)));
  }))), React.createElement(UitkLayoutFlex, null, React.createElement(UitkLayoutFlexItem, null, React.createElement(MobileLanguageSelector, null)))))));
};
export { HeaderActions };