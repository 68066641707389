import React from 'react';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkLink } from 'uitk-react-link';
import { UitkText } from 'uitk-react-text';
var TestimonialsCreatorDetails = function (_a) {
  var imgSrc = _a.imgSrc,
    altText = _a.altText,
    creatorName = _a.creatorName,
    creatorHandle = _a.creatorHandle,
    shopUrl = _a.shopUrl;
  return React.createElement(UitkLayoutFlex, {
    space: 'two',
    alignItems: 'center'
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement("img", {
    src: imgSrc,
    alt: altText,
    className: "TCTestimonials__avatar"
  })), React.createElement(UitkLayoutFlexItem, null, React.createElement("div", null, React.createElement(UitkLayoutFlex, {
    direction: "column"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkText, {
    size: 400,
    weight: 'medium',
    theme: 'emphasis'
  }, creatorName)), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkLink, null, React.createElement("a", {
    href: shopUrl,
    target: '_blank',
    rel: "noreferrer"
  }, creatorHandle)))))));
};
export { TestimonialsCreatorDetails };