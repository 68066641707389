import * as React from 'react';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkText } from 'uitk-react-text';
import { UitkLink } from 'uitk-react-link';
export function Footer() {
  return React.createElement("div", {
    className: "CVFooter"
  }, React.createElement("div", {
    className: "CVContainer"
  }, React.createElement(UitkLayoutFlex, {
    justifyContent: "space-between",
    space: "six",
    className: "CVFooter__content"
  }, React.createElement(UitkLayoutFlex, {
    className: "CVFooter__links",
    alignItems: "center",
    wrap: "wrap",
    space: "six"
  }, React.createElement(UitkLink, {
    inline: true,
    className: "CVFooter__button"
  }, React.createElement(UitkText, {
    size: 200
  }, React.createElement("a", {
    href: "/en-us/creator-video/terms-and-conditions",
    role: "link"
  }, "Terms & conditions"))), React.createElement(UitkLink, {
    inline: true,
    className: "CVFooter__button"
  }, React.createElement(UitkText, {
    size: 200
  }, React.createElement("a", {
    href: "/en-us/creator-video/video-guidelines",
    role: "link"
  }, "Video content guidelines"))), React.createElement(UitkLink, {
    inline: true,
    className: "CVFooter__button"
  }, React.createElement(UitkText, {
    size: 200
  }, React.createElement("a", {
    href: "mailto:creatorvideosupport@expediagroup.com"
  }, "Contact us")))), React.createElement(UitkText, {
    size: 200
  }, "\u00A9 2024 Expedia, Inc. All rights reserved. Confidential and proprietary."))));
}