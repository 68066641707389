import * as React from 'react';
import { useEffect } from 'react';
import { usePlatformContext } from '@bernie/platform-context';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkButtonSize, UitkPrimaryButton } from 'uitk-react-button';
import { UitkFigure } from 'uitk-react-images';
import { UitkIcon } from 'uitk-react-icons';
import { EventType, makeLocaleParam, useCommonClickStreamEvent } from 'shared/utils';
// Should be moved
import { facebookLeadEvent, facebookPageViewEvent, FacebookPixelScript, HeroVideo, useCreatorVideoSetup } from 'features/creator-video';
import { Faq, Footer, Header, HowItWorks, Inspiration, Tips, TravelVideoBenefits } from 'features/travel-video-landing';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkBadgeStandard } from 'uitk-react-badge';
import { LocalizedText } from '@shared-ui/localization-context';
import { useLocation } from 'react-router-dom';
import { LocalizedTextWrapping } from 'shared/components/localized-text-wrapping';
import { UitkLink } from 'uitk-react-link';
function LandingPage() {
  var context = usePlatformContext().context;
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  var location = useLocation();
  useCreatorVideoSetup();
  useEffect(function () {
    trackEvent({
      event_type: EventType.PAGE_VIEW,
      event_name: 'creator_video_landing.viewed'
    });
    facebookPageViewEvent();
  }, []);
  useEffect(function () {
    var hash = location.hash;
    if (hash) {
      var element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView();
      }
    }
  }, []);
  return React.createElement(React.Fragment, null, React.createElement(FacebookPixelScript, null), React.createElement(Header, null), React.createElement("section", {
    className: "CVSection"
  }, React.createElement("div", {
    className: "CVContainer"
  }, React.createElement("div", {
    className: "CVHero__section"
  }, React.createElement("div", {
    className: "CVHero__heading-flex"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: "six"
  }, React.createElement("div", {
    className: "CVHero__header"
  }, React.createElement(UitkBadgeStandard, {
    className: "travel-video-beta-tag"
  }, "Beta"), React.createElement(UitkHeading, {
    className: "text-uppercase"
  }, "Travel Videos"), React.createElement(UitkHeading, {
    size: 2,
    tag: "h1"
  }, React.createElement(LocalizedText, {
    message: "onboarded-dashboard.card.heading.creator_share_earn"
  }))), React.createElement(UitkText, {
    className: "CVHero__heading-paragraph"
  }, React.createElement(LocalizedTextWrapping, {
    message: "creatorvideos.landing_page.hero.content",
    fieldName: "link"
  }, React.createElement(UitkLink, {
    onClick: function () {
      trackEvent({
        event_type: EventType.INTERACTION,
        event_name: 'creator_video_join_affiliate.selected',
        action_location: 'travel-video-landing-page-hero-link'
      }, {
        additional_context: {
          user_interface: {
            component_element_text: 'hero link'
          }
        }
      });
      facebookLeadEvent([{
        content_name: 'Travel Video Hero Paragraph Link'
      }]);
    },
    inline: true
  }, React.createElement("a", {
    className: "no-text-decoration",
    href: "https://affiliates.expediagroup.com"
  }, React.createElement(LocalizedText, {
    message: "creatorvideos.landing_page.hero.content_link"
  })))))), React.createElement(UitkLayoutFlexItem, null, React.createElement("div", null, React.createElement("a", {
    target: "_blank",
    rel: "noreferrer",
    href: "/".concat(makeLocaleParam(context.locale), "/signup?initial=travel_video")
  }, React.createElement(UitkPrimaryButton, {
    className: "CVHero__heading-button",
    size: UitkButtonSize.LARGE,
    onClick: function () {
      trackEvent({
        event_type: EventType.INTERACTION,
        event_name: 'creator_video_join_affiliate.selected',
        action_location: 'travel-video-landing-page-hero-button'
      }, {
        additional_context: {
          user_interface: {
            component_element_text: 'hero button'
          }
        }
      });
      facebookLeadEvent([{
        content_name: 'Travel Video Hero Join Now'
      }]);
    }
  }, React.createElement(LocalizedText, {
    message: "creatorvideos.common.cta"
  }), " ", React.createElement(UitkIcon, {
    name: "open_in_new"
  })))))), React.createElement(UitkLayoutFlexItem, {
    alignSelf: "center"
  }, React.createElement("div", {
    className: "CVHero__relative-image"
  }, React.createElement(UitkFigure, {
    className: "CVHero__media-image"
  }, React.createElement(HeroVideo, {
    url: "https://mediaim.expedia.com/creator-video/creator-hero.mp4",
    poster: "/images/travel-video-landing/poster-hero.png"
  })), React.createElement("img", {
    className: "CVHero__svg CVHero__star-svg",
    src: "/images/creator-video/landing-page-star.svg"
  }), React.createElement("img", {
    className: "CVHero__svg CVHero__record-svg",
    src: "/images/creator-video/landing-page-record.svg"
  }), React.createElement("img", {
    className: "CVHero__svg CVHero__flash-scribble-svg",
    src: "/images/creator-video/flash-scribble.svg"
  }), React.createElement("img", {
    className: "CVHero__svg CVHero__dark-blue-svg",
    src: "/images/creator-video/dark-blue-scribble.svg"
  }), React.createElement("img", {
    className: "CVHero__svg CVHero__button-svg",
    src: "/images/creator-video/creator-video-button.svg"
  })))))), React.createElement("section", {
    className: "CVSection",
    id: "benefits"
  }, React.createElement("div", {
    className: "CVContainer"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    className: "CVSection__wrapper"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    tag: "header",
    space: "four"
  }, React.createElement(UitkText, {
    className: "cc-text-neutral-1-600",
    size: 400,
    weight: "medium",
    align: "center"
  }, React.createElement(LocalizedText, {
    message: "creatorvideos.landing_page.benefits.subheading"
  })), React.createElement(UitkHeading, {
    size: 3,
    tag: "h2",
    align: "center"
  }, React.createElement(LocalizedText, {
    message: "creatorvideos.landing_page.benefits.heading"
  }))), React.createElement(TravelVideoBenefits, null), React.createElement(UitkLayoutFlex, {
    justifyContent: "center"
  }, React.createElement("a", {
    target: "_blank",
    rel: "noreferrer",
    href: "/".concat(makeLocaleParam(context.locale), "/signup?initial=travel_video")
  }, React.createElement(UitkPrimaryButton, {
    onClickCapture: function () {
      trackEvent({
        event_type: EventType.INTERACTION,
        event_name: 'creator_video_join_affiliate.selected',
        action_location: 'travel-video-landing-page-benefits-button'
      }, {
        additional_context: {
          user_interface: {
            component_element_text: 'benefits button'
          }
        }
      });
      facebookLeadEvent([{
        content_name: 'Travel Video Benefits Join Now'
      }]);
    },
    size: UitkButtonSize.LARGE
  }, React.createElement(LocalizedText, {
    message: "creatorvideos.common.cta"
  }), " ", React.createElement(UitkIcon, {
    name: "open_in_new"
  }))))))), React.createElement("section", {
    className: "CVSection"
  }, React.createElement(Inspiration, null), React.createElement(UitkSpacing, {
    padding: {
      blockstart: 'twelve'
    }
  }, React.createElement(UitkLayoutFlex, {
    justifyContent: "center"
  }, React.createElement("a", {
    target: "_blank",
    rel: "noreferrer",
    href: "https://console.vap.expedia.com/eg-affiliates/products/travel-videos"
  }, React.createElement(UitkPrimaryButton, {
    onClickCapture: function () {
      trackEvent({
        event_type: EventType.INTERACTION,
        event_name: 'creator_video_sign_in_affiliate.selected',
        action_location: 'travel-video-landing-page-inspiration-button'
      }, {
        additional_context: {
          user_interface: {
            component_element_text: 'inspiration button'
          }
        }
      });
      facebookLeadEvent([{
        content_name: 'Travel Video Inspiration Submit Videos'
      }]);
    },
    size: UitkButtonSize.LARGE
  }, React.createElement(LocalizedText, {
    message: "creatorvideos.common.submit_videos"
  }), " ", React.createElement(UitkIcon, {
    name: "open_in_new"
  })))))), React.createElement("section", {
    className: "CVSection",
    id: "how-it-works"
  }, React.createElement("div", {
    className: "CVContainer"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    className: "CVSection__wrapper"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    tag: "header",
    space: "four"
  }, React.createElement(UitkText, {
    className: "cc-text-neutral-1-600",
    size: 400,
    weight: "medium",
    align: "center"
  }, React.createElement(LocalizedText, {
    message: "creatorvideos.landing_page.how_it_works.heading"
  })), React.createElement(UitkHeading, {
    size: 3,
    tag: "h2",
    align: "center"
  }, React.createElement(LocalizedText, {
    message: "creatorvideos.landing_page.how_it_works.subheading"
  }))), React.createElement(HowItWorks, null), React.createElement(UitkLayoutFlex, {
    justifyContent: "center"
  }, React.createElement("a", {
    target: "_blank",
    rel: "noreferrer",
    href: "".concat(makeLocaleParam(context.locale), "/signup?initial=travel_video")
  }, React.createElement(UitkPrimaryButton, {
    onClickCapture: function () {
      trackEvent({
        event_type: EventType.INTERACTION,
        event_name: 'creator_video_join_affiliate.selected',
        action_location: 'travel-video-landing-page-how-it-works-button'
      }, {
        additional_context: {
          user_interface: {
            component_element_text: 'how does it work button'
          }
        }
      });
      facebookLeadEvent([{
        content_name: 'Travel Video How It Works Join Now'
      }]);
    },
    size: UitkButtonSize.LARGE
  }, React.createElement(LocalizedText, {
    message: "creatorvideos.common.cta"
  }), " ", React.createElement(UitkIcon, {
    name: "open_in_new"
  }))))))), React.createElement("section", {
    className: "CVSection",
    id: "tips"
  }, React.createElement("div", {
    className: "CVContainer"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    className: "CVSection__wrapper"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    tag: "header",
    space: "four"
  }, React.createElement(UitkText, {
    className: "cc-text-neutral-1-600",
    size: 400,
    weight: "medium",
    align: "center"
  }, React.createElement(LocalizedText, {
    message: "creatorvideos.landing_page.tips.subheading"
  })), React.createElement(UitkHeading, {
    size: 3,
    tag: "h2",
    align: "center"
  }, React.createElement(LocalizedText, {
    message: "creatorvideos.landing_page.tips.heading"
  }))), React.createElement(Tips, null), React.createElement(UitkLayoutFlex, {
    justifyContent: "center"
  }, React.createElement("a", {
    target: "_blank",
    rel: "noreferrer",
    href: "https://console.vap.expedia.com/eg-affiliates/products/travel-videos"
  }, React.createElement(UitkPrimaryButton, {
    onClickCapture: function () {
      trackEvent({
        event_type: EventType.INTERACTION,
        event_name: 'creator_video_sign_in_affiliate.selected',
        action_location: 'travel-video-landing-page-tips-button'
      }, {
        additional_context: {
          user_interface: {
            component_element_text: 'tips button'
          }
        }
      });
      facebookLeadEvent([{
        content_name: 'Travel Video Tips Submit Videos'
      }]);
    },
    size: UitkButtonSize.LARGE
  }, React.createElement(LocalizedText, {
    message: "creatorvideos.common.submit_videos"
  }), " ", React.createElement(UitkIcon, {
    name: "open_in_new"
  }))))))), React.createElement("section", {
    className: "CVSection",
    id: "faq"
  }, React.createElement(Faq, null)), React.createElement(Footer, null));
}
export default LandingPage;
export { LandingPage };