var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import * as React from 'react';
import { Link } from 'react-router-dom';
import { UitkButtonSize, UitkPrimaryButton, UitkTertiaryButton } from 'uitk-react-button';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkSheetContent } from 'uitk-react-sheet';
import { Footer, FacebookPixelScript } from 'features/creator-video';
import { useCommonClickStreamEvent, EventType } from 'shared/utils';
import { LinkWithQuery } from 'shared/components';
import { useLocation, useHistory } from 'react-router-dom';
var navItems = [{
  label: 'Benefits',
  anchor: '#benefits'
}, {
  label: 'Inspiration',
  anchor: '#inspiration'
}, {
  label: 'How it works',
  anchor: '#how-it-works'
}, {
  label: 'Tips',
  anchor: '#tips'
}, {
  label: 'FAQ',
  anchor: '#faq'
}];
export function Layout(_a) {
  var children = _a.children;
  var location = useLocation();
  var history = useHistory();
  var _b = __read(React.useState(false), 2),
    mobileMenuVisible = _b[0],
    setMobileMenuVisible = _b[1];
  var triggerMobileMenu = function () {
    setMobileMenuVisible(!mobileMenuVisible);
  };
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  var isSubmitPage = location.pathname.includes('submit');
  var handleAnchorClick = function (_a) {
    var event = _a.event,
      anchor = _a.anchor;
    event.preventDefault();
    var currentUrl = window.location.href;
    currentUrl = currentUrl.split('#')[0];
    var anchorText = anchor.replace('#', '');
    trackEvent({
      event_type: EventType.INTERACTION,
      event_name: 'creator_video_redirect_to_section.selected',
      action_location: "header-nav-".concat(anchorText)
    }, {
      additional_context: {
        user_interface: {
          component_name: 'header-nav',
          component_element_text: anchorText
        }
      }
    });
    triggerMobileMenu();
    if (currentUrl.endsWith('creator-video/') || currentUrl.endsWith('creator-video')) {
      history.push("".concat(anchor));
    } else {
      history.push("/en-us/creator-video/".concat(anchor));
    }
  };
  // scroll page to top when navigating
  React.useEffect(function () {
    if (location.pathname) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }, [location.pathname]);
  return React.createElement("div", {
    className: "CVLayout"
  }, React.createElement(FacebookPixelScript, null), React.createElement("header", {
    className: "CVHeader"
  }, React.createElement("div", {
    className: "CVHeader__container"
  }, React.createElement(Link, {
    to: "/en-us/creator-video",
    className: "CVHeader__logo"
  }, React.createElement("img", {
    src: "/images/shared/eg-affiliate-program-logo-blue.svg",
    alt: "Expedia Group Affiliate Program"
  })), React.createElement("nav", {
    className: "CVHeader__nav"
  }, navItems.map(function (link, ind) {
    return React.createElement("a", {
      onClick: function (event) {
        return handleAnchorClick({
          event: event,
          anchor: link.anchor
        });
      },
      key: "creator-video-nav-".concat(ind)
    }, link.label);
  })), React.createElement(UitkLayoutFlex, {
    direction: "row",
    className: "CVHeader__Utils"
  }, !isSubmitPage && React.createElement(LinkWithQuery, {
    to: "/en-us/creator-video/submit"
  }, React.createElement(UitkPrimaryButton, {
    className: "CVHeader__submit",
    onClick: function () {
      return trackEvent({
        event_type: EventType.INTERACTION,
        event_name: 'creator_video_go_to_submission.selected',
        action_location: 'header-nav'
      }, {
        additional_context: {
          user_interface: {
            component_element_text: 'desktop header button'
          }
        }
      });
    },
    size: UitkButtonSize.LARGE
  }, "Submit videos")), React.createElement(UitkTertiaryButton, {
    className: "CVHeader__hamburger",
    onClick: triggerMobileMenu
  }, mobileMenuVisible ? React.createElement(UitkIcon, {
    name: "close",
    size: UitkIconSize.MEDIUM,
    className: "CVHeader__mobile-menu"
  }) : React.createElement(UitkIcon, {
    name: "menu",
    size: UitkIconSize.MEDIUM,
    className: "CVHeader__mobile-menu"
  }))))), mobileMenuVisible && React.createElement("div", {
    className: "CVPopover__mobile-menu"
  }, React.createElement(UitkSheetContent, {
    padded: true,
    className: "CVHeader_mobile-sheet-padding"
  }, React.createElement(UitkLayoutFlex, {
    className: "CVPopover__flex",
    justifyContent: "space-between",
    direction: "column"
  }, React.createElement("nav", {
    className: "CVHeader__mobile-nav"
  }, React.createElement("a", {
    href: "/en-us/creator-video"
  }, "Home"), navItems.map(function (link, ind) {
    return React.createElement("a", {
      href: link.anchor,
      onClick: function (event) {
        return handleAnchorClick({
          event: event,
          anchor: link.anchor
        });
      },
      key: "creator-video-nav-".concat(ind)
    }, link.label);
  }))))), children, React.createElement(Footer, null));
}