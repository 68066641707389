/* eslint-disable prefer-spread, prefer-rest-params */
import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
var FacebookPixelScript = function () {
  if (typeof window === 'undefined') {
    return;
  }
  React.useEffect(function () {
    !function (f, b, e, v, n, t, s) {
      // @ts-ignore facebook pixel code
      if (f.fbq) return false;
      // @ts-ignore facebook pixel code
      n = f.fbq = function () {
        // @ts-ignore facebook pixel code
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      // @ts-ignore facebook pixel code
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      if (s) {
        s.parentNode.insertBefore(t, s);
      } else {
        b.body.appendChild(t);
      }
    }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    // @ts-ignore facebook pixel code
    window.fbq('init', '383560014651304');
    // @ts-ignore facebook pixel code
    window.fbq('track', 'PageView');
  }, []);
  return React.createElement(React.Fragment, null, React.createElement("noscript", null, React.createElement("img", {
    alt: "Facebook Pixel",
    height: "1",
    width: "1",
    style: {
      display: 'none'
    },
    src: "https://www.facebook.com/tr?id=383560014651304&ev=PageView&noscript=1"
  })));
};
export { FacebookPixelScript };