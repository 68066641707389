// 3rd party imports
import * as React from 'react';
// components
import { HeaderLite } from './header-lite';
export var LayoutLite = function (_a) {
  var children = _a.children,
    centered = _a.centered;
  return React.createElement("div", {
    className: "Layout"
  }, React.createElement(HeaderLite, null), React.createElement("section", {
    className: "Layout__body ".concat(centered ? 'Layout__body--centered' : '')
  }, children));
};