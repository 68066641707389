// 3rd party imports
import * as React from 'react';
import { usePlatformContext } from '@bernie/platform-context';
// shared-ui
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { LocalizedText } from '@shared-ui/localization-context';
// ui
import { UitkPrimaryButton, UitkButtonSize } from 'uitk-react-button';
import { EventType, homeJoinNowSelectedEvent, makeLocaleParam, useCommonClickStreamEvent } from 'shared/utils';
import { facebookLeadEvent } from 'features/creator-video';
import { useLocation } from "react-router-dom";
var JoinNowAction = function (_a) {
  var size = _a.size,
    actionLocation = _a.actionLocation,
    signupUrlSearchParam = _a.signupUrlSearchParam;
  var track = useEgClickstream();
  var context = usePlatformContext().context;
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  var pathname = useLocation().pathname;
  return React.createElement(React.Fragment, null, React.createElement(UitkPrimaryButton, {
    tag: "a",
    href: "/".concat(makeLocaleParam(context.locale), "/signup").concat(signupUrlSearchParam ? "?".concat(signupUrlSearchParam) : ''),
    "data-cy": "sign-up-link",
    size: size ? size : UitkButtonSize.MEDIUM,
    onClickCapture: function () {
      if (signupUrlSearchParam === null || signupUrlSearchParam === void 0 ? void 0 : signupUrlSearchParam.includes('travel_video')) {
        trackEvent({
          event_type: EventType.INTERACTION,
          event_name: 'creator_video_join_affiliate.selected',
          action_location: 'travel-video-landing-page-header-join-now'
        }, {
          additional_context: {
            user_interface: {
              component_element_text: 'Join Now Header'
            }
          }
        });
        if (pathname.endsWith('travel-video') || pathname.endsWith('travel-video/')) {
          facebookLeadEvent([{
            content_name: 'Travel Video Header Sign In'
          }]);
        }
      } else {
        homeJoinNowSelectedEvent(track, actionLocation);
      }
    }
  }, React.createElement(LocalizedText, {
    message: "common.join_now"
  })));
};
export { JoinNowAction };