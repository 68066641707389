import React from 'react';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkHeading, UitkText } from 'uitk-react-text';
var contentData = [{
  imageSrc: '/images/travel-creator-landing/how-it-works-curate.svg',
  heading: 'Curate',
  description: 'Craft videos and curate collections. Showcase your picks in Travel Shop, and offer your followers a wide range of choices.'
}, {
  imageSrc: '/images/travel-creator-landing/how-it-works-share.svg',
  heading: 'Share',
  description: 'Use Creator Toolbox to share your curated content on social media, effortlessly and on the go.'
}, {
  imageSrc: '/images/travel-creator-landing/how-it-works-earn.svg',
  heading: 'Earn',
  description: 'Earn commissions by promoting your picks. Boost your earnings with video uploads, on-site placements, and creator ads.'
}];
var HowItWorksSection = function (_a) {
  var refProp = _a.refProp;
  return React.createElement("section", {
    className: "TCHowItWorks",
    id: "howItWorks",
    ref: refProp
  }, React.createElement("div", {
    className: "container"
  }, React.createElement(UitkLayoutFlex, {
    className: "TCHowItWorks__section__heading__container"
  }, React.createElement(UitkLayoutFlexItem, {
    grow: 1
  }, React.createElement(UitkHeading, {
    tag: "h2",
    className: "TCHowItWorks__section__heading"
  }, "How it works"))), React.createElement(UitkLayoutFlex, {
    tag: "ul",
    className: "TCHowItWorks__container"
  }, contentData.map(function (item, id) {
    return React.createElement("li", {
      key: id,
      className: "TCHowItWorks__item"
    }, React.createElement(UitkLayoutFlex, {
      direction: "column",
      alignItems: 'center',
      justifyContent: 'center'
    }, React.createElement(UitkLayoutFlexItem, null, React.createElement("img", {
      src: item.imageSrc
    })), React.createElement(UitkLayoutFlexItem, {
      className: "TCHowItWorks__item__heading__container"
    }, React.createElement(UitkHeading, {
      tag: "h3",
      className: "TCHowItWorks__item__heading"
    }, item.heading)), React.createElement(UitkLayoutFlexItem, {
      className: "TCHowItWorks__item__description__container"
    }, React.createElement(UitkText, {
      align: 'center',
      className: "TCHowItWorks__item__description"
    }, item.description))));
  }))));
};
export { HowItWorksSection };