var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import * as React from 'react';
import { useRef, useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useCommonClickStreamEvent, EventType } from 'shared/utils';
import { UitkButtonSize, UitkPrimaryButton, UitkSecondaryButton, UitkTertiaryButton } from 'uitk-react-button';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkInput } from 'uitk-react-input';
import { UitkLink } from 'uitk-react-link';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkBadgeStandard } from 'uitk-react-badge';
import { UitkList, UitkListItem } from 'uitk-react-list';
import { UitkBanner } from 'uitk-react-banner';
import { UitkCheckbox } from 'uitk-react-checkbox';
import { getVideoThumbnail, validateVideo, SubmitFormUploadDialog, SubmitFormSubmission, SubmitFormLinkDialog, Dialog, facebookLeadEvent, facebookSubmitApplicationEvent, facebookAdvancedMatching } from 'features/creator-video';
import { useHistory } from 'react-router-dom';
import { UitkLoader } from 'uitk-react-loader';
import { useStores, useLogger } from 'shared/hooks';
import { useDialog } from '@shared-ui/dialog-context';
import { useDevice } from 'shared/utils';
import { useLocation } from 'react-router-dom';
export var Source;
(function (Source) {
  Source["TRIP_INBOX"] = "TRIP_INBOX";
  Source["TRIP_ITEM_DETAILS_PAGE"] = "TRIP_ITEM_DETAILS_PAGE";
})(Source || (Source = {}));
export function SubmitForm(_a) {
  var innerRef = _a.innerRef;
  var isMobile = useDevice().isMobile;
  var uiStore = useStores().uiStore;
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  var logger = useLogger();
  var bffUrl = uiStore.bffUrl;
  var history = useHistory();
  var location = useLocation();
  var searchParams = new URLSearchParams(location.search);
  var adSetId = searchParams.get('adSetId');
  var _b = __read(useState(false), 2),
    isLoading = _b[0],
    setIsLoading = _b[1];
  var fileRef = useRef(null);
  var _c = __read(useState([]), 2),
    errorMessages = _c[0],
    setErrorMessages = _c[1];
  var _d = __read(useState(false), 2),
    isUploadDialogOpen = _d[0],
    setIsUploadDialogOpen = _d[1];
  var _e = __read(useState(false), 2),
    isLinkDialogOpen = _e[0],
    setIsLinkDialogOpen = _e[1];
  var _f = __read(useState(false), 2),
    isUploading = _f[0],
    setIsUploading = _f[1];
  var _g = __read(useState(null), 2),
    previewThumbnail = _g[0],
    setPreviewThumbnail = _g[1];
  var _h = __read(useState(null), 2),
    previewTitle = _h[0],
    setPreviewTitle = _h[1];
  var _j = __read(useState(null), 2),
    uploadedFile = _j[0],
    setUploadedFile = _j[1];
  var _k = __read(useState(false), 2),
    showUploadError = _k[0],
    setShowUploadError = _k[1];
  // this is combined regex for facebook, instagram and tiktok using references from around the web and combining them into one regex
  var profileRegex = /(?:(?:www\.)?(?:facebook|fb)\.com\/(?:(?![A-Za-z0-9_]+\.php)(?!marketplace|gaming|watch|me|messages|help|search|groups)(\w+)+\/?|profile\.php\?id=[0-9]+)|(?:www\.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)(?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?|(?:www\.)?tiktok\.com\/@[^/]+\/?(?![^\s]))/i;
  var desktopVideoContentGuidelinesTrigger = React.useRef(null);
  var fullSheetDialogTrigger = React.useRef(null);
  var _l = __read(React.useState(false), 2),
    isVCG = _l[0],
    setIsVCG = _l[1];
  var _m = __read(useDialog('VCG-necessary-dialog-layer-submit-form'), 3),
    isVCGDialogOpen = _m[0],
    dialogVCGActions = _m[1],
    DialogVCGComponent = _m[2];
  var _o = __read(useDialog('CV-footer-full-necessary-dialog-layer-submit-form'), 3),
    isFullDialogOpen = _o[0],
    dialogFullActions = _o[1],
    DialogFullComponent = _o[2];
  var openCenteredSheet = function (location) {
    dialogVCGActions.openDialog();
    trackEvent({
      event_type: EventType.IMPRESSION,
      event_name: 'creator_video_content_guidelines.presented',
      action_location: location
    });
  };
  var openFullSheet = function (isVCGBoolean, location) {
    setIsVCG(isVCGBoolean);
    dialogFullActions.openDialog();
    trackEvent({
      event_type: EventType.IMPRESSION,
      event_name: isVCGBoolean ? 'creator_video_content_guidelines.presented' : 'creator_video_terms_and_conditions.presented',
      action_location: location
    });
  };
  var _p = useForm({
      mode: 'onBlur',
      defaultValues: {
        firstName: '',
        lastName: '',
        email: '',
        socialProfile: '',
        termsConditions: true,
        contentGuidelines: true,
        marketingConsent: false,
        submissions: []
      }
    }),
    handleSubmit = _p.handleSubmit,
    control = _p.control,
    setValue = _p.setValue,
    trigger = _p.trigger,
    _q = _p.formState,
    isValid = _q.isValid,
    formErrors = _q.errors;
  var _r = useFieldArray({
      control: control,
      name: 'submissions'
    }),
    submissions = _r.fields,
    append = _r.append,
    remove = _r.remove;
  var handleSuccessLogging = function (actionLocation, fieldName) {
    trackEvent({
      event_type: EventType.OUTCOME,
      event_name: 'creator_video_submission.succeeded',
      action_location: actionLocation
    }, {
      form: {
        form_name: 'creator_video_submission',
        short_field_name: fieldName
      }
    });
  };
  var handleErrorLogging = function (errors, field, actionLocation) {
    trackEvent({
      event_type: EventType.IMPRESSION,
      event_name: 'creator_video_submission.failed',
      action_location: actionLocation
    }, {
      error_list: errors.map(function (message) {
        return {
          error_description: message
        };
      }),
      form: {
        form_name: 'creator_video_submission',
        short_field_name: field
      }
    });
  };
  function handleUploadClick() {
    handleSuccessLogging('submission-form-upload-button', 'video upload process started');
    if (fileRef.current) {
      fileRef.current.click();
    }
  }
  function handleAttach(submission) {
    if (uploadedFile) {
      append(__assign(__assign({}, submission), {
        uploadedFile: uploadedFile
      }));
    } else {
      append(submission);
    }
    handleSuccessLogging('submission-form-attach', 'attach video to form');
    fileRef.current.value = '';
    setIsUploadDialogOpen(false);
    setIsLinkDialogOpen(false);
    setUploadedFile(null);
  }
  function getSourceValue(value) {
    if (Object.values(Source).includes(value)) {
      return value.toUpperCase() + '_' + sessionStorage.getItem('tripStatus').toUpperCase();
    }
    return 'LANDING_PAGE';
  }
  function handleUploadChange(e) {
    return __awaiter(this, void 0, void 0, function () {
      var input, file, videoUrl, videoElement, errors, thumbnailSrc, error_1;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            input = e.target;
            file = input.files ? input.files[0] : null;
            setShowUploadError(false);
            if (!file) {
              console.log('No file selected');
              return [2 /*return*/];
            }
            console.log('File detected:', file.name, file.type);
            setUploadedFile(file);
            setPreviewTitle(file.name);
            videoUrl = URL.createObjectURL(file);
            videoElement = document.createElement('video');
            videoElement.autoplay = true;
            videoElement.muted = true;
            videoElement.playsInline = true;
            videoElement.src = videoUrl;
            _a.label = 1;
          case 1:
            _a.trys.push([1, 4, 5, 6]);
            return [4 /*yield*/, validateVideo(file, videoElement)];
          case 2:
            errors = _a.sent();
            if (errors.length > 0) {
              console.error('Validation errors:', errors);
              setErrorMessages(errors);
              URL.revokeObjectURL(videoUrl);
              handleErrorLogging(errors, 'upload video', 'submission-form-video-upload-error');
              return [2 /*return*/];
            }
            return [4 /*yield*/, getVideoThumbnail(videoElement)];
          case 3:
            thumbnailSrc = _a.sent();
            setPreviewThumbnail(thumbnailSrc);
            setIsUploadDialogOpen(true);
            console.log('Upload successful:', file.name);
            handleSuccessLogging('submission-form-video-upload', 'successful video upload');
            return [3 /*break*/, 6];
          case 4:
            error_1 = _a.sent();
            console.error('An unexpected error occurred during file processing:', error_1);
            return [3 /*break*/, 6];
          case 5:
            URL.revokeObjectURL(videoUrl);
            return [7 /*endfinally*/];
          case 6:
            return [2 /*return*/];
        }
      });
    });
  }
  function handleUploadError(index) {
    setShowUploadError(true);
    setIsUploading(false);
    handleErrorLogging(['There was an error uploading your video'], 'upload video', 'submission-form-video-upload-error');
    remove(index);
  }
  function onSubmit(data) {
    return __awaiter(this, void 0, void 0, function () {
      var payload, response;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            setIsLoading(true);
            logger.info('Submitting creator video form');
            payload = {
              source: getSourceValue(sessionStorage.getItem('source')),
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              socialProfile: data.socialProfile,
              marketingConsent: data.marketingConsent,
              submissions: data.submissions.map(function (submission) {
                return {
                  socialPlatform: submission.socialPlatform,
                  externalVideoUrl: submission.externalVideoUrl,
                  propertyName: submission.propertyName,
                  propertyUrl: submission.propertyUrl,
                  propertyId: submission.propertyId,
                  destinationId: submission.destinationId,
                  aspectRatio: '9/16',
                  videoRef: submission.videoRef,
                  adSetId: adSetId
                };
              })
            };
            return [4 /*yield*/, fetch("".concat(bffUrl, "/api/creator-videos"), {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(payload)
            })];
          case 1:
            response = _a.sent();
            if (!response.ok) {
              logger.error('Failed to submit creator video form');
              setIsLoading(false);
              return [2 /*return*/];
            }
            logger.info('Creator video form submitted successfully');
            facebookAdvancedMatching([{
              em: payload.email,
              fn: payload.firstName,
              ln: payload.lastName
            }]);
            handleSuccessLogging('submit-form-button', 'submit form redirect');
            facebookLeadEvent([{
              content_name: 'Creator Video Submission'
            }]);
            payload.submissions.forEach(function () {
              facebookSubmitApplicationEvent([{}, {
                eventID: "EG-".concat(payload.submissions.length, "-").concat(Math.floor(Math.random() * 30000))
              }]);
            });
            history.push('/en-us/creator-video/confirmation');
            return [2 /*return*/];
        }
      });
    });
  }
  useEffect(function () {
    if (isUploadDialogOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return function () {
      document.body.classList.remove('no-scroll');
    };
  }, [isUploadDialogOpen]);
  var handleCloseUploadDialog = function () {
    setPreviewThumbnail(null);
    setIsUploadDialogOpen(false);
    setUploadedFile(null);
    fileRef.current.value = '';
  };
  var handleCloseLinkDialog = function () {
    setIsLinkDialogOpen(false);
    fileRef.current.value = '';
  };
  useEffect(function () {
    if (isLinkDialogOpen || isUploadDialogOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return function () {
      document.body.classList.remove('no-scroll');
    };
  }, [isLinkDialogOpen, isUploadDialogOpen]);
  return React.createElement("div", {
    className: "CVSubmitForm",
    ref: innerRef
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: "twelve"
  }, React.createElement("form", {
    onSubmit: handleSubmit(onSubmit)
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: "twelve"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: "four"
  }, React.createElement(UitkLayoutFlex, {
    className: "CVInputs__name",
    space: "four"
  }, React.createElement("div", null, React.createElement(Controller, {
    control: control,
    name: "firstName",
    rules: {
      required: 'Please enter your first name.',
      minLength: {
        value: 3,
        message: 'First name must be at least 3 characters long.'
      },
      maxLength: {
        value: 50,
        message: 'First name must be at most 50 characters long.'
      },
      pattern: {
        value: /^[\p{L}\p{M}\p{Zs}.-]+$/u,
        message: 'First name is invalid.'
      }
    },
    render: function (_a) {
      var _b;
      var field = _a.field;
      return React.createElement(UitkInput, __assign({
        label: "First name",
        autoComplete: "off",
        invalid: (formErrors === null || formErrors === void 0 ? void 0 : formErrors.firstName) && ((_b = formErrors === null || formErrors === void 0 ? void 0 : formErrors.firstName) === null || _b === void 0 ? void 0 : _b.message)
      }, field));
    }
  })), React.createElement("div", null, React.createElement(Controller, {
    control: control,
    name: "lastName",
    rules: {
      required: 'Please enter your last name.',
      minLength: {
        value: 2,
        message: 'Last name must be at least 2 characters long.'
      },
      maxLength: {
        value: 50,
        message: 'Last name must be at most 50 characters long.'
      },
      pattern: {
        value: /^[\p{L}\p{M}\p{Zs}.-]+$/u,
        message: 'Last name is invalid.'
      }
    },
    render: function (_a) {
      var _b;
      var field = _a.field;
      return React.createElement(UitkInput, __assign({
        label: "Last name",
        autoComplete: "off",
        invalid: (formErrors === null || formErrors === void 0 ? void 0 : formErrors.lastName) && ((_b = formErrors === null || formErrors === void 0 ? void 0 : formErrors.lastName) === null || _b === void 0 ? void 0 : _b.message)
      }, field));
    }
  }))), React.createElement("div", null, React.createElement(Controller, {
    control: control,
    name: "email",
    rules: {
      required: 'Please enter your email address.',
      pattern: {
        value: /\S+@\S+\.\S+/,
        message: 'Please enter a valid email address.'
      }
    },
    render: function (_a) {
      var _b;
      var field = _a.field;
      return React.createElement(UitkInput, __assign({
        label: "Email",
        type: "email",
        required: true,
        invalid: (formErrors === null || formErrors === void 0 ? void 0 : formErrors.email) && ((_b = formErrors === null || formErrors === void 0 ? void 0 : formErrors.email) === null || _b === void 0 ? void 0 : _b.message),
        autoComplete: "off"
      }, field));
    }
  })), React.createElement("input", {
    type: "file",
    accept: ".mp4,.mov",
    "aria-hidden": true,
    ref: fileRef,
    hidden: true,
    onChange: handleUploadChange
  }), React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: "two"
  }, React.createElement(Controller, {
    control: control,
    name: "socialProfile",
    rules: {
      minLength: 5,
      maxLength: 100,
      required: 'Please enter your social profile.',
      pattern: {
        value: profileRegex,
        message: 'We only accept links from Instagram, Facebook and TikTok'
      }
    },
    render: function (_a) {
      var _b;
      var field = _a.field;
      return React.createElement(UitkInput, __assign({
        label: "Link to your social profile",
        invalid: (formErrors === null || formErrors === void 0 ? void 0 : formErrors.socialProfile) && ((_b = formErrors === null || formErrors === void 0 ? void 0 : formErrors.socialProfile) === null || _b === void 0 ? void 0 : _b.message),
        autoComplete: "off"
      }, field));
    }
  }), React.createElement(UitkText, null, "We support links from Instagram, Facebook and TikTok. Also ensure your account is publicly accessible."))), React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: "four"
  }, React.createElement(UitkHeading, {
    size: 6
  }, "Videos"), showUploadError && React.createElement(UitkBanner, {
    theme: "error"
  }, React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    space: "three"
  }, React.createElement(UitkIcon, {
    name: "error",
    size: UitkIconSize.MEDIUM
  }), React.createElement(UitkLayoutFlex, {
    direction: "column"
  }, React.createElement(UitkHeading, {
    size: 7
  }, "There was an error uploading your video.")))), errorMessages.length > 0 && React.createElement(UitkBanner, {
    theme: "error"
  }, React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    space: "three"
  }, React.createElement(UitkIcon, {
    name: "error",
    size: UitkIconSize.MEDIUM
  }), React.createElement(UitkLayoutFlex, {
    direction: "column"
  }, React.createElement(UitkHeading, {
    size: 7
  }, "There was an error uploading your video."), React.createElement(UitkList, {
    size: 3,
    className: "CVErrors"
  }, errorMessages.map(function (message, index) {
    return React.createElement(UitkListItem, {
      key: index
    }, message);
  }))))), React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    justifyContent: "space-between",
    space: "four"
  }, React.createElement(UitkSecondaryButton, {
    size: UitkButtonSize.LARGE,
    isFullWidth: true,
    onClick: handleUploadClick,
    disabled: submissions.length >= 15
  }, "Upload video"), React.createElement(UitkSecondaryButton, {
    size: UitkButtonSize.LARGE,
    isFullWidth: true,
    onClick: function () {
      handleSuccessLogging('submission-form-link-button', 'video link process started');
      setIsLinkDialogOpen(true);
    },
    disabled: submissions.length >= 15
  }, "Link a video")), React.createElement("div", {
    className: "CVDropzone"
  }, React.createElement(UitkBadgeStandard, {
    size: "small",
    className: "CVDropzone__counter",
    theme: "emphasis"
  }, submissions.length, "/15"), submissions.length > 0 ? React.createElement("div", {
    className: "CVSubmissions"
  }, React.createElement("div", {
    className: "CVSubmissions__entry CVSubmissions__entry--placeholder",
    onClick: handleUploadClick
  }, React.createElement("div", null, React.createElement(UitkIcon, {
    name: "upload",
    size: UitkIconSize.LARGE
  }), React.createElement(UitkText, {
    size: 100
  }, "Click to upload"))), submissions.map(function (submission, index) {
    return React.createElement("div", {
      className: "CVSubmissions__entry",
      key: submission.propertyUrl
    }, React.createElement(SubmitFormSubmission, {
      key: index,
      submission: submission,
      onDeleteSubmission: function () {
        return remove(index);
      },
      onUploadStart: function () {
        return setIsUploading(true);
      },
      onUploadComplete: function (url) {
        setValue("submissions.".concat(index, ".videoRef"), url);
        setIsUploading(false);
      },
      onUploadError: function () {
        return handleUploadError(index);
      }
    }));
  })) : React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    justifyContent: "center",
    direction: "column",
    space: "four",
    className: "CVDropzone__guide"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: "two",
    alignItems: "center",
    justifyContent: "center",
    onClick: handleUploadClick
  }, React.createElement(UitkIcon, {
    name: "upload",
    size: UitkIconSize.XLARGE
  }), React.createElement(UitkText, {
    size: 200,
    weight: "medium"
  }, "Tap to upload")), React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: "two",
    alignItems: "center",
    justifyContent: "center"
  }, React.createElement(UitkList, {
    size: 3,
    className: "CVDropzone__rules"
  }, React.createElement(UitkListItem, null, "Videos (.mp4 and .mov)"), React.createElement(UitkListItem, null, "Videos should be 9:16 with minimum resolution of 1080*1920 pixels"), React.createElement(UitkListItem, null, "Videos must be between 6 - 60 seconds"), React.createElement(UitkListItem, null, "Max 500 MB for each video"), React.createElement(UitkListItem, null, "You can share up to 15 videos"))))), React.createElement(UitkLink, null, React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    space: "two"
  }, React.createElement(UitkTertiaryButton, {
    alignLabelToContent: "start",
    className: "CVVcg__button",
    onClick: function () {
      return isMobile ? openFullSheet(true, 'video-content-guidelines-hyperlink') : openCenteredSheet('video-content-guidelines-hyperlink');
    }
  }, React.createElement(UitkIcon, {
    name: "help",
    size: UitkIconSize.SMALL
  }), React.createElement(UitkText, {
    className: "CVTertiary__text"
  }, "Video content guidelines")))), React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: "four"
  }, React.createElement(UitkText, {
    size: 400
  }, "I acknowledge that I have complied with the video guidelines and that I own all rights to the content or have a license to grant to Expedia Group, covering on and off platform use. Additionally, I acknowledge that upon acceptance of my content, the terms and conditions shall apply. See our", ' ', React.createElement(UitkTertiaryButton, {
    className: "CVVcg__button",
    onClick: function () {
      return openFullSheet(false, 'terms-and-conditions-hyperlink');
    }
  }, React.createElement(UitkText, {
    className: "CVTertiary__text",
    size: 400
  }, "terms and conditions."))), React.createElement(Controller, {
    control: control,
    name: "marketingConsent",
    render: function (_a) {
      var field = _a.field;
      return React.createElement(UitkCheckbox, {
        id: "marketingConsent",
        checked: field.value,
        onChange: function (e) {
          field.onChange(e);
          trigger('marketingConsent');
          if (e.target.checked) {
            trackEvent({
              event_type: EventType.INTERACTION,
              event_name: 'creator_video_marketing_opt_in.selected',
              action_location: 'submission-form-marketing-checkbox'
            });
          } else {
            trackEvent({
              event_type: EventType.INTERACTION,
              event_name: 'creator_video_marketing_opt_in.deselected',
              action_location: 'submission-form-marketing-checkbox'
            });
          }
        }
      }, "I\u2019d like to receive marketing emails from Expedia Group, including tips, advice, and access to exclusive promotions and deals.");
    }
  }))), React.createElement(UitkPrimaryButton, {
    size: UitkButtonSize.LARGE,
    isFullWidth: true,
    disabled: !isValid || !submissions.length || isLoading || isUploading,
    type: "submit"
  }, isLoading ? React.createElement(UitkLoader, {
    isDarkBackground: true,
    isSmall: true
  }) : 'Submit')))), isUploadDialogOpen && React.createElement(SubmitFormUploadDialog, {
    previewThumbnail: previewThumbnail,
    previewTitle: previewTitle,
    onClose: handleCloseUploadDialog,
    onAttach: handleAttach
  }), React.createElement(Dialog, {
    dialogFullActions: dialogFullActions,
    dialogVCGActions: dialogVCGActions,
    centeredRef: desktopVideoContentGuidelinesTrigger,
    fullRef: fullSheetDialogTrigger,
    isVCG: isVCG,
    fullOpen: isFullDialogOpen,
    vcgOpen: isVCGDialogOpen,
    DialogVCGComponent: DialogVCGComponent,
    DialogFullComponent: DialogFullComponent
  }), isLinkDialogOpen && React.createElement(SubmitFormLinkDialog, {
    onClose: handleCloseLinkDialog,
    onAttach: handleAttach
  }));
}