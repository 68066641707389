import { useLoggerEvents, SystemEvent, SystemEventLevel } from '@shared-ui/logger-context';
var LOG_EVENTS = {
  INFO_EVENT: new SystemEvent(SystemEventLevel.INFO, 'INFO_EVENT'),
  WARNING_EVENT: new SystemEvent(SystemEventLevel.WARN, 'WARNING_EVENT'),
  ERROR_EVENT: new SystemEvent(SystemEventLevel.ERROR, 'ERROR_EVENT')
};
export var useLogger = function () {
  var events = useLoggerEvents(LOG_EVENTS);
  var info = function (message) {
    events.INFO_EVENT.log(message);
  };
  var warn = function (message) {
    events.WARNING_EVENT.log(message);
  };
  var error = function (message) {
    events.ERROR_EVENT.log(message);
  };
  return {
    info: info,
    warn: warn,
    error: error
  };
};