var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import * as React from 'react';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkButtonSize, UitkPrimaryButton, UitkTertiaryButton } from 'uitk-react-button';
import { UitkSheet, UitkSheetContent } from 'uitk-react-sheet';
import { UitkToolbar } from 'uitk-react-toolbar';
import { useStores } from 'shared/hooks';
import { useDevice } from 'shared/utils';
import { VideoGuidelinesContent } from './video-guidelines-content';
import { TermsAndConditionsContent } from './tnc-content';
export function Dialog(_a) {
  var dialogVCGActions = _a.dialogVCGActions,
    dialogFullActions = _a.dialogFullActions,
    isVCG = _a.isVCG,
    isVCGDialogOpen = _a.isVCGDialogOpen,
    isFullDialogOpen = _a.isFullDialogOpen,
    desktopVideoContentGuidelinesTrigger = _a.desktopVideoContentGuidelinesTrigger,
    fullSheetDialogTrigger = _a.fullSheetDialogTrigger,
    DialogVCGComponent = _a.DialogVCGComponent,
    DialogFullComponent = _a.DialogFullComponent;
  var isMobile = useDevice().isMobile;
  var uiStore = useStores().uiStore;
  var closeCenteredSheet = function () {
    dialogVCGActions.closeDialog();
  };
  var closeFullSheet = function () {
    if (uiStore.additionalParams === 'showCVDialog') {
      uiStore.setAdditionalParams('');
    }
    dialogFullActions.closeDialog();
  };
  var _b = __read(React.useState(false), 2),
    switchDialog = _b[0],
    setSwitchDialog = _b[1];
  var handleSwitchDialog = function (bool) {
    setSwitchDialog(bool);
    if (bool) {
      isMobile ? dialogFullActions.openDialog() : dialogVCGActions.openDialog();
    } else {
      if (!isMobile) {
        dialogVCGActions.closeDialog();
      }
      dialogFullActions.openDialog();
    }
  };
  var videoContentGuidelinesBody = function () {
    return React.createElement("div", null, React.createElement(UitkLayoutFlex, {
      direction: "column",
      space: "six",
      className: "CVDialog__flex"
    }, switchDialog && React.createElement(UitkTertiaryButton, {
      onClick: function () {
        return handleSwitchDialog(false);
      }
    }, "Back to Terms and Conditions"), React.createElement(VideoGuidelinesContent, null), React.createElement(UitkPrimaryButton, {
      isFullWidth: true,
      size: UitkButtonSize.LARGE,
      onClick: closeFullSheet
    }, "Done")));
  };
  var termsAndConditionsBody = function () {
    return React.createElement(React.Fragment, null, React.createElement(TermsAndConditionsContent, {
      onGuidelinesClick: function () {
        return handleSwitchDialog(true);
      }
    }), React.createElement(UitkPrimaryButton, {
      isFullWidth: true,
      size: UitkButtonSize.LARGE,
      onClick: closeFullSheet
    }, "Done"));
  };
  return React.createElement(React.Fragment, null, React.createElement(DialogVCGComponent, null, React.createElement(UitkSheet, {
    className: "CVDialog__centered-sheet",
    isVisible: isVCGDialogOpen || switchDialog,
    triggerRef: desktopVideoContentGuidelinesTrigger,
    type: "centered"
  }, React.createElement(UitkToolbar, {
    navigationContent: {
      onClick: closeCenteredSheet,
      navIconLabel: 'Close video content guidelines'
    }
  }), React.createElement(UitkSheetContent, null, videoContentGuidelinesBody()))), React.createElement(DialogFullComponent, null, React.createElement(UitkSheet, {
    className: "CVDialog__full-sheet",
    isVisible: isFullDialogOpen,
    triggerRef: fullSheetDialogTrigger,
    type: "full"
  }, React.createElement(UitkToolbar, {
    navigationContent: {
      onClick: closeFullSheet,
      navIconLabel: 'Close full dialog'
    },
    toolbarTitle: isVCG ? '' : 'Terms & conditions'
  }), React.createElement(UitkSheetContent, {
    className: "CVSheet__margin-auto CVSheet__content"
  }, React.createElement("div", null, isVCG || switchDialog ? videoContentGuidelinesBody() : termsAndConditionsBody())))));
}