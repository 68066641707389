// 3rd party imports
import * as React from 'react';
//Uitk
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkLayoutGrid } from 'uitk-react-layout-grid';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkPrimaryButton } from 'uitk-react-button';
import { useCommonClickStreamEvent, EventType } from 'shared/utils';
import { LinkWithQuery } from 'shared/components';
export function ThreeTile(_a) {
  var heading = _a.heading,
    subHeading = _a.subHeading,
    gridContent = _a.gridContent,
    _b = _a.isBenefit,
    isBenefit = _b === void 0 ? false : _b,
    innerRef = _a.innerRef;
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  function desktopLayout() {
    return React.createElement(UitkLayoutGrid, {
      className: "CV".concat(isBenefit ? 'Benefits' : 'HowItWorks', "__grid"),
      columns: 3,
      rows: 1
    }, gridContent.map(function (gridItem, ind) {
      return React.createElement("div", {
        className: "CVThreeTile__grid-item",
        key: "cc-".concat(isBenefit ? 'benefits' : 'how-it-works', "-griditem-").concat(ind)
      }, React.createElement("img", {
        className: isBenefit ? 'cc-three-tile__large-grid-svg' : 'cc-three-tile__small-grid-svg',
        src: gridItem.svg
      }), React.createElement(UitkHeading, {
        size: 5
      }, gridItem.header), React.createElement(UitkText, {
        className: "cc-text-neutral-1-600",
        size: 400
      }, gridItem.paragraph));
    }));
  }
  var handleRedirectAnalytics = function () {
    trackEvent({
      event_type: EventType.INTERACTION,
      event_name: 'creator_video_go_to_submission.selected',
      action_location: isBenefit ? 'benefits-section-button' : 'how-it-works-section-button'
    }, {
      additional_context: {
        user_interface: {
          component_element_text: isBenefit ? 'benefits section button' : 'how it works section button'
        }
      }
    });
  };
  return React.createElement("div", {
    className: "CVContainer",
    ref: innerRef
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    alignItems: "center",
    alignContent: "center",
    space: "twelve",
    id: isBenefit ? 'benefits' : 'how-it-works'
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    className: "CVGap-16",
    tag: "header"
  }, React.createElement(UitkText, {
    className: "cc-text-neutral-1-600",
    size: 400,
    weight: "medium",
    align: "center"
  }, subHeading), React.createElement(UitkHeading, {
    size: 3,
    tag: "h2",
    align: "center"
  }, heading)), React.createElement(UitkLayoutFlexItem, {
    alignSelf: "center"
  }, desktopLayout()), React.createElement(LinkWithQuery, {
    to: "/en-us/creator-video/submit"
  }, React.createElement(UitkPrimaryButton, {
    className: "cc-three-tile__button",
    onClick: handleRedirectAnalytics
  }, isBenefit ? "I'm ready" : 'Upload my content'))));
}