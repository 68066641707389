import * as React from 'react';
import { useEffect } from 'react';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { Tips, TipsMobile, SubmitForm, Layout } from 'features/creator-video';
import { useCommonClickStreamEvent, EventType, isServer } from 'shared/utils';
import { useLocation } from 'react-router-dom';
import { useLogger } from 'shared/hooks';
function SubmitPage() {
  var location = useLocation();
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  var logger = useLogger();
  var handleScroll = function (ref) {
    var _a;
    (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'center'
    });
  };
  React.useEffect(function () {
    trackEvent({
      event_type: EventType.PAGE_VIEW,
      event_name: 'creator_video_submission.viewed'
    });
  }, []);
  var tipsRef = React.useRef(null);
  var formRef = React.useRef(null);
  React.useEffect(function () {
    if (!isServer && tipsRef.current && formRef.current && location.hash) {
      var anchor = location.hash.split('#')[1];
      switch (anchor) {
        case 'tips':
          handleScroll(tipsRef);
          break;
        default:
          handleScroll(formRef);
          break;
      }
    }
  }, [location.hash, tipsRef.current, formRef.current]);
  var handleHeroLinkClick = function (e) {
    e.preventDefault();
    trackEvent({
      event_type: EventType.INTERACTION,
      event_name: 'creator_video_redirect_to_section.selected',
      action_location: 'submission-page-hero-link'
    }, {
      additional_context: {
        user_interface: {
          component_name: 'submission hero link',
          component_element_text: 'tips'
        }
      }
    });
    tipsRef.current.scrollIntoView({
      behavior: 'smooth'
    });
  };
  useEffect(function () {
    logger.info('Submission page loaded');
  }, []);
  return React.createElement(Layout, null, React.createElement("section", {
    className: "CVSection"
  }, React.createElement("div", {
    className: "CVContainer"
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: "twelve"
  }, React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    justifyContent: "center",
    direction: "column",
    space: "two"
  }, React.createElement(UitkHeading, {
    className: "CVForm__heading",
    size: 3,
    align: "center"
  }, "Submit your content", React.createElement("img", {
    className: "CVHero__svg CVForm__yellow-highlight-svg",
    src: "/images/highlight-yellow.svg"
  })), React.createElement(UitkText, {
    size: 500,
    align: "center"
  }, "Check out ", React.createElement("a", {
    onClick: function (event) {
      return handleHeroLinkClick(event);
    }
  }, "travel video best practices"))), React.createElement(SubmitForm, {
    innerRef: formRef
  })))), React.createElement("section", {
    className: "CVSection"
  }, React.createElement("div", {
    className: "hide-desktop"
  }, React.createElement(TipsMobile, {
    innerRef: tipsRef
  })), React.createElement("div", {
    className: "show-desktop"
  }, React.createElement(Tips, {
    innerRef: tipsRef
  }))));
}
export default SubmitPage;
export { SubmitPage };