import React from 'react';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkBadgeSize, UitkBadgeStandard, UitkBadgeStandardThemes } from 'uitk-react-badge';
var FeatureContent = function (_a) {
  var imageSrc = _a.imageSrc,
    altText = _a.altText,
    performanceImage = _a.performanceImage,
    badgeText = _a.badgeText,
    heading = _a.heading,
    featureList = _a.featureList,
    reverse = _a.reverse;
  return React.createElement(UitkLayoutFlex, {
    alignItems: 'center',
    className: "TCFeatures__feature-content ".concat(reverse ? 'reverse' : null)
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement("div", null, React.createElement("img", {
    src: imageSrc,
    alt: altText,
    className: "TCFeatures__image ".concat(performanceImage ? 'TCFeatures__image__performance' : null)
  }))), React.createElement(UitkLayoutFlexItem, {
    grow: 1
  }, React.createElement("div", null, React.createElement("div", null, React.createElement(UitkBadgeStandard, {
    text: badgeText,
    theme: UitkBadgeStandardThemes.EMPHASIS,
    size: UitkBadgeSize.LARGE
  })), React.createElement("div", {
    className: "TCFeatures__heading__container"
  }, React.createElement(UitkHeading, {
    tag: 'h3',
    className: "TCFeatures__heading"
  }, heading)), React.createElement(UitkLayoutFlex, {
    direction: "column",
    tag: "ul",
    className: "TCFeatures__list"
  }, featureList.map(function (item, id) {
    return React.createElement("li", {
      key: id
    }, React.createElement(UitkSpacing, {
      padding: {
        block: 'one'
      }
    }, React.createElement(UitkLayoutFlex, {
      space: 'four',
      alignItems: 'center'
    }, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkIcon, {
      name: item.icon.name,
      id: item.icon.id,
      size: UitkIconSize.MEDIUM
    })), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkText, {
      theme: 'emphasis',
      className: "TCFeatures__list__text"
    }, item.text)))));
  })))));
};
export { FeatureContent };