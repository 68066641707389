import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
export function useCreatorVideoSetup() {
  var location = useLocation();
  useEffect(function () {
    var _a, _b, _c, _d, _e, _f;
    var shouldUpdateSessionStorage = !sessionStorage.getItem('source') || !sessionStorage.getItem('itinerary') || !sessionStorage.getItem('orderNumber') || !sessionStorage.getItem('propertyId') || !sessionStorage.getItem('tripStatus') || !sessionStorage.getItem('userType');
    if (shouldUpdateSessionStorage) {
      var urlSearchParams = new URLSearchParams(location.search);
      var source = (_a = sessionStorage.getItem('source')) !== null && _a !== void 0 ? _a : urlSearchParams.get('source');
      var itinerary = (_b = sessionStorage.getItem('itinerary')) !== null && _b !== void 0 ? _b : urlSearchParams.get('itinerary');
      var orderNumber = (_c = sessionStorage.getItem('orderNumber')) !== null && _c !== void 0 ? _c : urlSearchParams.get('orderNumber');
      var propertyId = (_d = sessionStorage.getItem('propertyId')) !== null && _d !== void 0 ? _d : urlSearchParams.get('propertyId');
      var tripStatus = (_e = sessionStorage.getItem('tripStatus')) !== null && _e !== void 0 ? _e : urlSearchParams.get('tripStatus');
      if (source === null || source.trim() === '') {
        source = 'LANDING_PAGE';
        sessionStorage.setItem('userType', 'creator');
      } else if (source.match(/reviews/i)) {
        tripStatus = 'IN_TRIP';
      }
      sessionStorage.setItem('source', source);
      sessionStorage.setItem('itinerary', itinerary);
      sessionStorage.setItem('orderNumber', orderNumber);
      sessionStorage.setItem('propertyId', propertyId);
      sessionStorage.setItem('tripStatus', tripStatus);
      sessionStorage.setItem('userType', (_f = sessionStorage.getItem('userType')) !== null && _f !== void 0 ? _f : 'traveler');
    }
  }, [location]);
}