import React from 'react';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkHeading } from 'uitk-react-text';
import { FeatureContent } from './feature-content';
var featuresData = [{
  imageSrc: "/images/travel-creator-landing/hero-image.png",
  altText: "A video of Expedia Travel Shops",
  performanceImage: false,
  badgeText: "Travel Shops",
  heading: "Curate your travel picks in one place for easy exploration and booking",
  reverse: false,
  featureList: [{
    icon: {
      name: "payments",
      id: "payments"
    },
    text: "4% commission on bookings"
  }, {
    icon: {
      name: "ondemand_entertainment",
      id: "ondemand_entertainment"
    },
    text: "Wider reach"
  }, {
    icon: {
      name: "insurance_hotels",
      id: "insurance_hotels"
    },
    text: "Hand-picked collections"
  }, {
    icon: {
      name: "map",
      id: "map"
    },
    text: "Curated trips (Coming 2025)"
  }]
}, {
  imageSrc: "/images/travel-creator-landing/hero-image.png",
  altText: "A video of Expedia Travel Shops",
  performanceImage: false,
  badgeText: "Travel Videos",
  heading: "Captivate your audience with videos that bring stunning destinations to life",
  reverse: true,
  featureList: [{
    icon: {
      name: "ondemand_entertainment",
      id: "ondemand_entertainment"
    },
    text: "$150 per approved video"
  }, {
    icon: {
      name: "payments",
      id: "payments"
    },
    text: "2% commission on bookings "
  }, {
    icon: {
      name: "smartphone",
      id: "smartphone"
    },
    text: "Visibility across app and site"
  }]
}, {
  imageSrc: "/images/travel-creator-landing/hero-image.png",
  altText: "A video of Creator Tools",
  performanceImage: false,
  badgeText: "Creator Toolbox",
  heading: "Generate links and track stats on the go for smarter, data-driven decisions",
  reverse: false,
  featureList: [{
    icon: {
      name: "download",
      id: "download"
    },
    text: "Download property images"
  }, {
    icon: {
      name: "smartphone",
      id: "smartphone"
    },
    text: "Access through the app"
  }, {
    icon: {
      name: "language",
      id: "language"
    },
    text: "Available on Expedia, Hotels.com, and Vrbo"
  }]
}, {
  imageSrc: "/images/travel-creator-landing/performance-dashboard.png",
  altText: "An image of Performance Dashboard",
  performanceImage: true,
  badgeText: "Performance Dashboard",
  heading: "Boost revenue with easy-to-track bookings",
  reverse: true,
  featureList: [{
    icon: {
      name: "payments",
      id: "payments"
    },
    text: "Payment history"
  }, {
    icon: {
      name: "query_stats",
      id: "query_stats"
    },
    text: "Link-level reporting"
  }, {
    icon: {
      name: "data_trends",
      id: "data_trends"
    },
    text: "Trend analysis"
  }, {
    icon: {
      name: "sentiment_5",
      id: "sentiment_5"
    },
    text: "Mobile-optimized"
  }]
}];
var FeaturesSection = function (_a) {
  var refProp = _a.refProp;
  return React.createElement("section", {
    className: "TCFeatures",
    id: "features",
    ref: refProp
  }, React.createElement(UitkLayoutFlex, {
    className: "TCFeatures__section-heading__container"
  }, React.createElement(UitkLayoutFlexItem, {
    grow: 1
  }, React.createElement("div", null, React.createElement(UitkHeading, {
    tag: 'h2',
    className: "TCFeatures__section-heading"
  }, "What's in it for you")))), React.createElement(UitkLayoutFlex, {
    direction: 'column',
    className: 'container'
  }, featuresData.map(function (feature, id) {
    return React.createElement(UitkLayoutFlexItem, {
      key: id
    }, React.createElement(FeatureContent, {
      imageSrc: feature.imageSrc,
      altText: feature.altText,
      performanceImage: feature.performanceImage,
      badgeText: feature.badgeText,
      heading: feature.heading,
      featureList: feature.featureList,
      reverse: feature.reverse
    }));
  })));
};
export { FeaturesSection };