import React from 'react';
import { UitkButtonSize, UitkSecondaryButton } from 'uitk-react-button';
import { UitkCard } from 'uitk-react-cards';
import { UitkFigure } from 'uitk-react-images';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkScrim, UitkScrimType } from 'uitk-react-scrim';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { usePlatformContext } from '@bernie/platform-context';
import { makeLocaleParam } from 'shared/utils';
var AffiliateProgramSection = function () {
  var context = usePlatformContext().context;
  return React.createElement("section", {
    className: "TCAffiliateProgram"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement(UitkCard, null, React.createElement(UitkFigure, {
    src: '/images/travel-creator-landing/affiliate-program.png',
    className: "TCAffiliateProgram__card-image"
  }, React.createElement(UitkScrim, {
    type: UitkScrimType.OVERLAY,
    className: "TCAffiliateProgram__scrim"
  }, React.createElement(UitkLayoutFlex, {
    direction: 'column',
    alignContent: "center",
    justifyContent: 'center',
    className: "TCAffiliateProgram__content"
  }, React.createElement(UitkLayoutFlexItem, {
    className: "TCAffiliateProgram__heading__container"
  }, React.createElement("div", null, React.createElement(UitkHeading, {
    tag: "h2",
    className: "TCAffiliateProgram__heading"
  }, "Explore our Affiliate Program"))), React.createElement(UitkLayoutFlexItem, {
    className: "TCAffiliateProgram__description__container"
  }, React.createElement("div", null, React.createElement(UitkText, {
    className: "TCAffiliateProgram__description"
  }, "If you're not a social media creator and prefer sharing links on your travel blog or website, check out the benefits of our Affiliate Program."))), React.createElement(UitkLayoutFlexItem, {
    className: "TCAffiliateProgram__button__container"
  }, React.createElement("div", null, React.createElement(UitkSecondaryButton, {
    size: UitkButtonSize.MEDIUM,
    href: "/".concat(makeLocaleParam(context.locale), "/home"),
    inverse: true
  }, "Learn more")))))))));
};
export { AffiliateProgramSection };