import * as React from 'react';
import { useEffect } from 'react';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { Layout } from 'features/creator-video';
import { useCommonClickStreamEvent, EventType } from 'shared/utils';
import { useLogger } from 'shared/hooks';
import { UitkLink } from 'uitk-react-link';
function CreatorVideoAcceptanceAgreementPage() {
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  var logger = useLogger();
  React.useEffect(function () {
    trackEvent({
      event_type: EventType.IMPRESSION,
      event_name: 'creator_video_terms_and_conditions.presented',
      action_location: 'acceptance-agreement-page'
    });
  }, []);
  useEffect(function () {
    logger.info('Creator video acceptance agreement loaded');
  }, []);
  return React.createElement(Layout, null, React.createElement(UitkLayoutFlex, {
    justifyContent: "center"
  }, React.createElement("div", {
    className: "CV_documents"
  }, React.createElement(UitkHeading, {
    size: 2,
    tag: "h2"
  }, "Video Acceptance Agreement"), React.createElement(UitkLayoutFlex, {
    className: "CVDialog__flex",
    direction: "column",
    space: "four"
  }, React.createElement(UitkText, {
    size: 300
  }, "This Video Acceptance Agreement (\u201CAgreement\u201D) is made between Expedia, Inc., on behalf of itself and its parents, affiliates, and subsidiaries (\u201CCompany\u201D), located at 1111 Expedia Group Way W., Seattle, WA 98119 and (\u201CVideo Creator\u201D (or \u201Cthey\u201D herein) (together \u201CParties\u201D herein)."), React.createElement("ol", {
    className: "nested-order-list"
  }, React.createElement("li", null, React.createElement(UitkText, {
    weight: "bold",
    size: 300
  }, "Ownership and Use of Video(s)."), React.createElement("ol", {
    className: "nested-order-list"
  }, React.createElement("li", null, "Video Creator has submitted a video(s) through Company\u2019s video creator submission process which adheres to the", ' ', React.createElement(UitkLink, {
    inline: true
  }, React.createElement("a", {
    target: "_blank",
    href: "/en-us/creator-video/video-guidelines"
  }, "Video Guidelines")), ', (the “Video(s)”).'), React.createElement("li", null, "Company has reviewed Video Creator\u2019s Video(s) submission and is selecting it for potential use and may edit, copy, add to, delete, adapt, translate, or otherwise modify the Vides(s) in any way Company sees fit. Video Creator acknowledges that, although Company is selecting the Video(s), Company shall have no obligation to use the Video(s)."), React.createElement("li", null, "Video Creator hereby acknowledges and do hereby assigns to Company, and its successors and assigns, all of Video Creator\u2019s rights, title, and interest throughout the world in and to the Video(s), including all intellectual property rights therein.  Without limiting the foregoing, Video Creator hereby waives (or, where waiver is not permissible, assigns to Company) any so-called \u201Cmoral rights\u201D or \u201Cdroit moral\u201D and other non-transferable rights in the Video(s) to Company under the present or future laws of any jurisdiction."))), React.createElement("li", null, React.createElement(UitkText, {
    weight: "bold",
    size: 300
  }, "Video(s)."), " The Video(s) submissions subject to this Agreement shall include all images, recordings (audio and/or audio visual), text, and/or other creative materials contained in the videos submitted through Company\u2019s video creator submission process."), React.createElement("li", null, React.createElement(UitkText, {
    weight: "bold",
    size: 300
  }, "Payment."), " In exchange for accepting the Video(s) submission, Company has or will provide Video Creator with payment of $150 USD, which represents full and complete payment for ownership and use of the Video(s). Such payment may be made in form of gift card with monetary value of the $150 USD payment. The Video Creator is responsible for paying any applicable federal, provincial, state, local, income and withholding taxes, if any."), React.createElement("li", null, React.createElement(UitkText, {
    weight: "bold",
    size: 300
  }, "Representations and Warranties."), "  Video Creator represents and warrants:", React.createElement("ol", {
    className: "nested-order-list"
  }, React.createElement("li", null, "Video Creator, in creating the Content, adhered to the Company\u2019s", ' ', React.createElement(UitkLink, {
    inline: true
  }, React.createElement("a", {
    target: "_blank",
    href: "/en-us/creator-video/video-guidelines"
  }, "Video Guidelines"))), React.createElement("li", null, "Video Creator, in creating the Content, complied with: (i) all applicable laws, regulations, rules and government and industry guidelines. "), React.createElement("li", null, "Video(s) submissions are original and an accurate and true representation of the subject matter of the video.  "), React.createElement("li", null, "Video Creator owns or has the right to use all images, text, music, trademarks, logos, other material or intellectual property contained within the Video(s) and use of the Video(s) by Company will not infringe or violate any third party rights (including any intellectual property rights, publicity rights, moral rights, or rights of privacy) and all persons appearing in the Video(s) have consented to the use of their photograph and likeness and any other personality rights of such persons being used in association with the images at no cost to, or additional licensing required by, Company. Video Creator shall provide prompt written notice to Company in the event Video Creator becomes aware of any claim, or potential claim, that the Video(s) infringes upon the rights of any third party. "))), React.createElement("li", null, React.createElement(UitkText, {
    weight: "bold",
    size: 300
  }, "Publicity Rights; Grant of License to Company."), "  If Video Creator is featured in the Video(s), Video Creator hereby grants to Company and its marketing affiliate (\u201CAuthorized Parties\u201D), a non-exclusive, perpetual, irrevocable, worldwide license to use Video Creator\u2019s name, social media links and/or handles, pseudonym, biographical facts, voice, statements, photograph and likeness (\u201Ccollectively, \u201CPersonal Material\u201D), alone or combined with any material, in any media, now known or hereafter developed, in connection with and for Company\u2019s marketing, promotional and advertising purposes. Video Creator understands that if they are a resident of the EU, UK, Brazil, Thailand, or other relevant jurisdiction, they have the right to withdraw their consent at any time to the use of their Personal Materials by contacting Company via PrivacyTeam@expedia.com. Video Creator understands that this will not affect the lawfulness of any processing carried out by the Authorized Parties before withdrawing consent."), React.createElement("li", null, React.createElement(UitkText, {
    weight: "bold",
    size: 300
  }, "Exclusivity."), "  Video Creator acknowledges and agrees that Company has the exclusive rights in the Video(s) subject to this Agreement and Video Creator shall not enter into any agreement with any other online travel agency or entity or person and offer said Video(s) to any such third parties."), React.createElement("li", null, React.createElement(UitkText, {
    weight: "bold",
    size: 300
  }, "Limitation of Liability. "), React.createElement("ol", {
    className: "nested-order-list"
  }, React.createElement("li", null, "Video Creator agrees that Company shall have no liability whatsoever to Video Creator, its heirs, legal representatives, and assigns for Company\u2019s ownership and use of the Video(s)."), React.createElement("li", null, "Neither party will have any liability whatsoever in contract, tort (including negligence) or otherwise for any loss of goodwill, business, revenue or profits, anticipated savings or wasted expenditure or for an indirect or consequential loss, whether any such loss whether such loss was reasonably foreseeable or not."))), React.createElement("li", null, React.createElement(UitkText, {
    weight: "bold",
    size: 300
  }, "Term and Termination "), React.createElement("ol", {
    className: "nested-order-list"
  }, React.createElement("li", null, "The term of this Agreement will commence on the Effective Date and will continue, unless terminated (\u201CTerm\u201D). This Agreement shall apply for all Video(s) submissions accepted by Company during the Term."), React.createElement("li", null, "Company shall have the right to terminate this Agreement for convenience with no cure period at any time during the Term."))), React.createElement("li", null, React.createElement(UitkText, {
    weight: "bold",
    size: 300
  }, "Miscellaneous"), React.createElement("ol", {
    className: 'nested-order-list'
  }, React.createElement("li", null, "Confidentiality. The Parties do not intend to share or disclose any confidential information under this Agreement. However, in the event Company provides confidential information to Video Creator pursuant to this Agreement, Video Creator shall not use or disclose any such confidential information to any third party for any reason."), React.createElement("li", null, "Data Protection. The Parties agree that no personal data or its equivalent term in any jurisdiction, shall be shared between the Parties other than basic business contact information for the purposes of facilitating the Services under this Agreement."), React.createElement("li", null, "No Assignment. Video Creator may not assign or delegate any of his/her obligations to any third party without specific written authorization from Company."), React.createElement("li", null, "No Duty. Company will not have any duty to use any Video(s), or otherwise exercise any of the rights granted in this Agreement."), React.createElement("li", null, "Severability. The provisions of this Agreement are severable and if any portion is held invalid, illegal, or unenforceable for any reason, the remainder of this Agreement will be effective and binding on the parties."), React.createElement("li", null, "Relationship of the Parties. This Agreement does not constitute employment, a partnership, or joint venture between Company and Video Creator."), React.createElement("li", null, "Governing Law. The validity, interpretation, construction, and performance of this Agreement is governed by, and interpreted in accordance with, the laws of the State of Washington."))))))));
}
export default CreatorVideoAcceptanceAgreementPage;
export { CreatorVideoAcceptanceAgreementPage };