import React from 'react';
import { useLocalization } from "@shared-ui/localization-context";
export function LocalizedTextWrapping(props) {
  var l10n = useLocalization();
  var message = props.message,
    fieldName = props.fieldName;
  var outerText = l10n.formatText(message).split("[".concat(fieldName, "]"));
  switch (outerText.length) {
    case 1:
      return React.createElement(React.Fragment, null, outerText[0]);
    case 2:
      return React.createElement(React.Fragment, null, outerText[0], props.children, outerText[1]);
    default:
      throw new Error("More than one field called ".concat(fieldName));
  }
}