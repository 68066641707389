var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
import axios from 'axios';
import { validate as isUUID } from 'uuid';
export var getPartnerEmail = function (state, logger) {
  return {
    queryKey: ['get-partner-email', state.query.email, state.query.id],
    queryFn: function () {
      return __awaiter(void 0, void 0, void 0, function () {
        var _a, protocol, hostname, _b, emailParam, id, email, partnerId, baseLog, bffService, data, error_1;
        return __generator(this, function (_c) {
          switch (_c.label) {
            case 0:
              _a = state.app.bff, protocol = _a.protocol, hostname = _a.hostname;
              _b = state.query, emailParam = _b.email, id = _b.id;
              email = decodeURIComponent(emailParam).replace(/\s/g, '+');
              partnerId = id;
              baseLog = {
                prefetched: typeof window === 'undefined',
                event: 'unsubscribe-get-partner-email',
                success: false,
                partnerId: partnerId,
                email: email
              };
              if (!email && !id) {
                logger.warn(__assign(__assign({}, baseLog), {
                  message: 'missing email and id'
                }));
                return [2 /*return*/, {
                  email: null
                }];
              }
              if (email && isUUID(email)) {
                partnerId = email;
                logger.info(__assign(__assign({}, baseLog), {
                  partnerId: partnerId,
                  message: 'using email as partnerId'
                }));
              }
              if (email && !partnerId) {
                logger.info(__assign(__assign({}, baseLog), {
                  success: true,
                  message: 'email provided but no partnerId'
                }));
                return [2 /*return*/, {
                  email: email,
                  partnerId: partnerId
                }];
              }
              if (!partnerId || !isUUID(partnerId)) {
                logger.info(__assign(__assign({}, baseLog), {
                  message: 'id is not present or not a valid UUID'
                }));
                return [2 /*return*/, {
                  email: null,
                  partnerId: partnerId
                }];
              }
              _c.label = 1;
            case 1:
              _c.trys.push([1, 3,, 4]);
              bffService = "".concat(protocol, "//").concat(hostname, "/api/v1");
              return [4 /*yield*/, axios.get("".concat(bffService, "/partners/").concat(partnerId, "/emails"))];
            case 2:
              data = _c.sent().data;
              if (data.body.length === 0) {
                logger.warn(__assign(__assign({}, baseLog), {
                  message: 'no emails in response'
                }));
                return [2 /*return*/, {
                  email: null,
                  partnerId: partnerId
                }];
              }
              logger.info(__assign(__assign({}, baseLog), {
                success: data.success,
                email: data.body[0],
                partnerId: partnerId,
                message: 'successfully retrieved partner email'
              }));
              return [2 /*return*/, {
                email: data.body[0],
                partnerId: partnerId
              }];
            case 3:
              error_1 = _c.sent();
              logger.error(__assign(__assign({}, baseLog), {
                message: 'failed to retrieve partner email',
                error: error_1
              }));
              return [2 /*return*/, {
                email: null,
                partnerId: partnerId
              }];
            case 4:
              return [2 /*return*/];
          }
        });
      });
    }
  };
};