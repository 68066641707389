import { QueryClient } from '@tanstack/react-query';
export var PLUGIN_NAME = 'query-state';
var client = new QueryClient({
  defaultOptions: {
    queries: {
      // staleTime is the time in ms that the query will be considered fresh
      // With SSR, we usually want to set some default staleTime
      // above 0 to avoid refetching immediately on the client
      staleTime: 60 * 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  }
});
export var queryClient = client;