// 3rd party imports
import * as React from 'react';
// components
import { ExpandFooter } from './expand-footer';
import { Header } from './header';
export var Layout = function (_a) {
  var children = _a.children,
    centered = _a.centered;
  var cookieLink = React.useRef();
  return React.createElement("div", {
    className: "Layout"
  }, React.createElement(Header, null), React.createElement("section", {
    className: "Layout__body ".concat(centered ? 'Layout__body--centered' : '')
  }, children), React.createElement(ExpandFooter, {
    cookieLinkRef: cookieLink
  }));
};