var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import * as React from 'react';
import { EventType, useCommonClickStreamEvent } from 'shared/utils';
var HeroVideo = function (_a) {
  var url = _a.url,
    poster = _a.poster;
  var videoRef = React.useRef(null);
  var _b = __read(React.useState(true), 2),
    isVideoPlaying = _b[0],
    setIsVideoPlaying = _b[1];
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  var handleVideoClick = function () {
    setIsVideoPlaying(!isVideoPlaying);
    videoRef.current[isVideoPlaying ? 'pause' : 'play']();
  };
  React.useEffect(function () {
    var _a;
    if (isVideoPlaying && videoRef.current) {
      trackEvent({
        event_type: EventType.IMPRESSION,
        event_name: 'creator_video_media.presented',
        action_location: 'hero-video'
      }, {
        additional_context: {
          user_interface: {
            component_name: 'hero video is played',
            component_element_name: "".concat((_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.src, " video")
          }
        }
      });
    }
  }, [isVideoPlaying, videoRef === null || videoRef === void 0 ? void 0 : videoRef.current]);
  return React.createElement("video", {
    autoPlay: true,
    role: "video",
    ref: videoRef,
    poster: poster,
    style: {
      width: '100%',
      position: 'relative'
    },
    onClick: handleVideoClick,
    loop: true,
    muted: true,
    preload: "auto",
    playsInline: true
  }, React.createElement("source", {
    src: url,
    type: "video/mp4"
  }));
};
export { HeroVideo };