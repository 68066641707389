var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import * as React from 'react';
import { useState, useEffect } from 'react';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkFigure, UitkImage } from 'uitk-react-images';
import { UitkText } from 'uitk-react-text';
import { UitkBadgeStandard } from 'uitk-react-badge';
import { UitkIcon } from 'uitk-react-icons';
import { UitkButtonSize, UitkTertiaryButton } from 'uitk-react-button';
import { UitkLoader } from 'uitk-react-loader';
import { useStores, useLogger } from 'shared/hooks';
import { useCommonClickStreamEvent, EventType } from 'shared/utils';
export function SubmitFormSubmission(_a) {
  var _this = this;
  var submission = _a.submission,
    onUploadStart = _a.onUploadStart,
    onDeleteSubmission = _a.onDeleteSubmission,
    onUploadComplete = _a.onUploadComplete,
    onUploadError = _a.onUploadError;
  var _b = __read(useState(false), 2),
    isLoading = _b[0],
    setIsLoading = _b[1];
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  var logger = useLogger();
  var uiStore = useStores().uiStore;
  var bffUrl = uiStore.bffUrl;
  useEffect(function () {
    var isMounted = true;
    var uploadVideo = function () {
      return __awaiter(_this, void 0, void 0, function () {
        var response, s3Url, uploadRes;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              if (!submission.uploadedFile) {
                return [2 /*return*/];
              }
              setIsLoading(true);
              onUploadStart();
              logger.info('Fetching S3 URL for video upload');
              return [4 /*yield*/, fetch("".concat(bffUrl, "/api/creator-videos/get-s3-url"), {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  fileName: submission.uploadedFile.name,
                  fileType: submission.uploadedFile.type
                })
              })];
            case 1:
              response = _a.sent();
              if (!response.ok) {
                logger.error('Failed to get S3 URL for video upload');
                onUploadError();
                return [2 /*return*/];
              }
              return [4 /*yield*/, response.json()];
            case 2:
              s3Url = _a.sent();
              logger.info('Uploading video to S3');
              return [4 /*yield*/, fetch(s3Url.url, {
                method: 'PUT',
                headers: {
                  'Content-Type': submission.uploadedFile.type
                },
                body: submission.uploadedFile
              })];
            case 3:
              uploadRes = _a.sent();
              if (!uploadRes.ok) {
                logger.error('Failed to upload video to S3');
                onUploadError();
                return [2 /*return*/];
              }
              onUploadComplete(s3Url.key);
              logger.info('Video uploaded to S3');
              if (isMounted) {
                setIsLoading(false);
              }
              trackEvent({
                event_type: EventType.OUTCOME,
                event_name: 'creator_video_submission.succeeded',
                action_location: 'submission-form'
              }, {
                form: {
                  form_name: 'creator_video_submission',
                  short_field_name: 'upload video file to s3'
                }
              });
              return [2 /*return*/];
          }
        });
      });
    };
    uploadVideo();
    return function () {
      isMounted = false;
    };
  }, [submission.uploadedFile]);
  return React.createElement(UitkLayoutFlex, {
    className: "CVSubmission",
    direction: "column",
    space: "three"
  }, !submission.externalVideoUrl ? React.createElement(UitkFigure, {
    className: "CVSubmission__thumb"
  }, React.createElement(UitkImage, {
    src: submission.thumbUrl,
    alt: submission.title,
    className: "CVSubmission__image ".concat(isLoading ? 'CVSubmission__image--loading' : '')
  }), isLoading && React.createElement(UitkLoader, {
    className: "CVSubmission__loader",
    isDarkBackground: true,
    isSmall: true
  }), !isLoading && React.createElement(UitkTertiaryButton, {
    className: "CVSubmission__delete",
    size: UitkButtonSize.SMALL,
    onClick: onDeleteSubmission
  }, React.createElement(UitkIcon, {
    name: "delete"
  })), React.createElement("div", {
    className: "CVSubmission__scrim"
  }), React.createElement(UitkBadgeStandard, {
    className: "CVSubmission__badge",
    size: "small"
  }, "Video")) : React.createElement("div", {
    className: "CVSubmission__thumb CVSubmission__thumb--".concat(submission.socialPlatform, " ").concat(isLoading ? 'CVSubmission__thumb--loading' : '')
  }, isLoading && React.createElement(UitkLoader, {
    className: "CVSubmission__loader",
    isDarkBackground: true,
    isSmall: true
  }), !isLoading && React.createElement(UitkTertiaryButton, {
    className: "CVSubmission__delete",
    size: UitkButtonSize.SMALL,
    onClick: onDeleteSubmission
  }, React.createElement(UitkIcon, {
    name: "delete"
  })), React.createElement("img", {
    alt: "social media logo",
    src: "/images/creator-video/".concat(submission.socialPlatform, ".svg")
  }), React.createElement(UitkBadgeStandard, {
    className: "CVSubmission__badge",
    size: "small"
  }, "Link")), React.createElement(UitkLayoutFlex, {
    direction: "column"
  }, React.createElement(UitkText, {
    overflow: "truncate",
    size: 200,
    weight: "medium",
    className: "CVSubmission__title"
  }, submission.propertyName), React.createElement(UitkText, {
    overflow: "truncate",
    size: 200,
    weight: "medium",
    className: "CVSubmission__filename"
  }, submission.externalVideoUrl || submission.title)));
}