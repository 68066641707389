import React from 'react';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkList, UitkListItem } from 'uitk-react-list';
export var VideoGuidelinesContent = function () {
  return React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: "six",
    className: "CVDialog__flex"
  }, React.createElement(UitkHeading, {
    size: 2
  }, "Video Guidelines"), React.createElement(UitkText, {
    size: 400
  }, "Last revised: 10/22/2024"), React.createElement(UitkText, {
    size: 400
  }, "We recognize how important it is that our platform provides a safe environment for everyone in the travel community, and that the contributions on our site provide useful and relevant guidance that helps travelers plan their trips."), React.createElement(UitkText, {
    size: 400
  }, "All accepted and posted videos must meet these requirements. The Video Guidelines (\u201CVideo Guidelines\u201D) cover the rules for video submissions uploaded or shared to Expedia Group. Please note that these Video Guidelines may be modified from time to time, so you\u2019re encouraged to check back for updates."), React.createElement(UitkHeading, {
    size: 4
  }, "Key Requirements"), React.createElement(UitkLayoutFlex, {
    className: "CVDialog__vcg-flex-gap",
    direction: "column"
  }, React.createElement(UitkText, {
    size: 500
  }, "Relevant to other travelers:"), React.createElement(UitkText, {
    size: 400
  }, "All videos must have relevance to a hotel or vacation rental accommodation from Expedia, Hotels.com, or VRBO.")), React.createElement(UitkLayoutFlex, {
    className: "CVDialog__vcg-flex-gap",
    direction: "column"
  }, React.createElement(UitkText, {
    size: 500
  }, "Video Duration:"), React.createElement(UitkText, {
    size: 400
  }, "The ideal video length is 10-30 seconds with the maximum allowed length of 60 seconds and a minimum allowed length of 10 seconds.")), React.createElement(UitkLayoutFlex, {
    className: "CVDialog__vcg-flex-gap",
    direction: "column"
  }, React.createElement(UitkText, {
    size: 500
  }, "Video Format:"), React.createElement(UitkText, {
    size: 400
  }, "Acceptable video formats include mp4 or mov, with a preference for mp4.")), React.createElement(UitkLayoutFlex, {
    className: "CVDialog__vcg-flex-gap",
    direction: "column"
  }, React.createElement(UitkText, {
    size: 500
  }, "Audio:"), React.createElement(UitkText, {
    size: 400
  }, "Refrain from including music, voiceovers, or other sound effects (individually and collectively, \"audio\"). Expedia Group may reject, remove, and/or replace audio (including with, but not limited to, non-copyrighted music) on any accepted video submissions.")), React.createElement(UitkLayoutFlex, {
    className: "CVDialog__vcg-flex-gap",
    direction: "column"
  }, React.createElement(UitkText, {
    size: 500
  }, "Video Size:"), React.createElement(UitkText, {
    size: 400
  }, "Videos must not exceed 500 MB. ")), React.createElement(UitkLayoutFlex, {
    className: "CVDialog__vcg-flex-gap",
    direction: "column"
  }, React.createElement(UitkText, {
    size: 500
  }, "Video Resolution:"), React.createElement(UitkText, {
    size: 400
  }, "Videos should have a minimum frame rate of 30 FPS (frames per second) and minimum resolution of 1080*1920 pixels. Expedia does not support 4K or higher resolutions. If you upload a 4K video to Expedia, it will be compressed and cropped.")), React.createElement(UitkLayoutFlex, {
    className: "CVDialog__vcg-flex-gap",
    direction: "column"
  }, React.createElement(UitkText, {
    size: 500
  }, "Video Orientation:"), React.createElement(UitkText, {
    size: 400
  }, "All videos must be filmed in portrait mode (9:16).")), React.createElement(UitkLayoutFlex, {
    className: "CVDialog__vcg-flex-gap",
    direction: "column"
  }, React.createElement(UitkText, {
    size: 500
  }, "License and Rights to Publicity of Content:"), React.createElement(UitkText, {
    size: 400
  }, "By submitting a video of any kind, you hereby grant to Expedia Group and its affiliates a nonexclusive, royalty free, fully paid, worldwide, perpetual, irrevocable, transferable and fully sublicensable (through multiple tiers) right and license to: (1) use, copy, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and publicly display and perform all Video posted or submitted to Expedia Group by you (whether before or after the posting of these Video Guidelines) throughout the world for any purpose in any media or form now known or hereafter devised. For any voice, image or likeness of any individual in your video, you hereby grant to Expedia Group a nonexclusive, royalty free, fully paid, worldwide, perpetual, irrevocable, transferable, and fully sublicensable (through multiple tiers) right and license.")), React.createElement(UitkLayoutFlex, {
    className: "CVDialog__vcg-flex-gap",
    direction: "column"
  }, React.createElement(UitkText, {
    size: 500
  }, "Ownership:"), React.createElement(UitkText, {
    size: 400
  }, "Upon Expedia Group\u2019s acceptance of your video, you hereby transfer all rights, title and interest in and to your video to Expedia Group.")), React.createElement(UitkHeading, {
    size: 4
  }, "Creative guidelines"), React.createElement(UitkLayoutFlex, {
    className: "CVDialog__vcg-flex-gap",
    direction: "column"
  }, React.createElement(UitkText, {
    size: 500
  }, "Engagement:"), React.createElement(UitkText, {
    size: 400
  }, "Create engaging and informative videos showcasing unique aspects of the property and surroundings.")), React.createElement(UitkLayoutFlex, {
    className: "CVDialog__vcg-flex-gap",
    direction: "column"
  }, React.createElement(UitkText, {
    size: 500
  }, "Primary Focus:"), React.createElement(UitkText, {
    size: 400
  }, "Video primary focus should be on the property."), React.createElement(UitkList, null, React.createElement("li", null, React.createElement(UitkText, {
    size: 500
  }, "Property + amenities:"), " Videos should be of the property and can include the surrounding area. Include 2-3 different areas of the property like:", React.createElement(UitkList, null, React.createElement("li", null, "Hotel (Exterior landscape, view, interior, lobby, lounge, rooms, suites)"), React.createElement("li", null, "Amenities (Pools, spas, gyms, dining)"))))), React.createElement(UitkLayoutFlex, {
    className: "CVDialog__vcg-flex-gap",
    direction: "column"
  }, React.createElement(UitkText, {
    size: 500
  }, "Perspectives:"), React.createElement(UitkText, {
    size: 400
  }, "Capture the different aspects of the stay from a variety of angles and distances (such as at eye level, from above, from far away and up close). Do not capture extreme angles like 360s, inverted angles and refrain from using distorted lens like fisheye. Refrain from using transitions.")), React.createElement(UitkLayoutFlex, {
    className: "CVDialog__vcg-flex-gap",
    direction: "column"
  }, React.createElement(UitkText, {
    size: 500
  }, "Lighting and Color:"), React.createElement(UitkText, {
    size: 400
  }, "Use natural sunlight where available. If shooting indoors, rely on environmental lighting. Refrain from adding artificial lighting like spotlights, ring lights, or professional video/camera lighting to change the brightness of space.")), React.createElement(UitkLayoutFlex, {
    className: "CVDialog__vcg-flex-gap",
    direction: "column"
  }, React.createElement(UitkText, {
    size: 500
  }, "People:"), React.createElement(UitkLayoutFlex, {
    space: "two",
    direction: "column"
  }, React.createElement(UitkText, {
    size: 400
  }, "We love to see candid, authentic travel moments. When shooting, use discretion when with people or self. Always be respectful of people\u2019s privacy."), React.createElement(UitkList, null, React.createElement(UitkListItem, null, React.createElement(UitkText, {
    size: 400
  }, "Identifiable people: \"Identifiable people\" definition means someone who can be identified, directly or indirectly. For this instance, identifiable people are those who we can clearly see their facial features (eyes, ears, nose, mouth, etc).")), React.createElement(UitkListItem, null, React.createElement(UitkText, {
    size: 400
  }, "Selfies: While showing self in video is okay, refrain from including too many selfies. This isn\u2019t a personal reel.")), React.createElement(UitkListItem, null, React.createElement(UitkText, {
    size: 400
  }, "Crowds: Crowd shots are okay, but refrain from focusing on individuals, especially those you do not know. Anonymity is great. Shooting crowds from behind is good.")), React.createElement(UitkListItem, null, React.createElement(UitkText, {
    size: 400
  }, "Minors: Those under the age of 18, should not be included. If a child is part of submitter\u2019s family, by uploading, you are giving your permission for use."))))), React.createElement(UitkLayoutFlex, {
    className: "CVDialog__vcg-flex-gap",
    direction: "column"
  }, React.createElement(UitkText, {
    size: 500
  }, "Food:"), React.createElement(UitkText, {
    size: 400
  }, "Is a great passion for travelers but avoid showcasing too much food and beverages. Hotel dining rooms are okay but remember the destination/property is the hero. Highlight dining experience during stay, dining atmosphere, seating, location within hotel, popular and cultural foods, room service and in-room food perks. Refrain from tight shots that miss the broader dining experience, refrain from showcasing just food in video, be mindful of alcohol consumption and locations. Creators and all others in the video must be (or appear to be) 21 years of age or older if alcohol is featured. Alcohol in a bar or restaurant is okay, refrain from alcohol in casinos. Avoid promoting overconsumption by limiting alcoholic drinks to 1 per person featured (empty or full).")), React.createElement(UitkLayoutFlex, {
    className: "CVDialog__vcg-flex-gap",
    direction: "column"
  }, React.createElement(UitkText, {
    size: 500
  }, "Activities:"), React.createElement(UitkText, {
    size: 400
  }, "Some hotels may offer activities or partner with businesses. Highlight activities found at hotel like yoga, pool/spa, recreational, and hotel partner business and activities like boat tours, cultural tours, etc. Highlight destination nature and culture like hikes, shopping, museums, architecture, history, zoos, etc. Where applicable, incorporate reasonable safety equipment (e.g., helmets) to address the risk of viewer who try and copy what they see. Refrain from animal tours, do not include photos/videos/references to unsafe or illegal activities (i.e., misuse of safety equipment in adventure activities, hiking in dangerous places, skiing out-of-bounds, etc.).")), React.createElement(UitkLayoutFlex, {
    className: "CVDialog__vcg-flex-gap",
    direction: "column"
  }, React.createElement(UitkText, {
    size: 500
  }, "Crafted and Aspirational:"), React.createElement(UitkText, {
    size: 400
  }, "Ensure locations are elevated and bring out the best. Avoid spaces that feel too industrial, avoid mess and a too lived-in experience like messy beds or personal items.")), React.createElement(UitkLayoutFlex, {
    className: "CVDialog__vcg-flex-gap",
    direction: "column"
  }, React.createElement(UitkText, {
    size: 500
  }, "Typography and Graphics: "), React.createElement(UitkLayoutFlex, {
    space: "two",
    direction: "column"
  }, React.createElement(UitkText, {
    size: 400
  }, "While we do not want text, logos, graphics, watermarks, transitions, or emojis, contextual text that includes location and hotel/property name is okay."), React.createElement(UitkText, {
    className: "underline",
    size: 400
  }, "Examples can include:"), React.createElement(UitkList, null, React.createElement(UitkListItem, null, React.createElement(UitkText, {
    size: 400
  }, "Hilton Hotel, Bora Bora")), React.createElement(UitkListItem, null, React.createElement(UitkText, {
    size: 400
  }, "Atlanta, Georgia")), React.createElement(UitkListItem, null, React.createElement(UitkText, {
    size: 400
  }, "The Anvaya Beach Resort Bali")), React.createElement(UitkListItem, null, React.createElement(UitkText, {
    size: 400
  }, "Palm Springs Hotel, Palm Springs, California")), React.createElement(UitkListItem, null, React.createElement(UitkText, {
    size: 400
  }, "Venice Beach"))), React.createElement(UitkText, {
    size: 400
  }, "Refrain from including claims that are not hotel or destination specific like \u201Cbest hotel\u201D or \u201Cthis is the most beautiful hotel\u201D. Or meme/trend language like \u201Cthis is it\u201D, \u201Cthis is why you need to visit Iceland\u201D, \u201C60 seconds in Conrad Hotel\u201D, etc."), React.createElement(UitkText, {
    className: "underline",
    size: 400
  }, "Fonts okay to use per platform:"), React.createElement(UitkList, null, React.createElement(UitkListItem, null, React.createElement(UitkText, {
    size: 400
  }, "Instagram - Classic, editor, poster, elegant, literature")), React.createElement(UitkListItem, null, React.createElement(UitkText, {
    size: 400
  }, "TikTok - classic, bold, elegance, serif, luxury, script "))), React.createElement(UitkText, {
    size: 400
  }, "Refrain from using text that is curvy, unique, or handwritten. It is okay to use overlays behind text for legibility. But are limited to neutral colors like white, black. Refrain from including strokes around type. Refrain from skewing type."))), React.createElement(UitkLayoutFlex, {
    className: "CVDialog__vcg-flex-gap",
    direction: "column"
  }, React.createElement(UitkHeading, {
    size: 4
  }, "Video Quality"), React.createElement(UitkText, {
    size: 400
  }, "Video media should not be:"), React.createElement(UitkList, null, React.createElement("li", null, "Low resolution, blurry, or pixelated."), React.createElement("li", null, "Graphically filtered with overlays, borders, or artistic effects."), React.createElement("li", null, "Too dark or light."), React.createElement("li", null, "Black and white or sepia."), React.createElement("li", null, "Sideways, upside down, or otherwise improperly rotated."), React.createElement("li", null, "No overt filters used."), React.createElement("li", null, "No still imagery or photographs"))), React.createElement(UitkLayoutFlex, {
    className: "CVDialog__vcg-flex-gap",
    direction: "column"
  }, React.createElement(UitkText, {
    size: 500
  }, "Using a Phone to Capture Videos "), React.createElement(UitkList, null, React.createElement("li", null, "Clean your lens to avoid unwanted smudges or dust."), React.createElement("li", null, "Use high quality settings on your phone."), React.createElement("li", null, "Stage the space (arrange it nicely for display, removing any unwanted items)"), React.createElement("li", null, "Use smooth, steady movements to avoid shaky shots."), React.createElement("li", null, "Use plenty of light (avoid dark or backlit shots)"), React.createElement("li", null, "Keep the phone orientation consistent."), React.createElement("li", null, "Limit using phone zoom to avoid degrading quality."))), React.createElement(UitkText, {
    size: 500
  }, "We strive to create a safe, helpful, and inclusive platform for everyone. By avoiding these prohibited elements, you contribute to a positive and welcoming community for all travelers."), React.createElement(UitkLayoutFlex, {
    className: "CVDialog__vcg-flex-gap",
    direction: "column"
  }, React.createElement(UitkHeading, {
    size: 4
  }, "We do not accept videos that include:"), React.createElement("div", null, React.createElement(UitkText, {
    inline: true,
    size: 500
  }, "Explicit Content -", ' '), React.createElement(UitkText, {
    inline: true,
    size: 400
  }, "Nudity, sexual acts, graphic violence, drug use, or excessive alcohol consumption.")), React.createElement("div", null, React.createElement(UitkText, {
    inline: true,
    size: 500
  }, "Discriminatory Content -", ' '), React.createElement(UitkText, {
    inline: true,
    size: 400
  }, "Hate speech, racism, sexism, xenophobia, harassment, bullying.")), React.createElement("div", null, React.createElement(UitkText, {
    inline: true,
    size: 500
  }, "Illegal Activity -", ' '), React.createElement(UitkText, {
    inline: true,
    size: 400
  }, "Violation of local laws or Expedia policies.")), React.createElement("div", null, React.createElement(UitkText, {
    inline: true,
    size: 500
  }, "Unsafe Practices -", ' '), React.createElement(UitkText, {
    inline: true,
    size: 400
  }, "Risky behavior, dangerous stunts, or illegal activities.")), React.createElement("div", null, React.createElement(UitkText, {
    inline: true,
    size: 500
  }, "Private Information -", ' '), React.createElement(UitkText, {
    inline: true,
    size: 400
  }, "Unintentional exposure of others' identity without consent, including faces, license plates, or addresses.")), React.createElement("div", null, React.createElement(UitkText, {
    inline: true,
    size: 500
  }, "Promotional Content -", ' '), React.createElement(UitkText, {
    inline: true,
    size: 400
  }, "Direct advertising of Expedia competitor products or services.")), React.createElement("div", null, React.createElement(UitkText, {
    inline: true,
    size: 500
  }, "Still Images in Video -", ' '), React.createElement(UitkText, {
    inline: true,
    size: 400
  }, "Videos comprised of still images in a slideshow or montage.")), React.createElement("div", null, React.createElement(UitkText, {
    inline: true,
    size: 500
  }, "Misrepresentation -", ' '), React.createElement(UitkText, {
    inline: true,
    size: 400
  }, "Intentionally misleading viewers about the property or surrounding area.")), React.createElement("div", null, React.createElement(UitkText, {
    inline: true,
    size: 500
  }, "Impersonation -", ' '), React.createElement(UitkText, {
    inline: true,
    size: 400
  }, "Attempts to impersonate another person, account, or entity, including Expedia representatives.")), React.createElement("div", null, React.createElement(UitkText, {
    inline: true,
    size: 500
  }, "Infringement of Intellectual Property and Proprietary Rights -", ' '), React.createElement(UitkText, {
    inline: true,
    size: 400
  }, "Content that infringes the intellectual property or other proprietary rights of others. Only submit your own content or content that you have permission to use. For example, unless you have obtained permission from all relevant third parties, do not play music or include images, artwork, or other audiovisual works in your video(s). Anyone featured in the video should avoid prominently visible branding on clothes, jewelry, or accessories if you don't have permission from the brand owner. ")), React.createElement("div", null, React.createElement(UitkText, {
    inline: true,
    size: 500
  }, "Confidential Information -", ' '), React.createElement(UitkText, {
    inline: true,
    size: 400
  }, "Any personally identifiable information about others, including full names, addresses, contact information, credit card numbers or other personally identifying information should not be displayed in the video.")), React.createElement("div", null, React.createElement(UitkText, {
    inline: true,
    size: 500
  }, "Irrelevant Titles -", ' '), React.createElement(UitkText, {
    inline: true,
    size: 400
  }, "Video captions containing irrelevant information to the property listing type, style, or experience.")), React.createElement("div", null, React.createElement(UitkText, {
    inline: true,
    size: 500
  }, "Symbols or Emojis -", ' '), React.createElement(UitkText, {
    inline: true,
    size: 400
  }, "Listing titles containing symbols or emojis.")), React.createElement("div", null, React.createElement(UitkText, {
    inline: true,
    size: 500
  }, "Fraudulent Information -", ' '), React.createElement(UitkText, {
    inline: true,
    size: 400
  }, "Video content showcasing fraudulent, false, misleading, or deceptive information.")), React.createElement("div", null, React.createElement(UitkText, {
    inline: true,
    size: 500
  }, "External Links -", ' '), React.createElement(UitkText, {
    inline: true,
    size: 400
  }, "Video content with any web links, URLs, phone numbers or calls.")), React.createElement("div", null, React.createElement(UitkText, {
    inline: true,
    size: 500
  }, "Property Access -", ' '), React.createElement(UitkText, {
    inline: true,
    size: 400
  }, "Videos containing specific information about accessing the property such as door codes, key locations, or exact addresses for vacation rental properties.")), React.createElement("div", null, React.createElement(UitkText, {
    inline: true,
    size: 500
  }, "Branding & Tagging Celebrities -", ' '), React.createElement(UitkText, {
    inline: true,
    size: 400
  }, "References to or footage containing other brands and logos, especially logos or branding for other travel agencies or Expedia competitors should not be displayed. #Hashtags with brand, celebrity or product names or tagging third-party companies or celebrities without prior agreement.")), React.createElement("div", null, React.createElement(UitkText, {
    inline: true,
    size: 500
  }, "Alcohol and Alcohol Consumption \u2013", ' '), React.createElement(UitkList, null, React.createElement("li", null, "Do not encourage, feature, or refer to behaviors that suggest irresponsible drinking."), React.createElement("li", null, "Do not encourage excessive consumption."), React.createElement("li", null, "Do not show alcohol more than what might be reasonably served to those in the video (i.e., one drink per person, no magnums for just 1-2 people, etc.)"), React.createElement("li", null, "Do not disparage abstinence from alcoholic beverages."), React.createElement("li", null, "Do not show products by alcohol manufacturers or distributors."))), React.createElement("div", null, React.createElement(UitkText, {
    inline: true,
    size: 500
  }, "Minors -", ' '), React.createElement(UitkText, {
    inline: true,
    size: 400
  }, "Videos of guests under 18 years old will be excluded, unless they're accompanied by an adult.")), React.createElement("div", null, React.createElement(UitkText, {
    inline: true,
    size: 500
  }, "Prices and Promotions -", ' '), React.createElement(UitkText, {
    inline: true,
    size: 400
  }, "Videos containing prices, promotional information, discount claims, or time-sensitive information.")), React.createElement("div", null, React.createElement(UitkText, {
    inline: true,
    size: 500
  }, "Expedia Logos -", ' '), React.createElement(UitkText, {
    inline: true,
    size: 400
  }, "Use of Expedia's intellectual property (such as logos and service names) except EG Credit Cards unless you have received our express written consent."))));
};