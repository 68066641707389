var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import React from 'react';
import { usePlatformContext } from '@bernie/platform-context';
import { UitkTertiaryButton } from 'uitk-react-button';
import { UitkIcon } from 'uitk-react-icons';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkSheet, UitkSheetTransition, UitkSheetContent } from 'uitk-react-sheet';
import { useLocation } from 'react-router-dom';
import { supportedLocales as defaultLocales } from 'shared/config';
import { makeLocaleParam } from 'shared/utils';
export var MobileLanguageSelector = function (_a) {
  var subSupportedLocales = _a.subSupportedLocales;
  var context = usePlatformContext().context;
  var contextLocale = makeLocaleParam(context.locale);
  var location = useLocation();
  var supportedLocales = subSupportedLocales || defaultLocales;
  var currentLang = React.useMemo(function () {
    var _a;
    return (_a = supportedLocales.find(function (locale) {
      return locale.langCode === contextLocale;
    })) !== null && _a !== void 0 ? _a : supportedLocales[0];
  }, [supportedLocales, contextLocale]);
  // Set state
  var _b = __read(React.useState(currentLang === null || currentLang === void 0 ? void 0 : currentLang.langLabel), 2),
    activeLabel = _b[0],
    setActiveLabel = _b[1];
  var triggerRef = React.useRef(null);
  var _c = __read(React.useState(false), 2),
    visible = _c[0],
    setVisible = _c[1];
  // But we already put it in a cookie in the context...
  React.useEffect(function () {
    localStorage.setItem('locale', contextLocale);
    setActiveLabel(currentLang.langLabel);
  }, [contextLocale, currentLang]);
  var pathWithoutLocale;
  var path;
  // Do this for every page other than the first homepage load
  if (location.pathname !== '/home') {
    // Get path from the route, strip out locale
    var pathArray = location.pathname.split('/');
    var param = location.search;
    if (pathArray) {
      // take out first pieces of URL '' and '/xx-xx/'
      pathArray.splice(0, 2);
      pathWithoutLocale = pathArray && pathArray.join('/');
    }
    path = param ? pathWithoutLocale + param : pathWithoutLocale;
    // Homepage starts without locale prefix so don't try remove
  } else {
    path = 'home';
  }
  function handleItemClick(e, label) {
    setActiveLabel(label);
  }
  var toggleVisibility = function () {
    setVisible(!visible);
  };
  return React.createElement("div", {
    className: "TCLanguageSelector"
  }, React.createElement("div", {
    className: "TCLanguageSelector__trigger"
  }, React.createElement(UitkTertiaryButton, {
    onClick: toggleVisibility,
    domRef: triggerRef
  }, activeLabel, React.createElement(UitkIcon, {
    name: "expand_more"
  }))), visible && React.createElement("div", {
    className: "TCLanguageSelector__scrim",
    onClick: toggleVisibility
  }), React.createElement(UitkSheetTransition, {
    isVisible: visible
  }, React.createElement(UitkSheet, {
    isVisible: visible,
    liveAnnounceString: "Default sheet has opened",
    triggerRef: triggerRef
  }, React.createElement(UitkSheetContent, null, React.createElement(UitkLayoutFlex, {
    direction: "column",
    tag: "ul",
    className: "TCLanguageSelector__menu"
  }, supportedLocales.map(function (item, id) {
    return React.createElement("li", {
      className: "TCLanguageSelector__menu-item",
      key: id
    }, React.createElement("a", {
      onClick: function (e) {
        return handleItemClick(e, item.langLabel);
      },
      href: "/".concat(item.langCode, "/").concat(path),
      className: "TCLanguageSelector__button ".concat(activeLabel === item.langLabel ? 'TCLanguageSelector__button--selected' : '')
    }, item.langLabel), activeLabel === item.langLabel ? React.createElement("div", null, React.createElement(UitkIcon, {
      name: "check",
      className: "TCLanguageSelector__icon"
    })) : null);
  }))))));
};