var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import * as React from 'react';
import { useState } from 'react';
import { UitkFakeInput } from 'uitk-react-input';
import { UitkFigure, UitkImage } from 'uitk-react-images';
import { UitkToolbar } from 'uitk-react-toolbar';
import { UitkPrimaryButton, UitkButtonSize } from 'uitk-react-button';
import { UitkText } from 'uitk-react-text';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { SubmitFormTypeahead, SubmitFormHotelCard } from 'features/creator-video';
import { useStores } from 'shared/hooks';
export function SubmitFormUploadDialog(_a) {
  var onClose = _a.onClose,
    previewThumbnail = _a.previewThumbnail,
    previewTitle = _a.previewTitle,
    onAttach = _a.onAttach;
  var uiStore = useStores().uiStore;
  var bffUrl = uiStore.bffUrl;
  var _b = __read(useState(1), 2),
    step = _b[0],
    setStep = _b[1];
  var _c = __read(useState(false), 2),
    canAttach = _c[0],
    setCanAttach = _c[1];
  var _d = __read(useState(false), 2),
    showHotelCard = _d[0],
    setShowHotelCard = _d[1];
  var _e = __read(useState({
      thumbUrl: '',
      title: '',
      location: '',
      destinationId: 1,
      propertyId: 1,
      propertyUrl: '',
      propertyName: ''
    }), 2),
    submission = _e[0],
    setSubmission = _e[1];
  function handleSelection(selection) {
    return __awaiter(this, void 0, void 0, function () {
      var propertyId, location, res, url;
      var _a;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            console.log(selection);
            propertyId = selection.data.selected;
            location = (_a = selection.data.regionNames) === null || _a === void 0 ? void 0 : _a.secondaryDisplayName;
            setStep(1);
            setShowHotelCard(true);
            return [4 /*yield*/, fetch("".concat(bffUrl, "/api/creator-videos/property-info/").concat(propertyId), {
              headers: {
                'Content-Type': 'application/json'
              }
            })];
          case 1:
            res = _b.sent();
            return [4 /*yield*/, res.json()];
          case 2:
            url = _b.sent().url;
            setSubmission({
              thumbUrl: previewThumbnail,
              title: previewTitle,
              location: location,
              destinationId: selection.data.cityId,
              propertyId: selection.data.selected,
              propertyUrl: url,
              propertyName: selection.term
            });
            setCanAttach(true);
            return [2 /*return*/];
        }
      });
    });
  }
  return React.createElement("div", {
    className: "CVSheet"
  }, React.createElement("div", {
    className: "CVSheet__wrapper"
  }, step === 1 && React.createElement("div", {
    className: "CVSheet__container"
  }, React.createElement(UitkToolbar, {
    className: "CVSheet__toolbar",
    navigationContent: {
      onClick: function () {
        return onClose();
      },
      navIconLabel: 'Close Toolbar'
    },
    toolbarTitle: "Upload a video"
  }), React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: "six"
  }, React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    direction: "column",
    space: "two"
  }, React.createElement("div", null, React.createElement(UitkFigure, {
    className: "CVSheet__thumb"
  }, React.createElement(UitkImage, {
    placeholderImage: true,
    alt: "alternative text",
    src: previewThumbnail
  }))), React.createElement(UitkText, {
    size: 200,
    align: "center"
  }, previewTitle)), React.createElement(UitkLayoutFlex, {
    direction: "column",
    space: "four"
  }, React.createElement(UitkText, {
    size: 300,
    weight: "medium"
  }, "Tell us where this video was taken"), showHotelCard ? React.createElement(SubmitFormHotelCard, {
    isLoading: !canAttach,
    hotelName: submission.propertyName,
    location: submission.location,
    propertyUrl: submission.propertyUrl,
    onEdit: function () {
      return setStep(2);
    }
  }) : React.createElement(UitkFakeInput, {
    label: "Property name",
    //placeholder="Search for a property"
    onClick: function () {
      return setStep(2);
    }
  }))), React.createElement(UitkPrimaryButton, {
    size: UitkButtonSize.LARGE,
    disabled: !canAttach,
    isFullWidth: true,
    onClick: function () {
      return onAttach(submission);
    }
  }, "Attach")), step === 2 && React.createElement("div", {
    className: "CVSheet__container"
  }, React.createElement(UitkToolbar, {
    className: "CVSheet__toolbar",
    navigationContent: {
      navType: 'back',
      onClick: function () {
        return setStep(1);
      }
    },
    toolbarTitle: "Find a property"
  }), React.createElement("div", {
    className: "CVSheet__typeahead"
  }, React.createElement(SubmitFormTypeahead, {
    onDismiss: function () {
      return setStep(1);
    },
    onSelection: handleSelection
  })))));
}