var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkExpandoList, UitkExpandoListItem } from 'uitk-react-expando';
var Faq = function (_a) {
  var faqList = _a.faqList,
    setFaqList = _a.setFaqList,
    innerRef = _a.innerRef;
  return React.createElement("div", {
    className: "TCFaq__list__container",
    ref: innerRef
  }, React.createElement(UitkLayoutFlex, {
    direction: "column",
    alignItems: "center",
    space: "twelve",
    className: "TCFaq__list"
  }, React.createElement(UitkExpandoList, {
    className: "TCFaq__expando-list"
  }, faqList.map(function (listItem, index) {
    return React.createElement("div", {
      className: "TCFaq__expando-list-item",
      key: "faq-list-item-".concat(index)
    }, React.createElement(UitkExpandoListItem, __assign({}, listItem, {
      triggerPosition: "trailing",
      onToggle: function () {
        var tempFaqList = __spreadArray([], __read(faqList), false);
        tempFaqList.map(function (obj) {
          obj.isVisible = obj.expandoTitle === listItem.expandoTitle;
        });
        setFaqList(tempFaqList);
      }
    })));
  }))));
};
export { Faq };