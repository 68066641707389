// 3rd party imports
import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { usePlatformContext } from '@bernie/platform-context';
// bernie
import { LocalizedText, useLocalization } from '@shared-ui/localization-context';
// ui
import { UitkLayoutGrid, UitkLayoutGridItem } from 'uitk-react-layout-grid';
import { UitkSpacing, UitkSpacingHr } from 'uitk-react-spacing';
import { UitkLink } from 'uitk-react-link';
import { UitkText, UitkHeading } from 'uitk-react-text';
import { makeLocaleParam } from 'shared/utils';
import { useStores } from 'shared/hooks';
import { DirectWord } from 'shared/direct-word';
var ExpandFooterComponent = function (_a) {
  var cookieLinkRef = _a.cookieLinkRef;
  var l10n = useLocalization();
  var uiStore = useStores().uiStore;
  var context = usePlatformContext().context;
  var pageName = uiStore.pageName;
  var locale = makeLocaleParam(context.locale);
  var logoLink = "/".concat(locale, "/home");
  var currentYear = new Date().getFullYear();
  return React.createElement(UitkSpacing, {
    padding: {
      block: 'twelve'
    },
    border: ['blockstart'],
    className: "ExpandFooter light-section-bg"
  }, React.createElement("footer", null, React.createElement("div", {
    className: "container"
  }, React.createElement(UitkLink, null, React.createElement(Link, {
    to: logoLink
  }, React.createElement("img", {
    className: "Header__logo",
    src: "/images/shared/eg-affiliate-program-logo-blue.svg",
    alt: "Expedia Group Affiliate Program"
  }))), React.createElement(UitkSpacing, {
    margin: {
      block: 'twelve'
    }
  }, React.createElement(UitkLayoutGrid, {
    columns: ['1fr', '1fr'],
    space: "four"
  }, React.createElement(UitkLayoutGridItem, null, React.createElement(UitkHeading, {
    size: 6
  }, React.createElement(LocalizedText, {
    message: "footer.heading.support"
  }))), React.createElement(UitkLayoutGridItem, null, React.createElement(UitkHeading, {
    size: 6
  }, React.createElement(LocalizedText, {
    message: "footer.heading.policies"
  }))), React.createElement(UitkLayoutGridItem, null, React.createElement(UitkLink, null, React.createElement("a", {
    href: "https://help.affiliates.expediagroup.com/hc/en-us",
    target: "_blank",
    rel: "noreferrer"
  }, React.createElement(LocalizedText, {
    message: "footer.links.help_center"
  })))), React.createElement(UitkLayoutGridItem, null, React.createElement(UitkLink, null, React.createElement(Link, {
    to: "/".concat(locale, "/doc/privacy-policy"),
    target: "_blank"
  }, React.createElement(LocalizedText, {
    message: "footer.links.privacy_policy"
  })))), React.createElement(UitkLayoutGridItem, null, React.createElement(UitkLink, null, React.createElement("a", {
    href: "https://help.affiliates.expediagroup.com/hc/en-us/categories/13657662298775",
    target: "_blank",
    rel: "noreferrer"
  }, React.createElement(LocalizedText, {
    message: "footer.links.resources"
  })))), React.createElement(UitkLayoutGridItem, null, React.createElement(UitkLink, null, React.createElement(Link, {
    to: "/".concat(locale, "/doc/affiliate"),
    target: "_blank"
  }, React.createElement(LocalizedText, {
    message: "footer.links.program_terms"
  })))), React.createElement(UitkLayoutGridItem, null, React.createElement(UitkLink, null, React.createElement("a", {
    href: "mailto:expedia.support@partnerize.com",
    target: "_blank",
    rel: "noreferrer"
  }, React.createElement(LocalizedText, {
    message: "footer.links.contact_us"
  })))), React.createElement(UitkLayoutGridItem, null, React.createElement(UitkLink, null, React.createElement(Link, {
    to: "/".concat(locale, "/doc/cookie-policy"),
    target: "_blank",
    ref: cookieLinkRef
  }, React.createElement(LocalizedText, {
    message: "footer.links.cookie_policy"
  })))))), React.createElement("div", {
    className: "Footer__hr"
  }, React.createElement(UitkSpacingHr, {
    blockMargin: 'six'
  })), React.createElement(UitkText, {
    size: 200
  }, l10n.formatText('footer.content.all_rights_reserved', currentYear)), React.createElement("div", {
    className: "Footer__feedback"
  }, React.createElement(DirectWord, {
    pageName: pageName,
    showIcon: true,
    display: "floating",
    locale: locale,
    l10n: l10n
  })))));
};
export var ExpandFooter = withRouter(ExpandFooterComponent);