import * as React from 'react';
import { useEffect } from 'react';
import { Layout, TermsAndConditionsContent } from 'features/creator-video';
import { useCommonClickStreamEvent, EventType } from 'shared/utils';
import { useLogger } from 'shared/hooks';
function CreatorVideoTermsPage() {
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  var logger = useLogger();
  React.useEffect(function () {
    trackEvent({
      event_type: EventType.IMPRESSION,
      event_name: 'creator_video_terms_and_conditions.presented',
      action_location: 'terms_and_conditions-page'
    });
  }, []);
  useEffect(function () {
    logger.info('Creator video acceptance agreement loaded');
  }, []);
  return React.createElement(Layout, null, React.createElement(TermsAndConditionsContent, null));
}
export default CreatorVideoTermsPage;
export { CreatorVideoTermsPage };